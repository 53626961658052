import { useContext } from "react";
import GlobalStateContext from "../../context/globalState/GlobalStateContext";
import styles from "./ParticipationHeader.module.scss";
import { Navbar } from "react-bootstrap";
import TasksSummary from "../../components/Task/TasksSummary";
import { MenuLineIcon } from "../../components/RemixIcons";
import { getRelevantTranslationFor } from "../../tools/multiLingualTools";
import ParticipationLayoutContext from "../../context/participation/ParticipationLayoutContext";

type Props = {};

const ParticipationHeader: React.FC<Props> = () => {
  const { state } = useContext(GlobalStateContext);
  const ctx = useContext(ParticipationLayoutContext);
  const participation = state.context?.participationCtx;

  return !participation ? (
    <></>
  ) : (
    <>
      <Navbar bg='light' expand='lg' fixed='top' className={`${styles.navBar}`}>
        <div className={styles.root}>
          <div className={styles.menuLink}>
            <Navbar.Brand
              className={styles.icon}
              href=''
              onClick={ctx.toggleStepMenu}
            >
              <MenuLineIcon />
            </Navbar.Brand>
          </div>
          <Navbar.Text className={styles.navBarText}>
            {participation.icon ? (
              <img
                alt='logo'
                src={participation.icon}
                className={styles.logo}
              />
            ) : (
              <></>
            )}
            {getRelevantTranslationFor(participation.name)}
          </Navbar.Text>
          <Navbar className={styles.navBarEnd}>
            <TasksSummary
              size='small'
              percent={participation.estimatedCompletionInPercent || 0}
              variant='light'
            />
          </Navbar>
        </div>
      </Navbar>
    </>
  );
};

export default ParticipationHeader;
