import React, { useContext, useEffect, useState } from "react";
import stylesEntitlement from "./AddOrUpdateFormEntitlementPage.module.scss";
import stylesModal from "../../../components/ModalWrapper/ModalSlideShowWrapper.module.scss";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import ReactLoading from "react-loading";
import selectStyles from "../../../styles/react-select.module.scss";
import { toast } from "react-toastify";
import GlobalStateContext from "../../../context/globalState/GlobalStateContext";
import { FormEntitlementResponse } from "../../../@types/form-api";
import { SET_SPHERE_FORM_ENTITLEMENT } from "../../../context/globalState/globalStateReducer";
import PageTitle from "../../../components/PageTitle/PageTitle";
import api from "../../../api/Api";
import { getUrlParams } from "../../../tools/routeTools";
import { subject } from "@casl/ability";
import AbilityContext from "../../../context/AbilityContext";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { ShieldKeyholeLineIcon } from "../../../components/RemixIcons";
import HorizontalRule from "../../../components/horizontalRule/HorizontalRule";
import { motion } from "framer-motion";
import { cardListAnimation } from "../../../tools/framerAnimations";
import SessionCard from "../../../components/Card/SessionCard/SessionCard";
import { Session } from "../../../@types/session-api";
import { getErrorTranslation } from "../../../tools/errorTools";
import LoadingPage from "../../loading/LoadingPage";
import { captureException } from "@sentry/minimal";
import { getRelevantTranslationFor } from "../../../tools/multiLingualTools";

const styles = { ...stylesModal, ...stylesEntitlement };

type Props = {
  currentPageIdx?: number;
  onSubmit?: (state?: any) => void;
};

const dicoPath = "pages.sphere.forms.section";

type SelectInput = { value: string; label: string };

const AddOrUpdateFormEntitlementPage: React.FC<Props> = ({
  onSubmit = () => {},
}) => {
  const { t } = useTranslation("i18n");
  const [availabilitiesThrough, setAvailabilitiesThrough] =
    useState<SelectInput>();
  const [availabilitiesThroughOptions, setAvailabilitiesThroughOptions] =
    useState<SelectInput[]>([]);
  const [saving, setSaving] = useState<boolean>(false);
  const [couldUpdate, setCouldUpdate] = useState<boolean>(false);
  const [couldCreate, setCouldCreate] = useState<boolean>(false);
  const [entitlement, setEntitlement] = useState<FormEntitlementResponse>();
  const [formSelected, setFormSelected] = useState<SelectInput>();

  const [loadingSessions, setLoadingSessions] = useState<boolean>(false);
  const [sessions, setSessions] = useState<Session[]>([]);
  const [showSessions, setShowSessions] = useState<boolean>(false);
  const location = useLocation();
  let formId = new URLSearchParams(location.search).get("formId");

  const {
    state: { formEntitlements },
    dispatch,
  } = useContext(GlobalStateContext);
  const ability = useContext(AbilityContext);

  const { sphereId } = getUrlParams(location.pathname);

  useEffect(() => {
    if (formEntitlements === undefined) {
      //Possible if the user refresh the page then return to the list page
      onSubmit();
      return;
    }

    const formEntitlement = formEntitlements?.find(
      (e) => e.formId === formId && e.sphereId === sphereId
    );
    const availabilities = ["none", "sphereMember", "sessionMember"].map(
      (a) => ({
        label: t(`${dicoPath}.form.labels.availabilitiesThrough.${a}`),
        value: a,
      })
    );
    setAvailabilitiesThroughOptions(availabilities);
    setAvailabilitiesThrough(
      formEntitlement
        ? availabilities.find(
            (a) => a.value === formEntitlement.availabilitiesThrough
          )
        : availabilities[0]
    );
    setEntitlement(formEntitlement);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formId]);

  useEffect(() => {
    const showSessions =
      availabilitiesThrough?.value === "sessionMember" &&
      entitlement?.availabilitiesThrough === "sessionMember" &&
      entitlement.sessionIds?.length > 0;
    setShowSessions(showSessions);
    if (showSessions) {
      fetchSessions(entitlement?.sessionIds || []);
    }
  }, [availabilitiesThrough, entitlement]);

  useEffect(() => {
    setCouldUpdate(
      ability.can("put", subject("form-entitlement", { sphereId }))
    );
    setCouldCreate(
      ability.can("post", subject("form-entitlement", { sphereId }))
    );
  }, [ability, sphereId]);

  const handleClose = () => {
    onSubmit();
  };

  const handleAvailabilitiesThroughChanged = (value: any) => {
    setAvailabilitiesThrough(value);
  };

  const handleFormSelected = (formSelected: any) => {
    setFormSelected(formSelected);
  };

  const fetchSessions = async (sessionIds: string[]) => {
    setLoadingSessions(true);
    const response = await api.getSessions({
      max: sessionIds.length,
      offset: 0,
      filters: [`id=${sessionIds.join(",")}`],
    });
    setSessions(response.items);
    setLoadingSessions(false);
  };

  const fetchForms = async ({
    search,
    filters,
  }: {
    search?: string;
    filters?: string[];
  }): Promise<SelectInput[]> => {
    const response = await api.getForms({
      max: 200,
      searchQuery: search,
      offset: 0,
      filters,
    });

    const forms = response.items.map((form) => {
      return {
        label: getRelevantTranslationFor(form.title),
        value: form.id,
      };
    });
    return forms;
  };

  const loadFormsOptions = (
    inputValue: string,
    callback: (data: { label: string; value: string }[]) => void
  ) => {
    const formIds = formEntitlements?.map((e) => e.formId);

    fetchForms({
      search: inputValue,
      //Exclude form already activated
      filters: formIds ? [`id!=${formIds.join(",")}`] : [],
    }).then((members) => {
      callback(members);
    });
  };

  const handleCreate = async (event: any) => {
    event.preventDefault();
    event.stopPropagation();

    if (!couldCreate || !formSelected) {
      return;
    }

    try {
      setSaving(true);

      const entitlementResponse = await api.createFormEntitlement({
        sphereId,
        formId: formSelected.value,
        availabilitiesThrough: availabilitiesThrough?.value as any,
      });
      toast.success(t(`${dicoPath}.add.succeed`));

      dispatch({
        type: SET_SPHERE_FORM_ENTITLEMENT,
        payload: [...(formEntitlements || []), entitlementResponse],
      });

      onSubmit();
    } catch (error) {
      captureException(error);
      toast.error(
        getErrorTranslation({
          t,
          error,
          defaultKey: t(`${dicoPath}.add.error`),
        })
      );
    }
    setSaving(false);
  };
  const handleUpdate = async (event: any) => {
    event.preventDefault();
    event.stopPropagation();

    if (!couldUpdate || !formId) {
      return;
    }

    try {
      setSaving(true);
      const sessionIds =
        // Clean sessionIds if availabilities not sessionMember
        availabilitiesThrough?.value !== "sessionMember" ? [] : undefined;
      const entitlementResponse = await api.updateFormEntitlement({
        sphereId,
        formId,
        availabilitiesThrough: availabilitiesThrough?.value as any,
        sessionIds,
      });
      toast.success(t(`${dicoPath}.update.succeed`));

      dispatch({
        type: SET_SPHERE_FORM_ENTITLEMENT,
        payload: [
          ...(formEntitlements || []).filter(
            (t) =>
              t.formId !== entitlementResponse.formId ||
              t.sphereId !== entitlementResponse.sphereId
          ),
          entitlementResponse,
        ],
      });

      onSubmit();
    } catch (error) {
      captureException(error);
      toast.error(
        getErrorTranslation({
          t,
          error,
          defaultKey: t(`${dicoPath}.update.error`),
        })
      );
    }
    setSaving(false);
  };

  return (
    <div className={styles.page}>
      <PageTitle title={t(`${dicoPath}.${formId ? "update" : "add"}.title`)} />

      <div className={styles.content}>
        <Form noValidate onSubmit={handleCreate}>
          <Container className={styles.root}>
            <Row key={`form`} className={styles.noMargin}>
              <Col sm={6} className={styles.noMargin}>
                <Form.Group controlId='formId'>
                  <Form.Label>
                    {!formId && <ShieldKeyholeLineIcon />}{" "}
                    {t(`${dicoPath}.form.labels.formId`)} *
                  </Form.Label>
                  {entitlement ? (
                    <Form.Control
                      disabled
                      type='text'
                      value={getRelevantTranslationFor(entitlement.title)}
                    />
                  ) : (
                    <AsyncSelect
                      isDisabled={!couldCreate}
                      className={selectStyles.reactSelect}
                      classNamePrefix='custom-react-select'
                      loadOptions={loadFormsOptions}
                      defaultOptions
                      onChange={handleFormSelected}
                      placeholder={t(
                        `${dicoPath}.form.labels.formIdPlaceholder`
                      )}
                      autoFocus={!formId}
                      value={formSelected}
                      noOptionsMessage={() => t("common.noValue")}
                    />
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row key={`access`} className={styles.noMargin}>
              <Col sm={6} className={styles.noMargin}>
                <Form.Group controlId='access'>
                  <Form.Label>{t(`${dicoPath}.form.labels.access`)}</Form.Label>
                  {availabilitiesThrough && (
                    <Select
                      className={selectStyles.reactSelect}
                      classNamePrefix='custom-react-select'
                      defaultValue={availabilitiesThrough}
                      options={availabilitiesThroughOptions}
                      onChange={handleAvailabilitiesThroughChanged}
                    />
                  )}
                </Form.Group>
              </Col>
            </Row>

            <hr />
            <Row className={styles.noMargin}>
              <Col sm={11} className={styles.buttons}>
                <Button
                  variant='secondary'
                  className={styles.right}
                  onClick={() => handleClose()}
                >
                  {t("common.action.close")}
                </Button>
                {formId && (
                  <Button
                    disabled={!couldUpdate}
                    className={styles.left}
                    onClick={handleUpdate}
                  >
                    {saving && (
                      <div className={styles.submitContent}>
                        <ReactLoading
                          type={"bars"}
                          color={"#DDD"}
                          height={"30px"}
                          width={"40px"}
                        />
                        <div className={styles.submitText}>
                          {t(`common.action.saving`)}
                        </div>
                      </div>
                    )}
                    {!saving && t(`common.action.update`)}
                  </Button>
                )}
                {!formId && (
                  <Button
                    disabled={!couldCreate || !formSelected}
                    className={styles.left}
                    onClick={handleCreate}
                  >
                    {saving && (
                      <div className={styles.submitContent}>
                        <ReactLoading
                          type={"bars"}
                          color={"#DDD"}
                          height={"30px"}
                          width={"40px"}
                        />
                        <div className={styles.submitText}>
                          {t(`common.action.saving`)}
                        </div>
                      </div>
                    )}
                    {!saving && t(`common.action.add`)}
                  </Button>
                )}
              </Col>
            </Row>
            {showSessions && (
              <>
                <HorizontalRule text={t(`${dicoPath}.form.sessions`)} />
                {!loadingSessions && sessions.length === 0 && (
                  <>{t(`${dicoPath}.form.noOptionMessage`)}</>
                )}

                {!loadingSessions && sessions.length > 0 && (
                  <motion.div
                    className={styles.memberList}
                    variants={cardListAnimation.container}
                    initial='hidden'
                    animate='show'
                  >
                    {sessions.map((session: Session) => (
                      <motion.div
                        key={`${session.id}`}
                        variants={cardListAnimation.item}
                      >
                        <SessionCard session={session} />
                      </motion.div>
                    ))}
                  </motion.div>
                )}
                {loadingSessions && <LoadingPage />}
              </>
            )}
            <hr />
          </Container>
        </Form>
      </div>
    </div>
  );
};

export default AddOrUpdateFormEntitlementPage;
