import { PieCustomLayerProps } from "@nivo/pie";

const PieTaskPercentLabel = ({
  dataWithArc,
  centerX,
  centerY,
}: PieCustomLayerProps<{
  x: number;
  y: number;
  low: number;
  high: number;
}>) => {
  const [data] = dataWithArc;
  const { data: source } = data as any;
  return (
    <>
      <text
        key={"id"}
        x={centerX}
        y={centerY - 4}
        textAnchor={"middle"}
        fontSize={10}
        fill={source?.variant === "light" ? "#0d1939" : "rgb(187, 198, 237)"}
      >
        %
      </text>
      <text
        key={"value"}
        x={centerX}
        y={centerY + 8}
        textAnchor={"middle"}
        fontSize={15}
        fill={source?.variant === "light" ? "#0d1939" : "rgb(187, 198, 237)"}
      >
        {!data || data.id !== "done" ? "..." : data.value}
      </text>
    </>
  );
};

export default PieTaskPercentLabel;
