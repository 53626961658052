import React, {
  useState,
  useContext,
  FC,
  useEffect,
  ReactNode,
  useRef,
} from 'react';
import feelingStyles from './FeelingContributorPage.module.scss';
import modalStyles from '../../../components/ModalWrapper/ModalSlideShowWrapper.module.scss';
import Histogram from '../../../components/histogram/Histogram';
import HappyFeelingIcon from '../../../assets/images/happyFeeling.svg';
import SadFeelingIcon from '../../../assets/images/sadFeeling.svg';
import happyFace from '../../../assets/images/Happy_64.png';
import notHappyFace from '../../../assets/images/NotHappy_64.png';
import { toast } from 'react-toastify';

import battery from '../../../assets/images/battery.png';
import { DeviceUUID } from 'device-uuid';
import runIcon from '../../../assets/images/run.svg';
import { useTranslation } from 'react-i18next';
import api from '../../../api/Api';

import {
  Container,
  Button,
  DropdownButton,
  ButtonGroup,
  Dropdown,
} from 'react-bootstrap';
import { ADD_FEELING } from '../../../context/globalState/globalStateReducer';
import GlobalStateContext from '../../../context/globalState/GlobalStateContext';
import { useLocation, useHistory } from 'react-router-dom';
import {
  extractLocationData,
  getFeelingUpdateRoute,
} from '../../../tools/routeTools';
import { m as motion } from 'framer-motion';
import {
  feelingCategory,
  FeelingEvaluationPeriod,
  MySession,
} from '../../../@types/webapp-api';
import { Sphere } from '../../../@types/sphere-api';
import {
  CloseLineIcon,
  HotelBedFillIcon,
} from '../../../components/RemixIcons';
import FeelingReminder from '../FeelingReminder';
import { withGeoPosition } from '../../../tools/withGeoPosition';
import { WithGeoPoint } from '../../../@types/seen-apps';
import { ToastOnClose } from '../../../components/ModalWrapper/ModalSlideShowWrapper';
import { MemberResponse } from '../../../@types/member-api';
import { SessionResponse } from '../../../@types/session-api';
import SessionIcon from '../../../components/session/SessionIcon';
import { MyselfIcon, SphereIcon } from '../../../components/header/Header';
import { getRelevantTranslationFor } from '../../../tools/multiLingualTools';
import { getErrorTranslation } from '../../../tools/errorTools';
import { confirmWrapper } from '../../../tools/confirm';
import { captureException } from '@sentry/minimal';
import FeelingModeInput from '../../../components/feeling/FeelingModeInput';
import FeelingEvaluationPeriodInput, {
  TIMES_AVAILABLE,
} from '../../../components/feeling/FeelingEvaluationPeriodInput';
import {
  UrlCampaignResponse,
  FeelingWizard,
} from '../../../@types/shorten-url-api';
import MarkdownComponent from '../../../components/Markdown/MarkdownComponent';

class FeelingContext {
  sphereId: string | undefined;
  sessionId: string | undefined;
  tagIds: string | undefined;
  duration?: string;
  directLink?: boolean;

  constructor({
    sphereId,
    sessionId,
    duration,
    directLink,
    tagIds,
  }: {
    sphereId: string | undefined;
    sessionId: string | undefined;
    duration?: string;
    directLink?: boolean;
    tagIds: string | undefined;
  }) {
    this.sessionId = sessionId;
    this.sphereId = sphereId;
    this.duration = duration;
    this.directLink = directLink;
    this.tagIds = tagIds;
  }

  hasContext() {
    return !!this.sphereId || !!this.sessionId;
  }
}

const styles = { ...modalStyles, ...feelingStyles };

type Choice = {
  session?: SessionResponse;
  sphere?: Sphere;
  label: string;
  description?: string;
  value?: string;
};

type Props = {
  mode?: 'good' | 'bad';
  sphereId?: string;
  duration?: string;
  directLink?: boolean;
  tagIds?: string;
  sessionId?: string;
  memberCategory?: feelingCategory;
  currentPageIdx?: number;
  pageElements?: ReactNode[];
  onSubmit?: (stateProps: any) => void;
  variants?: any;
  onCloseNotifications: ToastOnClose[];
  disableThinkingCreation?: boolean;
  onClose?: () => {};
  campaign?: UrlCampaignResponse;
  validationCode?: string;
  wizzard?: FeelingWizard;
};

const FeelingContributorPage: FC<Props & WithGeoPoint> = ({
  mode: _mode,
  currentPageIdx = 0,
  pageElements = [],
  geoPoint,
  tagIds,
  onSubmit = () => {},
  variants,
  memberCategory = 'contributor',
  onCloseNotifications,
  disableThinkingCreation,
  onClose = () => {},
  campaign,
  validationCode,
  wizzard,
  ...others
}) => {
  const {
    state,
    state: { context },
    dispatch,
  } = useContext(GlobalStateContext);

  const [feelingSelectedIndex, setFeelingSelectedIndex] = useState<number>(-1);
  const [energySelectedIndex, setEnergySelectedIndex] = useState<number>(-1);
  const { t } = useTranslation('i18n');
  const [feelingContext, setFeelingContext] = useState<FeelingContext>();
  const [choiceSelected, setChoiceSelected] = useState<Choice>();
  const [choiceSelections, setChoiceSelections] = useState<Choice[]>([]);
  const [selectedTime, setSelectedTime] = useState<string>();
  const [previousContext, setPreviousContext] = useState<any>();
  const [contextMessage, setContextMessage] = useState<string>();
  const submitBtnRef = useRef(null);
  const history = useHistory();
  const location = useLocation();

  const [feelingMode, setFeelingMode] = useState<'good' | 'bad' | undefined>(
    _mode
  );

  useEffect(() => {
    const { params } = extractLocationData(location);

    const forbidden = (message: string) => {
      onCloseNotifications?.push({
        content: message,
        options: {
          type: 'error',
        },
      });
      onClose();
    };

    const duration = others.duration || wizzard?.period;

    const feelingContext = new FeelingContext({
      sphereId:
        state.context.sphereCtx?.sphere.id ||
        params.sphereId ||
        others.sphereId,
      sessionId:
        state.context.sessionCtx?.session.id ||
        params.sessionId ||
        others.sessionId,
      duration: duration
        ? // If the period is unknown then remove duration
          TIMES_AVAILABLE.find((time) => time === duration)
        : undefined,
      directLink: !!others.directLink,
      tagIds: tagIds,
    });
    setSelectedTime(feelingContext.duration);
    const noContextChoice = {
      label: t(`pages.feeling.subTitle.feeling.noContext`),
      value: undefined,
    };

    const choices: Choice[] = [
      noContextChoice,
      ...getContributorSessions(state.mySessions).map((s) => ({
        session: s,
        label: getRelevantTranslationFor(s.name),
        description: `${s.sphere.name}`,
        value: `SESSION-${s.id}`,
      })),
      ...getContributorSpheres(state.spheres, state.members).map((s) => ({
        sphere: s,
        label: s.name,
        value: `SPHERE-${s.id}`,
      })),
    ];

    let defaultChoice: Choice | undefined = undefined;
    if (feelingContext.sessionId) {
      defaultChoice = choices.find(
        (c) => c.session?.id === feelingContext.sessionId
      );
    } else if (feelingContext.sphereId) {
      defaultChoice = choices.find(
        (c) => c.sphere?.id === feelingContext.sphereId
      );
    }
    let newContextMessage = '';
    //check if admin but not a member (display message why session not selected)
    if (!defaultChoice && state.context.sessionCtx) {
      feelingContext.sessionId = undefined;
      newContextMessage = t('pages.feeling.context.sessionMissing', {
        session: getRelevantTranslationFor(
          state.context.sessionCtx.session.name
        ),
      });
    }
    //check if admin but not a member (display message why sphere not selected)
    else if (!defaultChoice && state.context.sphereCtx) {
      feelingContext.sphereId = undefined;
      newContextMessage = t('pages.feeling.context.sphereMissing', {
        sphere: state.context.sphereCtx.sphere.name,
      });
    }
    //check if user but not a member (direct link)
    else if (!defaultChoice && feelingContext.sessionId) {
      forbidden(t('pages.feeling.context.sessionForbidden'));
      return;
    }
    //check if user but not a member (direct link)
    else if (!defaultChoice && feelingContext.sphereId) {
      forbidden(t('pages.feeling.context.sphereForbidden'));
      return;
    }
    setContextMessage(newContextMessage);
    if (newContextMessage && newContextMessage !== contextMessage) {
      confirmWrapper(newContextMessage, {
        title: ' ',
        hideSecondaryButton: true,
        btnPrimaryLabel: t('common.action.close'),
      });
    }

    setFeelingContext(feelingContext);
    setChoiceSelected(defaultChoice);
    setChoiceSelections(choices);

    if (previousContext !== context) {
      setPreviousContext(context);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    context,
    previousContext,
    state.members,
    state.spheres,
    location,
    state.context.sphereCtx,
    state.context.sessionCtx,
    state.mySessions,
    onCloseNotifications,
    onClose,
    t,
  ]);

  useEffect(() => {}, [
    location,
    onClose,
    onCloseNotifications,
    state.members,
    state.mySessions,
    state.spheres,
    t,
  ]);

  const goodFeeling = feelingMode === 'good';

  const handleFeelingSelectionChange = (index: number) =>
    setFeelingSelectedIndex(index);
  const handleEnergySelectionChange = (index: number) =>
    setEnergySelectedIndex(index);

  const handleChoiceChange = (choice: any | Choice) => {
    setChoiceSelected(choice);
  };
  const handleTimeChange = (time: string | undefined) => {
    setSelectedTime(time);
  };

  const getContextIcon = (choice: Choice) => {
    const component = (
      <span className={styles.choiceIcon}>
        {choice.session ? (
          <SessionIcon session={choice.session} />
        ) : choice.sphere ? (
          <SphereIcon />
        ) : choice.sphere ? (
          <MyselfIcon />
        ) : (
          <CloseLineIcon />
        )}
      </span>
    );

    return component;
  };

  const formatContextLabel = (choice: Choice, hideDescription?: boolean) => {
    const component = (
      <div className={styles.choice}>
        {getContextIcon(choice)}

        <span className={styles.choiceLabel}>{choice.label}</span>
        {!hideDescription && (
          <span className={styles.choiceDesc}>{choice.description}</span>
        )}
      </div>
    );

    return component;
  };

  const handleSaveClick = async () => {
    //avoid double click
    if (submitBtnRef.current) {
      (submitBtnRef.current as any).setAttribute('disabled', 'disabled');
    }

    let value = (feelingSelectedIndex + 1) / 10;
    if (!goodFeeling) {
      value = -value;
    }

    let sphereId: string | undefined = undefined;
    let sessionId: string | undefined = undefined;
    if (choiceSelected) {
      sphereId = choiceSelected.sphere?.id;
      sessionId = choiceSelected.session?.id;
    }
    let evaluationPeriod: FeelingEvaluationPeriod | undefined = undefined;
    if (selectedTime) {
      const [value, type] = selectedTime.split(' ');
      evaluationPeriod = { value: Number.parseInt(value), type: type as any };
    }

    try {
      const result = await api.addFeeling({
        sphereId,
        sessionId,
        campaignId: campaign ? campaign.id : undefined,
        value,
        geoPoint,
        energy: (energySelectedIndex + 1) / 10,
        deviceCode: new DeviceUUID().get(),
        category: sphereId ? memberCategory : undefined,
        disableThinkingCreation,
        evaluationPeriod,
        validationCode,
        fromDirectLink: !!feelingContext?.directLink,
        specificTagIds: tagIds,
      });
      dispatch({
        type: ADD_FEELING,
        payload: result,
      });

      // add notification to display on close
      const link = getFeelingUpdateRoute({
        callerUrl: location.pathname,
        feelingId: result.id,
      });

      onCloseNotifications.push({
        content: (
          <div className={styles.feelingNotif}>
            {t('pages.feeling.added.success')} {t('pages.feeling.emotion')}{' '}
            <button
              onClick={() => history.push(link)}
              className={styles.wordLink}
            >
              {t(`words.${result.wordId}`)}
            </button>
          </div>
        ),
        options: {
          autoClose: 20000,
          type: 'success',
        },
      });

      //go to next page or close
      onSubmit({ feelingId: result.id, memberCategory });
    } catch (error) {
      captureException(error);
      toast.error(
        getErrorTranslation({
          error,
          t,
          defaultKey: 'pages.feeling.added.error',
        })
      );
      onSubmit({});
    }
  };

  return (
    <motion.div
      variants={variants}
      initial='hidden'
      animate='visible'
      exit='exit'
      className={styles.page}
    >
      <div className={styles.header}>
        {feelingMode === 'bad' && (
          <img
            className={styles.feelingImage}
            src={notHappyFace}
            alt='nothappyface'
          />
        )}
        {feelingMode === 'good' && (
          <img
            className={styles.feelingImage}
            src={happyFace}
            alt='nothappyface'
          />
        )}
        {!feelingMode && (
          <div className={styles.disabled}>
            <img
              className={styles.feelingImage}
              src={notHappyFace}
              alt='nothappyface'
            />
            <img
              className={styles.feelingImage}
              src={happyFace}
              alt='nothappyface'
            />
          </div>
        )}
      </div>
      <div className={styles.content}>
        <Container>
          <div className={styles.answer}>
            <MarkdownComponent
              text={t(
                `pages.feeling.subTitle.feeling.${feelingMode || 'default'}`
              )}
            />
          </div>
          <div className={styles.context}>
            <div className={styles.title}>
              {t(`pages.feeling.subTitle.feeling.context`)}
            </div>
            <div className={styles.place}>
              <div className={styles.contextLine}>
                {campaign ? (
                  <div>
                    {campaign.campaign.iconUrl && (
                      <span className={styles.choiceIcon}>
                        <img
                          alt='logo'
                          src={campaign.campaign.iconUrl}
                          className={styles.logo}
                        />
                      </span>
                    )}

                    <span className={styles.label}>
                      {getRelevantTranslationFor(campaign.name)}
                    </span>
                  </div>
                ) : feelingContext?.hasContext() && choiceSelected ? (
                  <div>
                    {getContextIcon(choiceSelected)}
                    <span className={styles.label}>{choiceSelected.label}</span>
                    <span className={styles.description}>
                      {choiceSelected.description}
                    </span>
                  </div>
                ) : (
                  choiceSelections.length > 1 && (
                    <DropdownButton
                      disabled={feelingContext?.hasContext()}
                      as={ButtonGroup}
                      variant={choiceSelected ? 'primary' : 'outline-primary'}
                      title={
                        choiceSelected
                          ? formatContextLabel(choiceSelected, true)
                          : t(`pages.feeling.subTitle.feeling.place`)
                      }
                      id='contextChoices'
                    >
                      {choiceSelections.map((choice) => (
                        <Dropdown.Item
                          active={
                            choiceSelected &&
                            choice.value === choiceSelected?.value
                          }
                          key={`context-choice-${choice.value}`}
                          onClick={() => handleChoiceChange(choice)}
                        >
                          {formatContextLabel(choice)}
                        </Dropdown.Item>
                      ))}
                    </DropdownButton>
                  )
                )}
              </div>
            </div>
            <div className={styles.time}>
              <div className={styles.contextLine}>
                <FeelingEvaluationPeriodInput
                  disabled={!!feelingContext?.directLink}
                  selectedTime={selectedTime}
                  onChange={handleTimeChange}
                />
              </div>
            </div>
          </div>
          <div className={styles.container}>
            {feelingMode === undefined ? (
              <FeelingModeInput title={<></>} onChange={setFeelingMode} />
            ) : (
              <>
                <Histogram
                  title={t(`pages.feeling.histogram.title.${feelingMode}`)}
                  selectedIndex={feelingSelectedIndex}
                  onSelectedIndexChange={handleFeelingSelectionChange}
                  colorScheme={goodFeeling ? 'green' : 'red'}
                  srcIcon={goodFeeling ? HappyFeelingIcon : SadFeelingIcon}
                  legendLowComp={t(
                    `pages.feeling.histogram.legend${
                      goodFeeling ? 'Good' : 'Bad'
                    }.low`
                  )}
                  legendHighComp={t(
                    `pages.feeling.histogram.legend${
                      goodFeeling ? 'Good' : 'Bad'
                    }.high`
                  )}
                />
                <Histogram
                  title={t(`pages.feeling.histogram.title.energy`)}
                  selectedIndex={energySelectedIndex}
                  onSelectedIndexChange={handleEnergySelectionChange}
                  colorScheme='blue'
                  srcIcon={battery}
                  legendLowComp={
                    <HotelBedFillIcon
                      style={{ fontSize: '30px', color: '#A7B6D1' }}
                    />
                  }
                  legendHighComp={
                    <img src={runIcon} alt='icon' style={{ width: '30px' }} />
                  }
                />

                <div className={styles.footer}>
                  <Button
                    ref={submitBtnRef}
                    disabled={
                      feelingSelectedIndex === -1 || energySelectedIndex === -1
                    }
                    onClick={handleSaveClick}
                  >
                    {t('common.action.save')}
                  </Button>
                </div>
                <div className={styles.info}>
                  {choiceSelected && (
                    <FeelingReminder sphere={choiceSelected.sphere} />
                  )}
                </div>
              </>
            )}
          </div>
        </Container>
      </div>
    </motion.div>
  );
};

const getContributorSessions = (sessions: MySession[]) => {
  const sessionsContribution = sessions.filter((s) =>
    s.roles.some((r) => r === 'member' && s.category === 'contributor')
  );
  return sessionsContribution;
};

const getContributorSpheres = (
  spheres: Sphere[],
  members: MemberResponse[]
) => {
  const contributors = (members as MemberResponse[]).filter((m) =>
    m.roles?.find((r) => r === 'contributor')
  );
  const spheresContribution = (
    contributors
      .map((c) => spheres.find((s) => s.id === c.sphereId))
      .filter((s) => !!s) as Sphere[]
  ).sort((s1, s2) => s1.name.localeCompare(s2.name));

  return spheresContribution;
};

export default withGeoPosition(FeelingContributorPage as FC<WithGeoPoint>);
