import React, { useContext, useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import { m as motion } from 'framer-motion';
import api from '../../api/Api';
import happyFace from '../../assets/images/HappyFace.svg';
import notHappyFace from '../../assets/images/NotHappyFace.svg';
import stylesCampaign from './CampaignPage.module.scss';
import stylesModal from '../../components/ModalWrapper/ModalSlideShowWrapper.module.scss';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { extractLocationData } from '../../tools/routeTools';
import AbilityContext from '../../context/AbilityContext';
import GlobalStateContext from '../../context/globalState/GlobalStateContext';
import { CampaignContext } from '../../@types/seen-apps';
import { SET_CONTEXT } from '../../context/globalState/globalStateReducer';
import LoadingPage from '../loading/LoadingPage';
import { subject } from '@casl/ability';
import { ToastOnClose } from '../../components/ModalWrapper/ModalSlideShowWrapper';
import { getRelevantTranslationFor } from '../../tools/multiLingualTools';
import MarkdownComponent from '../../components/Markdown/MarkdownComponent';

const styles = { ...stylesModal, ...stylesCampaign };

type Props = {
  onSubmit?: (stateProps: any) => void;
  onClose?: () => void;
  variants?: any;
  onCloseNotifications?: ToastOnClose[];
};

const CampaignPage: React.FC<Props> = ({
  variants,
  onSubmit = () => {},
  onCloseNotifications = [],
  onClose = () => {},
}) => {
  const location = useLocation();
  const ability = useContext(AbilityContext);
  const {
    dispatch,
    state: { context, user, language },
  } = useContext(GlobalStateContext);
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const [title, setTitle] = useState('');
  const [feelingEnabled, setFeelingEnabled] = useState(false);
  const [message, setMessage] = useState('');
  const { t } = useTranslation('i18n');

  const forbidden = (message: string) => {
    onCloseNotifications?.push({
      content: message,
      options: {
        type: 'error',
      },
    });
    onClose();
  };

  const loadCampaign = async () => {
    setLoading(true);
    try {
      const {
        queryString: { campaignId, validationCode },
      } = extractLocationData(location);

      const campaign = await api.getUrlCampaign(campaignId, validationCode);

      if (
        campaign.category !== 'consumer' &&
        ability.cannot(
          'post',
          subject('feeling', {
            userId: user.userId,
            sessionId: campaign.sessionId || null,
            sphereId: campaign.sphereId || null,
            category: campaign.category,
          })
        )
      ) {
        if (campaign.sessionId) {
          forbidden(t('pages.feeling.context.sessionForbidden'));
        } else {
          forbidden(t('pages.feeling.context.sphereForbidden'));
        }
        return;
      }
      dispatch({
        type: SET_CONTEXT,
        payload: {
          urlCampaignCtx: {
            campaign,
            validationCode,
          } as CampaignContext,
        },
      });
    } catch (error) {
      dispatch({
        type: SET_CONTEXT,
        payload: {},
      });
      toast.error(t('common.invalid'));
      history.push('/');
      return;
    }
    setLoading(false);
  };

  useEffect(() => {
    const { queryString } = extractLocationData(location);
    const campaignId = queryString.campaignId;
    if (!campaignId) {
      return;
    }

    if (context?.urlCampaignCtx?.campaign.id !== campaignId) {
      loadCampaign();
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context?.urlCampaignCtx]);

  useEffect(() => {
    setTitle(
      getRelevantTranslationFor(context?.urlCampaignCtx?.campaign.name || '')
    );
    setMessage(
      getRelevantTranslationFor(
        context?.urlCampaignCtx?.campaign.campaign.messageForUser || ''
      ) || `## ${t(`pages.feeling.subTitle.feeling.default`)}`
    );
    setFeelingEnabled(
      !!context?.urlCampaignCtx?.campaign.campaign?.wizards.find(
        (w, index) => index === 0 && w.type === 'feeling'
      )
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context?.urlCampaignCtx, language]);

  const handleClick = (mode?: string) => {
    onSubmit({
      mode,
    });
  };

  const campaign = context?.urlCampaignCtx?.campaign;

  return context.urlCampaignCtx ? (
    <motion.div
      variants={variants}
      initial='hidden'
      animate='visible'
      exit='exit'
      className={styles.page}
    >
      <div className={styles.content}>
        <Container className={styles.campaignContainer}>
          <div className={styles.sphereTitle}>
            {campaign?.campaign.iconUrl ? (
              <img
                alt='logo'
                src={campaign?.campaign.iconUrl}
                className={styles.logo}
              />
            ) : (
              <></>
            )}
            <span>{title}</span>
          </div>

          {message && (
            <div className={styles.message}>
              <MarkdownComponent text={message} />
            </div>
          )}

          <div className={styles.campaignContent}>
            {feelingEnabled ? (
              <>
                <button
                  type='button'
                  className={styles.button}
                  onClick={() => handleClick('bad')}
                >
                  <img
                    src={notHappyFace}
                    alt='nothappyface'
                    width='100%'
                    height='100%'
                  />
                </button>
                <button
                  type='button'
                  className={styles.button}
                  onClick={() => handleClick('good')}
                >
                  <img
                    src={happyFace}
                    alt='happyFace'
                    width='100%'
                    height='100%'
                  />
                </button>
              </>
            ) : (
              <Button onClick={() => handleClick()}>
                {t('common.action.start')}
              </Button>
            )}
          </div>
        </Container>
      </div>
    </motion.div>
  ) : isLoading ? (
    <LoadingPage />
  ) : (
    <></>
  );
};

export default CampaignPage;
