import React, { useEffect, useState } from "react";

import { AnswerDefinition, Step } from "../../@types/form-api";
import AnswerComponent from "./AnswerComponent";
import { hasDynamicValues, replaceDynamicValues } from "../../tools/formTools";
import MarkdownComponent from "../Markdown/MarkdownComponent";
import { getRelevantTranslationFor } from "../../tools/multiLingualTools";
// import * as remarkSubSuper from 'remark-supersub';

interface StepComponentProps {
  step: Step;
  disabled?: boolean;
  printMode?: boolean;
  saving?: boolean;
  showError: boolean;
  values: Record<string, any>;
  onChange?: (id: string, value: any) => void;
}

const StepComponent: React.FC<StepComponentProps> = ({
  onChange = () => {},
  step,
  values,
  showError,
  disabled,
  saving,
  printMode,
}) => {
  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");

  const [isDynamic, setDynamic] = useState(false);

  useEffect(() => {
    const title = getRelevantTranslationFor(step.title);
    const description = getRelevantTranslationFor(step.description);
    setTitle(title);
    setDescription(description);
    setDynamic(hasDynamicValues(title) || hasDynamicValues(description));
  }, [step.title, step.description]);

  useEffect(() => {
    const title = getRelevantTranslationFor(step.title);
    const description = getRelevantTranslationFor(step.description);
    if (isDynamic) {
      setTitle(replaceDynamicValues(title, values));
      setDescription(replaceDynamicValues(description, values));
    }

    setDynamic(hasDynamicValues(title) || hasDynamicValues(description));
  }, [values, isDynamic, step.title, step.description]);

  const handleChange = (answer: AnswerDefinition, value: any) => {
    onChange(answer.id, value);

    //No scroll if N/A
    if (value === null) {
      return;
    }
    let autoScroll = true;
    if (answer.measureType?.type === "text") {
      autoScroll = false;
    } else if (
      answer.measureType?.type === "select" &&
      answer.measureType.selectOptions?.multiChoice
    ) {
      autoScroll = false;
    } else if (
      answer.measureType?.type === "choice" &&
      answer.measureType.choiceOptions?.multiChoice
    ) {
      autoScroll = false;
    }

    if (autoScroll) {
      document.getElementById(`answer-${answer.id}-end`)?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };
  return (
    <div>
      <h3>
        <MarkdownComponent text={title} />
      </h3>

      {step.description && <MarkdownComponent text={description} />}

      {step.answers.map((answer) => {
        return (
          <div id={`answer-${answer.id}`}>
            <AnswerComponent
              printMode={printMode}
              showError={showError}
              key={`${answer.id}`}
              onChange={(value) => handleChange(answer, value)}
              answer={answer}
              values={values || {}}
              disabled={disabled || saving}
            />
            <div id={`answer-${answer.id}-end`}></div>
          </div>
        );
      })}
    </div>
  );
};

export default StepComponent;
