import { FC, useEffect, useState } from 'react';

import ReactLoading from 'react-loading';
import { Task } from '../../@types/task-api';
import { ResponsivePie } from '@nivo/pie';
import PieTaskPercentLabel from './TaskPercentLabel';
import styles from './TasksSummary.module.scss';

type PercentProps = {
  percent: number;
  tasks?: undefined;
};
type TaskProps = {
  tasks: Partial<Task>[];
  percent?: undefined;
};

type Props = (PercentProps | TaskProps) & {
  variant?: 'light' | 'dark';
  size: 'small';
  isLoading?: boolean;
  className?: string;
};

const STATUSES = ['done', 'inProgress', 'todo'] as (
  | 'done'
  | 'inProgress'
  | 'todo'
)[];

type Data = {
  id: string;
  value: number;
  color: string;
  variant: string;
};

const getPercentOf = (count: number, total: number) => {
  return count === 0 ? 0 : Math.round((count / total) * 100);
};

const COLORS = {
  dark: {
    done: '#06ff53',
    inProgress: '#FF5F1F',
    todo: '#3c4263',
    empty: '#3c4263',
  },
  light: {
    done: '#06ff53',
    inProgress: '#FF5F1F',
    todo: '#eef5fe',
    empty: '#eef5fe',
  },
};

const TasksSummary: FC<Props> = ({
  isLoading,
  tasks,
  percent,
  variant = 'dark',
  ...props
}) => {
  const [summary, setSummary] = useState<{
    data: Data[];
    colors: string[];
  }>({
    data: [],
    colors: [],
  });

  useEffect(() => {
    let data: Data[];

    if (percent !== undefined) {
      data =
        percent === 0
          ? []
          : [
              {
                color: COLORS[variant].done,
                id: 'done',
                value: percent,
                variant,
              },
            ];
    } else {
      const total = tasks.filter((t) => t.status !== 'cancelled').length;
      data = STATUSES.reduce((acc, status) => {
        const count = tasks.filter((t) => t.status === status).length;

        const value = getPercentOf(count, total);
        return value
          ? [
              ...acc,
              {
                id: status,
                value,
                color: COLORS[variant][status],
                variant,
              },
            ]
          : acc;
      }, [] as Data[]);
    }

    const totalPercent = data.reduce((acc, d) => acc + d.value, 0);
    if (totalPercent < 100) {
      data.push({
        id: 'empty',
        color: COLORS[variant].empty,
        value: 100 - totalPercent,
        variant,
      });
    }

    setSummary({
      data,
      colors: data.map((d) => d.color),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tasks, percent]);

  return (
    <div className={styles.root} {...props}>
      {isLoading && (
        <ReactLoading
          type={'spin'}
          color={'#DDD'}
          height={'30px'}
          width={'30px'}
        />
      )}
      {!isLoading && (
        <ResponsivePie
          data={summary.data as any}
          margin={{ top: 5, right: 5, bottom: 5, left: 5 }}
          innerRadius={0.75}
          padAngle={0.7}
          cornerRadius={3}
          colors={summary.colors}
          // activeOuterRadiusOffset={8}
          borderWidth={1}
          borderColor={{
            from: 'color',
            modifiers: [['darker', 0.3]],
          }}
          arcLinkLabelsSkipAngle={10}
          arcLinkLabelsTextColor='#0e0f27'
          arcLinkLabelsThickness={2}
          arcLinkLabelsColor={{ from: 'color' }}
          arcLabelsSkipAngle={10}
          arcLabelsTextColor={{
            from: 'color',
            modifiers: [['darker', 2]],
          }}
          legends={[]}
          layers={['arcs', PieTaskPercentLabel]}
          defs={[
            {
              id: 'lines',
              type: 'patternLines',
              background: 'inherit',
              color: 'rgba(255, 255, 255, 0.3)',
              rotation: -45,
              lineWidth: 6,
              spacing: 10,
            },
          ]}
          tooltip={() => <></>}
          fill={[
            {
              match: {
                id: 'closed',
              },
              id: 'lines',
            },
          ]}
        />
      )}
    </div>
  );
};

export default TasksSummary;
