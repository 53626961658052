import React from "react";
import { ParticipationLayoutCtx } from "../../@types/seen-apps";

const ParticipationLayoutContext = React.createContext<ParticipationLayoutCtx>({
  isStepMenuOpen: true,
  toggleStepMenu: () => {},
  stepStateChangeHandler: () => {},
});

export const LayoutContextConsumer = ParticipationLayoutContext.Consumer;
export default ParticipationLayoutContext;
