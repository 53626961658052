import React, { FC, lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import Footer from "./components/footer/Footer";
import styles from "./AppRoutes.module.scss";
import {
  getDefaultPage,
  getMyselfFullReportPage,
  getSphereContributionRoute,
  getSphereFullReportRoute,
  getMyHistoryPage,
  getSphereJoinRoute,
  getCampaignAdminRoute,
  getAdminSphereListingRoute,
  getAdminSphereEngagementUpRoute,
  getAdminCreateSphereRoute,
  getAdminSphereSalesUpRoute,
  getAdminThinkingAxesListingRoute,
  getSessionRoute,
  getAdminSessionRoute,
  getSessionJoinRoute,
  getSessionReportRoute,
  getSphereFormEntitlementsRoute,
  getMyFormsPage,
  getCampaignRoute,
  getChatRoute,
  getGlobalMembersRoute,
  ShowDialog,
  getPromotionCodesRoute,
  getMyselfFormReportRoute,
  getAdminSphereRoute,
  getParticipationRoute,
  getCampaignReportsRoute as getCampaignReportRoute,
  getReportAdminRoute,
  getCampaignCustomReportRoute,
  getReportPartAdminRoute,
} from "./tools/routeTools";
import ModalSlideShowWrapper from "./components/ModalWrapper/ModalSlideShowWrapper";
import AddMemberPage from "./pages/sphere/AddMember/AddMemberPage";
import AddOrUpdateTagPage from "./pages/sphere/Tags/AddOrUpdateTagPage";
import AddOrUpdateFormEntitlementPage from "./pages/sphere/Forms/AddOrUpdateFormEntitlementPage";
import FeelingPage from "./pages/feeling/FeelingPage";
import ThinkingPage from "./pages/thinking/ThinkingPage";
import UpdateFeelingPage from "./pages/feeling/UpdateFeelingPage";
import UpdateThinkingPage from "./pages/thinking/UpdateThinkingPage";
import SphereNotAllowed from "./pages/sphere/SphereNotAllowed";
import LoadingPage from "./pages/loading/LoadingPage";
import SalesUpPage from "./pages/salesUp/SalesUpPage";
import ScanPage from "./pages/scan/ScanPage";
import CreateSessionPage from "./pages/session/CreateSessionPage";
import GuestRegistrationPage from "./pages/guest/GuestRegistrationPage";
import SessionNotAllowed from "./pages/session/SessionNotAllowed";
import AddOrUpdateSessionMemberPage from "./pages/session/AddOrUpdateSessionMemberPage";
import ShowLinkPage from "./pages/link/ShowUrlAliasPage";
import UpdateUrlAliasPage from "./pages/link/UpdateUrlAliasPage";
import AddUrlAliasPage from "./pages/link/AddUrlAliasPage";
import CampaignPage from "./pages/campaign/CampaignPage";
import AddOrUpdateSuperAdminMemberPage from "./pages/superAdmin/AddOrUpdateSuperAdminMemberPage";
import AddPromotionCodePage from "./pages/ambassador/AddPromotionCodePage";
import MyselfStrategyPage from "./pages/strategy/MyselfStrategyPage";
import StrategyAssessmentPage from "./pages/strategy/StrategyAssessmentPage";
import UpdateStrategyAssessmentPage from "./pages/strategy/UpdateStrategyAssessmentPage";
import CampaignEndPage from "./pages/campaign/CampaignEndPage";
import AddOrUpdateTagsSelectionPage from "./pages/sphere/Tags/AddOrUpdateTagsSelectionPage";

const ReactLazyPreload = (importStatement: any) => {
  const Component: any = React.lazy(importStatement);
  Component.preload = importStatement;
  return Component;
};

export const EmailsPage = ReactLazyPreload(
  () => import("./components/emails/EmailsPage")
);
export const ProfilePage = ReactLazyPreload(
  () => import("./components/profile/ProfilePage")
);
export const SubscriptionPage = ReactLazyPreload(
  () => import("./components/profile/SubscriptionPage")
);
export const PasswordPage = ReactLazyPreload(
  () => import("./components/password/PasswordPage")
);
export const ThinkingAxisSettingsPage = ReactLazyPreload(
  () => import("./components/thinkingAxis/thinkingAxisSettings")
);
export const AddFormPage = ReactLazyPreload(
  () => import("./components/forms/AddFormPage")
);
export const SelectFormContextPage = ReactLazyPreload(
  () => import("./components/forms/SelectFormContextPage")
);
export const UpdateFormPage = ReactLazyPreload(
  () => import("./components/forms/UpdateFormPage")
);

interface RouteProperties {
  path?: string;
  page: PageRoute;
  Component: any;
  exact: boolean;
  stretch?: boolean;
  startsWith?: boolean;
}

export enum PageRoute {
  MyselfPage = 0,
  SpherePage,
  SessionPage,
  AdminPage,
  AmbassadorPage,
  CampaignPage,
  ReportPage,
}

export const routes: RouteProperties[] = [
  {
    path: getDefaultPage(),
    page: PageRoute.MyselfPage,
    Component: lazy(() => import("./pages/myself/MyselfPage")),
    exact: true,
    stretch: true,
  },
  {
    path: getCampaignRoute({
      campaignId: ":campaignId",
    }),
    page: PageRoute.CampaignPage,
    Component: lazy(() => import("./pages/myself/MyselfPage")),
    exact: true,
  },
  {
    path: getMyselfFullReportPage(),
    page: PageRoute.MyselfPage,
    Component: lazy(() => import("./pages/myself/MyselfReportPage")),
    exact: true,
  },
  {
    path: getMyFormsPage(),
    page: PageRoute.MyselfPage,
    Component: lazy(() => import("./pages/myForms/MyFormsPage")),
    exact: true,
  },
  {
    path: getSphereJoinRoute({
      sphereId: ":sphereId",
      accessCode: ":accessCode",
    }),
    page: PageRoute.SpherePage,
    Component: lazy(() => import("./pages/sphere/SphereJoinPage")),
    exact: true,
  },
  {
    path: getSphereFullReportRoute({
      sphereId: ":sphereId",
    }),
    page: PageRoute.SpherePage,
    Component: lazy(() => import("./pages/sphere/SphereReportPage")),
    exact: false,
  },
  {
    path: getSphereContributionRoute({ sphereId: ":sphereId" }),
    page: PageRoute.SpherePage,
    Component: lazy(() => import("./pages/sphere/SpherePage")),
    exact: true,
  },
  {
    path: getMyHistoryPage(),
    page: PageRoute.MyselfPage,
    Component: lazy(() => import("./pages/history/HistoryPage")),
    exact: true,
  },
  {
    path: getAdminSphereRoute({ sphereId: ":sphereId" }),
    page: PageRoute.SpherePage,
    Component: lazy(() => import("./pages/sphere/SphereSettingsPage")),
    exact: true,
  },
  {
    path: getAdminSphereListingRoute(),
    page: PageRoute.MyselfPage,
    Component: lazy(() => import("./pages/sphere/SphereListingPage")),
    exact: true,
  },
  {
    path: getAdminCreateSphereRoute(),
    page: PageRoute.MyselfPage,
    Component: lazy(() => import("./pages/sphere/CreateSpherePage")),
    exact: true,
  },
  {
    path: getMyselfFormReportRoute({
      reportId: ":reportId",
    }),
    page: PageRoute.MyselfPage,
    Component: lazy(() => import("./pages/analyticsPage/MyselfReportPage")),
    exact: false,
  },
  {
    path: getAdminSphereEngagementUpRoute({ sphereId: ":sphereId" }),
    page: PageRoute.SpherePage,
    Component: lazy(
      () => import("./pages/sphere/Settings/entitlements/EngagementUpPage")
    ),
    exact: true,
  },
  {
    path: getSphereFormEntitlementsRoute({ sphereId: ":sphereId" }),
    page: PageRoute.SpherePage,
    Component: lazy(
      () => import("./pages/sphere/Forms/SphereFormEntitementsPage")
    ),
    exact: true,
  },
  {
    path: getSessionRoute({ sessionId: ":sessionId" }),
    page: PageRoute.SessionPage,
    Component: lazy(() => import("./pages/session/SessionPage")),
    exact: true,
  },
  {
    path: getAdminSessionRoute({ sessionId: ":sessionId" }),
    page: PageRoute.SessionPage,
    Component: lazy(() => import("./pages/session/SessionAdminMainPage")),
    exact: true,
  },
  {
    path: getSessionReportRoute({
      sessionId: ":sessionId",
    }),
    page: PageRoute.SessionPage,
    Component: lazy(() => import("./pages/session/SessionReportPage")),
    exact: false,
  },
  {
    path: getSessionJoinRoute({
      sessionId: ":sessionId",
      accessCode: ":accessCode",
    }),
    page: PageRoute.SessionPage,
    Component: lazy(() => import("./pages/session/SessionJoinPage")),
    exact: true,
  },
  {
    path: getAdminSphereSalesUpRoute({ sphereId: ":sphereId" }),
    page: PageRoute.SpherePage,
    Component: lazy(
      () => import("./pages/sphere/Settings/entitlements/SalesUpPage")
    ),
    exact: true,
  },
  {
    path: getPromotionCodesRoute({}),
    page: PageRoute.AmbassadorPage,
    Component: lazy(() => import("./pages/ambassador/PromotionCodesPage")),
    exact: true,
  },
  {
    path: getAdminThinkingAxesListingRoute(),
    page: PageRoute.AdminPage,
    Component: lazy(() => import("./pages/thinking/ThinkingAxesListingPage")),
    exact: true,
  },
  {
    path: getChatRoute({}),
    page: PageRoute.AdminPage,
    Component: lazy(() => import("./components/Chat/ChatPage")),
    exact: true,
  },
  {
    path: getGlobalMembersRoute({}),
    page: PageRoute.AdminPage,
    Component: lazy(() => import("./pages/superAdmin/SuperAdminMembersPage")),
    exact: true,
  },
  {
    path: getCampaignAdminRoute({
      campaignId: ":campaignId",
    }),
    page: PageRoute.AdminPage,
    Component: lazy(
      () => import("./pages/campaign/admin/CampaignAdminMainPage")
    ),
    exact: true,
  },
  {
    path: getCampaignReportRoute({
      campaignId: ":campaignId",
    }),
    page: PageRoute.AdminPage,
    Component: lazy(
      () => import("./pages/campaign/reports/CampaignReportMainPage")
    ),
    exact: true,
  },
  {
    path: getCampaignCustomReportRoute({
      campaignId: ":campaignId",
    }),
    page: PageRoute.AdminPage,
    Component: lazy(
      () => import("./pages/campaign/reports/CampaignCustomReportsPage")
    ),
    exact: true,
  },
  {
    path: getParticipationRoute({
      campaignId: ":campaignId",
    }),
    page: PageRoute.CampaignPage,
    Component: lazy(
      () => import("./pages/participation/ParticipationMainPage")
    ),
    exact: true,
  },
  {
    path: getReportAdminRoute({
      reportId: ":reportId",
    }),
    page: PageRoute.AdminPage,
    Component: lazy(() => import("./pages/report/admin/ReportAdminMainPage")),
    exact: true,
  },
  {
    path: getReportPartAdminRoute({
      reportId: ":reportId",
      reportPartId: ":reportPartId",
    }),
    page: PageRoute.AdminPage,
    Component: lazy(() => import("./pages/report/ReportAdminPage")),
    exact: true,
  },
];

export type Props = {
  initialized: boolean;
};

const AppRoutes: FC<Props> = ({ initialized }) => {
  return (
    <>
      <div className={styles.containerPage} id='page-wrap'>
        {initialized ? (
          <Suspense fallback={<div className={styles.page}></div>}>
            <Switch>
              {routes.reverse().map(({ path, Component, exact }) => (
                <Route
                  key={path}
                  exact={exact}
                  path={path}
                  render={() => (
                    <div className={styles.page}>
                      <Component />
                    </div>
                  )}
                />
              ))}
            </Switch>
          </Suspense>
        ) : (
          <div className={styles.page}>
            <LoadingPage />
          </div>
        )}
        <Footer />
      </div>
      {initialized && (
        <ModalSlideShowWrapper
          queryParam='show'
          matchingComponents={{
            [ShowDialog.addOrUpdateTag]: <AddOrUpdateTagPage />,
            [ShowDialog.addOrUpdateTagsSelection]: (
              <AddOrUpdateTagsSelectionPage />
            ),
            [ShowDialog.addOrUpdateFormEntitlement]: (
              <AddOrUpdateFormEntitlementPage />
            ),
            [ShowDialog.addSphereMember]: <AddMemberPage />,
            [ShowDialog.feeling]: <FeelingPage />,
            [ShowDialog.thinking]: <ThinkingPage />,
            [ShowDialog.profile]: <ProfilePage />,
            [ShowDialog.emails]: <EmailsPage />,
            [ShowDialog.password]: <PasswordPage />,
            [ShowDialog.createThinkingAxis]: <ThinkingAxisSettingsPage />,
            [ShowDialog.addForm]: <AddFormPage />,
            [ShowDialog.updateForm]: <UpdateFormPage />,
            [ShowDialog.selectFormContext]: <SelectFormContextPage />,
            [ShowDialog.updateThinkingAxis]: <ThinkingAxisSettingsPage />,
            [ShowDialog.feelingUpdate]: <UpdateFeelingPage />,
            [ShowDialog.thinkingUpdate]: <UpdateThinkingPage />,
            [ShowDialog.sphereNotAllowed]: <SphereNotAllowed />,
            [ShowDialog.sessionNotAllowed]: <SessionNotAllowed />,
            [ShowDialog.salesUp]: <SalesUpPage />,
            [ShowDialog.scan]: <ScanPage />,
            [ShowDialog.createSession]: <CreateSessionPage />,
            [ShowDialog.registration]: <GuestRegistrationPage />,
            [ShowDialog.addOrUpdateMemberSession]: (
              <AddOrUpdateSessionMemberPage />
            ),
            [ShowDialog.link]: <ShowLinkPage />,
            [ShowDialog.updateLink]: <UpdateUrlAliasPage />,
            [ShowDialog.addLink]: <AddUrlAliasPage />,
            [ShowDialog.addOrUpdateMember]: <AddOrUpdateSuperAdminMemberPage />,
            [ShowDialog.addOrUpdatePromotionCode]: <AddPromotionCodePage />,
            [ShowDialog.lifeCompass]: <MyselfStrategyPage />,
            [ShowDialog.subscription]: <SubscriptionPage />,
            form: <AddFormPage />,
            contributor: <FeelingPage />,
            consumer: <SalesUpPage />,
            campaign: <CampaignPage />,
            [ShowDialog.campaignEnd]: <CampaignEndPage />,
            enterCode: <ScanPage />,

            [ShowDialog.strategyAssessment]: (
              <StrategyAssessmentPage linkTo='user' />
            ),
            [ShowDialog.sphereStrategyAssessment]: (
              <StrategyAssessmentPage linkTo='sphere' />
            ),
            [ShowDialog.sessionStrategyAssessment]: (
              <StrategyAssessmentPage linkTo='session' />
            ),
            [ShowDialog.updateStrategyAssessment]: (
              <UpdateStrategyAssessmentPage />
            ),
          }}
        />
      )}
    </>
  );
};

export default React.memo(AppRoutes);
