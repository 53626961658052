import React, { useEffect, useState } from "react";
import styles from "./CreateSessionPage.module.scss";
import selectStyles from "../../styles/react-select.module.scss";
import { useTranslation } from "react-i18next";
import PageTitle from "../../components/PageTitle/PageTitle";
import { useLocation } from "react-router";
import { Container, Form, Row, Col, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import AsyncSelect from "react-select/async";
import api from "../../api/Api";
import {
  extractLocationData,
  getAdminSessionRoute,
} from "../../tools/routeTools";
import { MultiLingual } from "../../@types/sphere-api";
import ReactLoading from "react-loading";
import {
  SessionType,
  Category,
  Session,
  SessionResponse,
} from "../../@types/session-api";
import { useHistory } from "react-router-dom";
import MultiLingualInput from "../../components/MulitLingual/MultiLingualInput";
import SelectParentSessionInput from "../../components/session/SelectParentSessionInput";
import SessionTags from "../../components/session/SessionTags";
import {
  clearEmptyLanguage,
  getRelevantTranslationFor,
} from "../../tools/multiLingualTools";
import { getNumberValue, MAX_STEP_NUMBER } from "./SessionAdminPage";
import { getErrorTranslation } from "../../tools/errorTools";
import { captureException } from "@sentry/minimal";
import TagsInput from "../../components/input/TagsInput";
import GuestPreview from "./GuestPreview";
import AdvancedForm from "../../components/forms/AdvancedForm";
import { OptionsType } from "react-select";
import Select from "react-select";
import { Subscription } from "../../@types/member-api";

type OptionType = {
  value: string;
  label: string;
};

type CreateSessionPageProps = {
  onSubmit?: (state?: any) => void;
};

const CreateSessionPage: React.FC<CreateSessionPageProps> = ({ onSubmit }) => {
  const { t } = useTranslation("i18n");
  const history = useHistory();

  const [parent, setParent] = useState<Session>();
  const [defaultParentSessionId, setDefaultParentSessionId] =
    useState<string>();
  const [type, setType] = useState<SessionType>("group");
  const [tags, setTags] = useState<{ value: string; label: string }[]>([]);
  const [owners, setOwners] = useState<{ value: string; label: string }[]>([]);
  const [sphereId, setSphereId] = useState<string>("");
  const [category, setCategory] = useState<Category>("consumer");
  const [isLeaf, setIsLeaf] = useState<boolean>(false);
  const [stepNumber, setStepNumber] = useState<string>();
  const [name, setName] = useState<MultiLingual>({});
  const [publicName, setPublicName] = useState<MultiLingual>({});
  const [subscriptionOptions] = useState<OptionsType<OptionType>>([
    {
      value: "starter",
      label: `e-Nous ${t(`common.subscription.starter`)}`,
    },
    {
      value: "essentials",
      label: `e-Nous ${t(`common.subscription.essentials`)}`,
    },
  ]);
  const [subscription, setSubscription] = useState<OptionType>(
    subscriptionOptions[0]
  );
  const [saving, setSaving] = useState<boolean>(false);
  const location = useLocation();

  const [displayPublicName, setDisplayPublicName] = useState(false);

  const fetchMembers = async (search?: string) => {
    const response = await api.getSphereMembers({
      sphereId,
      max: 200,
      searchQuery: search,
      offset: 0,
      filters: ["active=true"],
    });
    const members = response.items.map((member) => ({
      label: `${member.name} - ${member.email}`,
      value: member.id,
    }));
    return members;
  };

  const handleStepNumberChange = (value: string) => {
    const stepNumber = getNumberValue(value);

    setStepNumber(stepNumber > MAX_STEP_NUMBER ? `${MAX_STEP_NUMBER}` : value);
  };

  useEffect(() => {
    let session = (location.state as any)?.session as SessionResponse;
    //Duplicate session process
    if (session) {
      setDefaultParentSessionId(
        session.parentSessionIds?.length
          ? session.parentSessionIds[session.parentSessionIds.length - 1]
          : undefined
      );
      setType(session.type);
      setTags(
        session.tags?.map((t) => ({
          label: getRelevantTranslationFor(t.name),
          value: t.id,
        })) || []
      );
      setSphereId(session.sphere.id);
      setCategory(session.category);
      setIsLeaf(session.isLeaf);
      setStepNumber(
        Number.isInteger(session.stepNumber) ? `${session.stepNumber}` : ""
      );
      setName(session.name);
      setPublicName(session.publicName);
    } else {
      const urlData = extractLocationData(location);
      const type = urlData.queryString.type as SessionType;
      setType(type);

      const defaultParentSessionId = urlData.queryString
        .parentSessionId as string;
      setDefaultParentSessionId(defaultParentSessionId);

      const sphereId = urlData.queryString.sphereId || urlData.params.sphereId;
      setSphereId(sphereId);

      const category = (urlData.queryString.category ||
        urlData.params.category) as Category;
      setCategory(category);
      setDisplayPublicName(category === "consumer");
    }
  }, [location]);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    event.stopPropagation();

    if (!name?.default || (displayPublicName && !publicName?.default)) {
      return;
    }
    setSaving(true);

    const ownerIds = owners?.map((o) => o.value) || [];
    const nameCleaned = clearEmptyLanguage(name);
    const publicNameCleaned = displayPublicName
      ? clearEmptyLanguage(publicName)
      : clearEmptyLanguage(name);
    try {
      const session = await api.createSession({
        category,
        isLeaf,
        name: nameCleaned,
        publicName: publicNameCleaned,
        specificTagIds: tags.map((t) => t.value),
        type,
        parentSessionId: parent?.id,
        sphereId: parent ? undefined : sphereId,
        ownerIds,
        stepNumber: category === "consumer" ? getNumberValue(stepNumber) : 0,
        subscription: subscription.value as Subscription,
      });

      toast.success(t(`pages.sphere.sessions.${category}.create.success`));

      // if (onSubmit) {
      //   onSubmit();
      // }
      history.push(
        getAdminSessionRoute({
          sessionId: session.id,
        })
      );
    } catch (error: any) {
      captureException(error);
      toast.error(
        getErrorTranslation({ t, error, defaultKey: "common.error" })
      );
    }
    setSaving(false);
  };

  const loadOwnersOptions = (
    inputValue: string,
    callback: (data: { label: string; value: string }[]) => void
  ) => {
    fetchMembers(inputValue).then((owners) => {
      callback(owners);
    });
  };

  const handleInputOwnerChange = (newValue: string) => {
    const inputValue = newValue.replace(/\W/g, "");
    return inputValue;
  };

  const handleOwnerSelected = (ownerSelected: any) => {
    setOwners(ownerSelected);
  };
  const handleIsLeafChange = (isLeaf: boolean) => {
    setIsLeaf(isLeaf);
  };

  return !sphereId || !type || !category ? (
    <div></div>
  ) : (
    <div className={styles.page}>
      <PageTitle
        title={t(`pages.sphere.sessions.settings.${category}.create`)}
      />
      <div className={styles.content}>
        <Container>
          <Form onSubmit={handleSubmit} autoComplete='off' noValidate>
            <Row>
              <Col sm={6}>
                <Form.Group controlId='tags'>
                  <Form.Label>
                    {t(`pages.sphere.sessions.${category}.parent`)}
                  </Form.Label>
                  <SelectParentSessionInput
                    sphereId={sphereId}
                    defaultValue={defaultParentSessionId}
                    value={parent}
                    onChange={setParent}
                    category={category}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <MultiLingualInput
                  required
                  label={t(`pages.sphere.sessions.${category}.name`)}
                  value={name}
                  onChange={(value) => setName(value)}
                />
              </Col>
            </Row>
            {displayPublicName && (
              <>
                <Row className={styles.noMargin}>
                  <Col sm={6} className={`${styles.relative}`}>
                    <MultiLingualInput
                      required
                      label={t(`pages.sphere.sessions.${category}.publicName`)}
                      value={publicName}
                      onChange={(value) => setPublicName(value)}
                    />
                  </Col>
                </Row>
                <Row className={styles.noMargin}>
                  <Col sm={6} className={styles.noMargin}>
                    <GuestPreview
                      className={styles.preview}
                      label={t(`pages.sphere.sessions.${category}.preview`)}
                      previewLabel={publicName}
                    />
                  </Col>
                </Row>
              </>
            )}
            {category === "contributor" && (
              <Row>
                <Col sm={6}>
                  <Form.Group controlId='subscription'>
                    <Form.Label>
                      {t(`pages.sphere.sessions.${category}.license`)} *
                    </Form.Label>
                    <div className={selectStyles.reactSelect}>
                      <Select
                        options={subscriptionOptions}
                        defaultValue={subscription}
                        value={subscription}
                        onChange={(value) =>
                          setSubscription(value || subscriptionOptions[0])
                        }
                        isSearchable={false}
                        isClearable={false}
                        isMulti={false}
                        className={selectStyles.reactSelect}
                        classNamePrefix='custom-react-select'
                      />
                    </div>
                  </Form.Group>
                </Col>
              </Row>
            )}
            <Row>
              <Col sm={6}>
                <Form.Group controlId='tags'>
                  <Form.Label>{t(`pages.sphere.sessions.tags`)}</Form.Label>
                  <TagsInput
                    sphereId={sphereId}
                    onChange={(items) => {
                      setTags(items);
                    }}
                    module={`session-${category}`}
                    tagsSelected={tags}
                    defaultValueTagWhenCreation={{
                      name,
                      modules: [`session-${category}`],
                    }}
                  />
                  <div className={styles.inherited}>
                    <div className={styles.inheritedTitle}>
                      {t("pages.sphere.sessions.inherited")}
                    </div>
                    <SessionTags
                      className={styles.inheritedTags}
                      session={parent}
                      variant='secondary'
                    />
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group controlId='owners'>
                  <Form.Label>{t(`pages.sphere.sessions.owners`)}</Form.Label>
                  <AsyncSelect
                    className={selectStyles.reactSelect}
                    classNamePrefix='custom-react-select'
                    loadOptions={loadOwnersOptions}
                    defaultOptions
                    isMulti
                    onInputChange={handleInputOwnerChange}
                    onChange={handleOwnerSelected}
                    placeholder={t(`pages.sphere.sessions.owners`)}
                    autoFocus
                    value={owners}
                    noOptionsMessage={() => t("common.noValue")}
                  />
                </Form.Group>
              </Col>
            </Row>
            <AdvancedForm defaultStatus='closed'>
              <Container className={styles.noMargin}>
                <Row className={styles.noMargin}>
                  <Col sm={6} className={styles.noMargin}>
                    <Form.Group controlId='isLeaf'>
                      <Form.Check
                        type='switch'
                        id='nameAutoFill'
                        name='nameAutoFill'
                        label={t(`pages.sphere.sessions.${category}.isLeaf`)}
                        checked={isLeaf}
                        onChange={(event: any) => {
                          handleIsLeafChange(event.target.checked);
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                {category === "consumer" && (
                  <Row className={styles.noMargin}>
                    <Col sm={6} className={styles.noMargin}>
                      <Form.Group controlId='stepNumber'>
                        <Form.Label>
                          {t(`pages.sphere.sessions.settings.stepNumber`)}
                        </Form.Label>
                        <Form.Control
                          type='number'
                          maxLength={2}
                          value={stepNumber}
                          onChange={(event) =>
                            handleStepNumberChange(event.target.value)
                          }
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                )}
              </Container>
            </AdvancedForm>
            <hr />
            <Row>
              <Col sm={6} className={styles.buttons}>
                <Button
                  variant='secondary'
                  className={styles.right}
                  onClick={onSubmit}
                >
                  {t("common.action.close")}
                </Button>
                <Button name='submit' type='submit'>
                  {saving && (
                    <div className={styles.submitContent}>
                      <ReactLoading
                        type={"bars"}
                        color={"#DDD"}
                        height={"30px"}
                        width={"40px"}
                      />
                      <div className={styles.submitText}>
                        {t("common.action.saving")}
                      </div>
                    </div>
                  )}
                  {!saving && t("common.action.save")}
                </Button>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </div>
  );
};

export default CreateSessionPage;
