/* eslint react-hooks/exhaustive-deps: 0 */
import React, {
  FC,
  useState,
  useEffect,
  ReactElement,
  Suspense,
  useContext,
} from "react";
import { m as motion, AnimatePresence } from "framer-motion";
import { useHistory, useLocation, matchPath } from "react-router-dom";
import { v5 as uuidv5 } from "uuid";
import qs from "qs";
import { Location } from "history";
import styles from "./ModalSlideShowWrapper.module.scss";
import { routes } from "../../AppRoutes";
import RoundedButton from "../RoundedButton/RoundedButton";
import { CloseLineIcon } from "../RemixIcons";
import { toast, ToastContent, ToastOptions } from "react-toastify";
import GlobalStateContext from "../../context/globalState/GlobalStateContext";
import { Wizard } from "../../@types/shorten-url-api";
import { ShowDialog } from "../../tools/routeTools";

const variantsModal = {
  hidden: {
    opacity: 0,
    top: "-100vh",
  },
  visible: {
    opacity: 1,
    top: 0,
    transition: {
      type: "tween",
      ease: "easeOut",
      duration: 0.6,
    },
  },
  exit: {
    opacity: 0,
    top: "-100vh",
    transition: {
      type: "tween",
      ease: "easeIn",
      duration: 0.6,
    },
  },
};

const variantsOnlyOnePage = {
  hidden: {
    x: 0,
  },
  visible: {
    x: 0,
  },
  exit: {
    x: 0,
  },
};

const variantsFirstPage = {
  hidden: {
    x: 0,
  },
  visible: {
    x: 0,
  },
  exit: {
    x: "-100vw",
    transition: {
      type: "tween",
      duration: 0.8,
    },
  },
};

const variantsOtherPages = {
  hidden: {
    x: "100vw",
  },
  visible: {
    x: 0,
    transition: {
      type: "tween",
      duration: 0.8,
    },
  },
  exit: {
    x: "-100vw",
    transition: {
      type: "tween",
      duration: 0.8,
    },
  },
};

const variantsLastPage = {
  hidden: {
    x: "100vw",
  },
  visible: {
    x: 0,
    transition: {
      type: "tween",
      duration: 0.8,
    },
  },
  exit: {
    x: 0,
  },
};

const checkIfQueryParamExists = (
  queryParam: string,
  location: Location
): boolean => {
  return !!new URLSearchParams(location.search).get(queryParam);
};

const getUrlParams = (url: string) => {
  return routes.reduce((accumulator: any, route) => {
    const match: any = matchPath(url, {
      path: route.path,
      exact: route.exact,
    });
    return {
      ...accumulator,
      ...match?.params,
    };
  }, {});
};

type Props = {
  queryParam: string;
  matchingComponents: Record<string, ReactElement>;
};

export type ToastOnClose = {
  content: ToastContent;
  options?: ToastOptions | undefined;
};

type PageElement = { element: ReactElement; wizzard?: Wizard };

type State = {
  currentPageIdx: number;
  originPathname: string;
  pageElements: PageElement[];
  variants?: any;
  onCloseNotifications: ToastOnClose[];
};

let lastLocation: Location;

const ModalSlideShowWrapper: FC<Props> = ({
  queryParam,
  matchingComponents,
}) => {
  const { state: globalState } = useContext(GlobalStateContext);
  const history = useHistory();
  let location = useLocation();
  const [state, setState] = useState<State>({
    currentPageIdx: 0,
    originPathname: location.pathname,
    pageElements: [],
    variants: null,
    onCloseNotifications: [],
  });
  const [showModal, setShowModal] = useState<string>(
    checkIfQueryParamExists(queryParam, location)
      ? uuidv5(location.search, uuidv5.URL)
      : undefined
  );
  const onRouteChange = (newLocation: Location) => {
    const showModal = checkIfQueryParamExists(queryParam, newLocation);
    setShowModal(
      showModal ? uuidv5(newLocation.search, uuidv5.URL) : undefined
    );
    if (!showModal) {
      lastLocation = newLocation;
    }
  };

  useEffect(() => {
    if (showModal) {
      setinitHistoryLength(history.length);
      let queryParams = qs.parse(
        location.search.startsWith("?")
          ? location.search.substring(1)
          : location.search || {}
      ) as Record<string, any>;
      const urlParams = getUrlParams(location.pathname);

      const modulesToShow = queryParams[queryParam].split(",") as string[];
      const campaign = globalState?.context?.urlCampaignCtx?.campaign;
      let pageElements: PageElement[];
      if (modulesToShow.some((m) => m === "campaign") && campaign) {
        queryParams = {
          ...queryParams,
          // sessionId: campaign.sessionId,
          // sphereId: campaign.sphereId,
          memberCategory: campaign.category,
          disableThinkingCreation: true,
          directLink: true,
          validationCode: globalState?.context?.urlCampaignCtx?.validationCode,
          campaign,
        };

        pageElements = [
          { element: matchingComponents["campaign"], wizzard: undefined },
          ...(globalState?.context?.urlCampaignCtx?.campaign.campaign?.wizards?.map(
            (wizzard) => {
              return { element: matchingComponents[wizzard.type], wizzard };
            }
          ) || []),
          {
            element: matchingComponents[ShowDialog.campaignEnd],
            wizzard: undefined,
          },
        ];
      } else {
        pageElements = modulesToShow.map((module) => ({
          element: matchingComponents[module],
          wizzard: undefined,
        }));
      }

      setState({
        currentPageIdx: 0,
        pageElements,
        originPathname: location.pathname,
        ...queryParams,
        ...urlParams,
        variants:
          queryParams[queryParam].split(",").length > 1
            ? variantsFirstPage
            : variantsOnlyOnePage,
        onCloseNotifications: [],
      });
    }
  }, [showModal, globalState?.context?.urlCampaignCtx]);

  useEffect(() => {
    lastLocation = location;
    history.listen(onRouteChange);
    const changes = () => {
      setHistoryLength(window.history.length);
    };
    window.ontransitionstart = changes;
  }, [history]);

  /**
   * Stonly add iframe and navigation in iFrame add history items.
   * To go back we need to know the number of history added by the iframe
   *
   */
  const [initHistoryLength, setinitHistoryLength] = useState(-1);
  const [historyLength, setHistoryLength] = useState(-1);
  useEffect(() => {
    setHistoryLength(history.length);
  }, [history]);

  const onClose = () => {
    //if lastlocation contains show query param, we enter to seensapps directly from this url
    //so remove quey params to stay in seenapps
    if (checkIfQueryParamExists(queryParam, lastLocation)) {
      history.replace(lastLocation.pathname);
    }
    //else modal has been opened from seenapps page so goBack to this page
    else if (initHistoryLength && historyLength) {
      for (let index = initHistoryLength; index <= historyLength; index++) {
        history.goBack();
      }
      setinitHistoryLength(historyLength);
    } else {
      history.goBack();
    }

    setTimeout(() => {
      //send notification?
      state.onCloseNotifications?.forEach(({ content, options }) => {
        toast(content, options);
      });
    }, 1000);
  };

  const gotoNextPage = (newPropsState: any = {}) => {
    const { currentPageIdx, pageElements } = state;
    if (currentPageIdx + 1 < pageElements.length) {
      const variants =
        currentPageIdx + 1 < pageElements.length - 1
          ? variantsOtherPages
          : variantsLastPage;
      setState({
        ...state,
        currentPageIdx: currentPageIdx + 1,
        ...newPropsState,
        variants,
      });
    } else {
      onClose();
    }
  };

  const handleExitComplete = () => {
    setState({
      currentPageIdx: 0,
      pageElements: [],
      originPathname: "",
      onCloseNotifications: [],
    });
  };

  const page = state.pageElements[state.currentPageIdx];
  return (
    <AnimatePresence exitBeforeEnter onExitComplete={handleExitComplete}>
      {showModal && (
        <motion.div
          className={styles.root}
          variants={variantsModal}
          initial='hidden'
          animate='visible'
          exit='exit'
          key={showModal}
        >
          <RoundedButton className={styles.close} onClick={() => onClose()}>
            <CloseLineIcon />
          </RoundedButton>
          <Suspense fallback={<div></div>}>
            <AnimatePresence>
              {page &&
                React.cloneElement(page.element, {
                  key: `${showModal}_${state.currentPageIdx}`,
                  ...(page.element.props || {}),
                  ...state,
                  wizzard: page.wizzard,
                  onSubmit: gotoNextPage,
                  onClose,
                })}
            </AnimatePresence>
          </Suspense>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default ModalSlideShowWrapper;
