import { useContext, useEffect, useState } from "react";
import { slide as Menu } from "react-burger-menu";
import burgerStyles from "../../components/side-menus/react-burger-menu.module.scss";
import menuStyles from "./ParticipationMenu.module.scss";
import { useTranslation } from "react-i18next";
import GlobalStateContext from "../../context/globalState/GlobalStateContext";
import ParticipationLayoutContext from "../../context/participation/ParticipationLayoutContext";
import { isMobile } from "react-device-detect";
import ParticipationMenuItem, { MeasureItem } from "./ParticipationMenuItem";

const styles = { ...burgerStyles, ...menuStyles };

const ParticipationMenu = () => {
  const layoutContext = useContext(ParticipationLayoutContext);
  const { t } = useTranslation("i18n");
  const { state, dispatch } = useContext(GlobalStateContext);

  const participation = state.context?.participationCtx;
  const [menu, setMenu] = useState<MeasureItem[]>([]);

  useEffect(() => {
    const menu: MeasureItem[] = (participation?.stepDefinitions || []).reduce(
      (acc, step) => {
        const parent = acc.find((m) => m.id === step.measureId);
        if (parent) {
          parent.stepDefinitions.push(step);
          return acc;
        }
        const item: MeasureItem = {
          id: step.measureId,
          position: acc.length + 1,
          title: step.title,
          message: step.message,
          stepDefinitions: [step],
          values: [],
        };
        return [...acc, item];
      },
      [] as MeasureItem[]
    );

    menu.forEach((item) => {
      item.values = (participation?.values || []).filter((value) =>
        item.stepDefinitions.some(
          (stepDefinition) => stepDefinition.stepId === value.stepId
        )
      );
    });
    setMenu(menu);
  }, [dispatch, participation?.stepDefinitions, participation?.values, t]);

  const handleClicked = (stepId: number) => {
    //Is step reachable?
    const step = participation?.values.find((step) => step.stepId === stepId);
    const previousStep = participation?.values.find(
      (step) => step.stepId === stepId - 1
    );
    if (
      !step ||
      (step.stepStatus === "todo" && previousStep?.stepStatus === "todo")
    )
      return;

    dispatch({
      type: "SET_CONTEXT",
      payload: {
        participationCtx: {
          ...participation,
          currentStepId: stepId,
        },
      },
    });
  };

  const handleOnClose = () => {
    layoutContext.stepStateChangeHandler({ isOpen: false });
  };
  return (
    <div className={`${styles.root}`}>
      <Menu
        onClose={handleOnClose}
        className={styles.menuContainer}
        width={layoutContext.isStepMenuOpen ? 250 : 50}
        customBurgerIcon={false}
        customCrossIcon={false}
        pageWrapId={"page-step-wrap"}
        isOpen={true}
        noOverlay={!isMobile || !layoutContext.isStepMenuOpen}
        onStateChange={(state: any) => {
          layoutContext.stepStateChangeHandler(state);
        }}
      >
        <div className={`navbar-nav ${styles.leftSideMenu} ${styles.stepMenu}`}>
          {menu.map((menuItem) => {
            return (
              <ParticipationMenuItem
                size={layoutContext.isStepMenuOpen ? "lg" : "sm"}
                key={`MENU_${menuItem.id}`}
                measure={menuItem}
                currentStep={participation?.currentStepId || 0}
                onClick={handleClicked}
              />
            );
          })}
        </div>
      </Menu>
    </div>
  );
};

export default ParticipationMenu;
