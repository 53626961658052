import { useContext, useState, FC, useEffect } from 'react';
import stylesUpdateProfile from './UpdateFeelingPage.module.scss';
import stylesModal from '../../components/ModalWrapper/ModalSlideShowWrapper.module.scss';
import { useTranslation } from 'react-i18next';
import GlobalStateContext from '../../context/globalState/GlobalStateContext';
import { useLocation } from 'react-router-dom';
import WordGrid from '../../components/feeling/WordGrid';
import { Container, Col, Row, Button } from 'react-bootstrap';
import {
  convertToMoodMeter,
  getWordTranslated,
} from '../../tools/feelingTools';
import api from '../../api/Api';
import { toast } from 'react-toastify';
import { UPDATE_FEELING } from '../../context/globalState/globalStateReducer';
import { Feeling, FeelingGoal, MoodMeter, Note } from '../../@types/seen-apps';
import moment from 'moment';
import LocationView from '../../components/location/LocationView';
import SphereNameView from '../../components/sphere/SphereNameView';
import DateView from '../../components/date/DateView';
import MoodMeterPolygon from '../../components/feeling/MoodMeterPolygon';
import PageTitle from '../../components/PageTitle/PageTitle';
import FeelingReminder from './FeelingReminder';
import SessionNameView from '../../components/session/SessionNameView';
import { captureException } from '@sentry/minimal';
import PeriodView from '../../components/date/PeriodView';
import Notes from '../../components/feeling/note/Notes';
import { useAbility } from '@casl/react';
import AbilityContext, { Can } from '../../context/AbilityContext';
import HorizontalRule from '../../components/horizontalRule/HorizontalRule';
import { isEqual } from 'lodash';
import LoadingPage from '../loading/LoadingPage';
import FeelingAIModal from './FeelingAIModal';
import { subject } from '@casl/ability';
import {
  ChatSuggestion,
  ContextQuestion,
} from '../../components/Chat/ChatDialog';
import PreviewContainer from '../../components/Preview/PreviewContainer';
import FeelingGoals from '../../components/feeling/goal/FeelingGoals';
import { PersonalNoteIcon } from '../../components/RemixIcons';
import al from '../../assets/images/al.webp';
import impactedImage from '../../assets/images/impacted.webp';

const styles = { ...stylesModal, ...stylesUpdateProfile };

type Props = {
  onSubmit?: (state?: any) => void;
};

const UpdateFeelingPage: FC<Props> = ({ onSubmit = () => {} }) => {
  const { state, dispatch } = useContext(GlobalStateContext);
  const location = useLocation();
  const { t } = useTranslation('i18n');
  const [notes, setNotes] = useState<Note[]>([]);
  const [goals, setGoals] = useState<FeelingGoal[]>([]);
  const [hasNotesChanged, setNotesChanged] = useState<boolean>(false);
  const [moodMetreData, setMoodMetreData] = useState<MoodMeter>({});
  const ability = useAbility(AbilityContext);

  const feelingId = new URLSearchParams(location.search).get('feelingId') || '';

  const [initialized, setInitialized] = useState(false);

  const [aiContext, setAiContext] = useState<{
    mode: string;
    initiationText: string;
    contextQuestions?: ContextQuestion[];
    suggestions?: ChatSuggestion[];
    disableFreeText?: boolean;
  }>();
  const [aiShow, setAiShow] = useState(false);

  const [feeling, setFeeling] = useState<Feeling & { couldUpdate: boolean }>();

  useEffect(() => {
    const getFeeling = async () => {
      setInitialized(false);

      let feeling = state.feelings.find((f) => f.id === feelingId);
      if (!feeling) {
        feeling = await api.getFeeling(feelingId);
      }
      if (!feeling) {
        setInitialized(true);
        return;
      }
      setNotes(feeling.personalNotes || []);
      if (goals.every((g) => g.validated)) {
        setGoals(feeling.goals?.map((g) => ({ ...g, validated: true })) || []);
      }

      const couldUpdate = feeling.freezeDate
        ? moment(feeling.freezeDate).isAfter(moment())
        : moment(feeling.timestamp).add(1, 'hour').isAfter(moment());

      setMoodMetreData(convertToMoodMeter([feeling]));
      setFeeling({
        ...feeling,
        couldUpdate,
        goals: [],
      });
      setInitialized(true);
    };
    if (feelingId) {
      getFeeling();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.feelings, feelingId, state.spheres, t]);

  useEffect(() => {
    if (!feeling) {
      return;
    }

    setNotesChanged(!isEqual(feeling.personalNotes || [], notes));
  }, [feeling, notes]);

  const handleSaveNotes = async (personalNotes: Note[]) => {
    if (!feeling) {
      return;
    }
    try {
      const result = await api.updateFeeling(feeling.id, {
        personalNotes,
      });
      toast.success(t('pages.feeling.updated.success'));
      dispatch({
        type: UPDATE_FEELING,
        payload: result,
      });
    } catch (error) {
      captureException(error);
      toast.error(t('pages.feeling.updated.error', { error }));
    }
  };

  const handleWordSelected = async (wordId: string) => {
    if (!feeling) {
      return;
    }
    try {
      const result = await api.updateFeeling(feeling.id, {
        wordId,
      });
      toast.success(t('pages.feeling.updated.success'));
      dispatch({
        type: UPDATE_FEELING,
        payload: result,
      });
    } catch (error) {
      captureException(error);
      toast.error(t('pages.feeling.updated.error', { error }));
    }
  };

  const handleMoreFeeling = () => {
    if (!feeling) {
      return;
    }

    const MORE_QUESTIONS = [
      'physiologicalSensations',
      'advantages',
      'disadvantages',
      'causes',
      'needs',
    ];

    const feelingWord = getWordTranslated(state, feeling.wordId);
    setAiContext({
      mode: 'feeling-more',
      initiationText: t('pages.feeling.extra.more', {
        feeling: feelingWord,
      }),
      disableFreeText: true,
      suggestions: [
        ...MORE_QUESTIONS.map((question) => ({
          id: question,
          text: t(`pages.feeling.extra.more-suggestions.${question}`, {
            feeling: feelingWord,
          }),
          onlyOnce: true,
        })),
        {
          id: 'close',
          text: t(`pages.feeling.extra.toPersonalNotes`),
          onlyOnce: true,
          closeDialogAfter: true,
        },
      ],
    });

    setAiShow(true);
  };

  const handleControlFeeling = () => {
    if (!feeling) {
      return;
    }

    const contextDefinition = [
      {
        id: 'place',
        values: [
          'home',
          'work',
          'public',
          'private',
          'school',
          'nature',
          'health',
          'leisure',
        ],
      },
      {
        id: 'people',
        values: [
          'alone',
          'business',
          'family',
          'friends',
          'love',
          'community',
          'animal',
          'foreigners',
        ],
      },
      { id: 'time', values: ['5', '15', '30', '60', 'needed'] },
      // {
      //   id: 'regulationStrategySelected',
      //   values: [
      //     'situation-selection',
      //     'situation-modification',
      //     'attentional-deployment',
      //     'cognitive-change',
      //     'response-modulation',
      //   ],
      // },
    ];

    setAiContext({
      mode: 'feeling-control',
      initiationText: t('pages.feeling.extra.control', {
        feeling: getWordTranslated(state, feeling.wordId),
      }),
      contextQuestions: contextDefinition.map((c) => ({
        contextId: c.id,
        question: t(`pages.feeling.extra.control-questions.${c.id}.question`),
        allowFreeText: true,
        suggestions: c.values.map((id) => ({
          id: id,
          text: t(`pages.feeling.extra.control-questions.${c.id}.${id}`),
        })),
      })),
      suggestions: [
        {
          id: 'close',
          text: t(`pages.feeling.extra.toPersonalNotes`),
          onlyOnce: true,
          closeDialogAfter: true,
        },
      ],
      disableFreeText: false,
    });
    setAiShow(true);
  };

  const handleChatClosed = (text?: string) => {
    setAiShow(false);
    if (text) {
      const id = notes.reduce((acc, note) => Math.max(note.id, acc), 0) + 1;

      const newNotes = [
        ...notes,
        {
          id,
          value: text,
        },
      ];
      setNotes(newNotes);
      handleSaveNotes(newNotes);
    }
  };

  const handleGoalsChanged = async (goals: FeelingGoal[]) => {
    setGoals(goals);
    if (!feeling || goals.some((g) => !g.validated)) {
      return;
    }

    try {
      goals.sort((g1, g2) => g1.ranking - g2.ranking);
      const result = await api.updateFeeling(feeling.id, {
        goals: goals.map((g) => ({ ...g, validated: undefined })),
      });
      toast.success(t('pages.feeling.updated.success'));
      dispatch({
        type: UPDATE_FEELING,
        payload: result,
      });
    } catch (error) {
      captureException(error);
      toast.error(t('pages.feeling.updated.error', { error }));
    }
  };

  return !initialized ? (
    <LoadingPage />
  ) : !feeling ? (
    <div>{t('common.not-found.feeling')}</div>
  ) : (
    <div className={styles.page}>
      <PageTitle title={t(`pages.feeling.update.title`)} />
      <div className={styles.content}>
        <Container className={styles.updateFeelingContainer}>
          <Row className={styles.updateFeelingRow}>
            <Col className={styles.updateFeelingCol}>
              <div className={styles.details}>
                <div className={styles.moodMeterImage}>
                  <MoodMeterPolygon
                    showNumber={false}
                    size='medium'
                    moodMetre={moodMetreData}
                  />
                </div>
                <div className={styles.details}>
                  <div className={styles.detailsItem}>
                    <DateView date={feeling.timestamp} full />
                    <PeriodView period={feeling.evaluationPeriod} />
                    {feeling.session && (
                      <SessionNameView session={feeling.session} />
                    )}
                    <SphereNameView
                      sphere={feeling.sphere}
                      full
                      sphereName={feeling.sphereName}
                    />
                  </div>

                  <div className={styles.detailsItem}>
                    <LocationView location={feeling.location} full />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className={styles.updateFeelingRow}>
            <Col className={styles.updateFeelingCol}>
              <HorizontalRule
                icon={
                  <MoodMeterPolygon
                    monochrome='light'
                    showNumber={false}
                    onlySVG
                    size='xsmall'
                    moodMetre={{}}
                  />
                }
                text={t('pages.feeling.update.word', {
                  word: t(`words.${feeling.wordId}`),
                })}
              />

              <WordGrid
                disabled={!feeling.couldUpdate}
                energyValue={feeling.energy}
                feelingValue={feeling.value}
                wordId={feeling.wordId}
                onWordSelected={handleWordSelected}
              />
            </Col>
          </Row>
          {!!feeling?.couldUpdate && (
            <Can
              I='post'
              a={subject('chat-thread', {}) as any}
              ability={ability}
            >
              <Row className={styles.connectionRow}>
                <Col className={styles.connectionCol}>
                  <PreviewContainer className={styles.chatButtonsContainer}>
                    <div className={styles.chatButtons}>
                      <Button
                        variant='outline-primary'
                        onClick={handleMoreFeeling}
                      >
                        <img className={styles.profileImg} src={al} alt='AL' />
                        {t('pages.feeling.extra.more', {
                          feeling: getWordTranslated(state, feeling.wordId),
                        })}
                      </Button>{' '}
                      <Button
                        variant='outline-primary'
                        onClick={handleControlFeeling}
                      >
                        <img className={styles.profileImg} src={al} alt='AL' />
                        {t('pages.feeling.extra.control', {
                          feeling: getWordTranslated(state, feeling.wordId),
                        })}
                      </Button>
                    </div>
                  </PreviewContainer>
                </Col>
              </Row>
            </Can>
          )}
          <Row>
            <Col className={styles.separatorStart}>
              <HorizontalRule
                icon={
                  <img
                    className={styles.impactedIcon}
                    src={impactedImage}
                    alt={`Goals`}
                  />
                }
                text={t(`pages.feeling.goal.mainTitle`)}
              />
            </Col>
          </Row>

          <Row className={styles.connectionRow}>
            <Col className={styles.connectionCol}>
              <FeelingGoals
                feeling={feeling}
                values={goals}
                disabled={!feeling?.couldUpdate}
                onChange={(goals) => handleGoalsChanged(goals)}
              />
            </Col>
          </Row>
          <Row>
            <Col className={styles.separatorStart}>
              <HorizontalRule
                icon={<PersonalNoteIcon />}
                text={t('pages.note.personal.title')}
              />
            </Col>
          </Row>

          <Row className={styles.personalNoteRow}>
            <Col className={styles.personalNoteCol}>
              <Notes
                notes={notes}
                onChange={setNotes}
                disabled={!feeling.couldUpdate}
                isPersonal
              />
              {hasNotesChanged && feeling.couldUpdate && (
                <Button
                  // disabled={!wordSelected}
                  variant='primary'
                  onClick={() => handleSaveNotes(notes)}
                >
                  {t('common.action.save')}
                </Button>
              )}
            </Col>
          </Row>
          <Row>
            <Col className={styles.separatorEnd}>
              <HorizontalRule text={''} />
            </Col>
          </Row>
          <Row className={styles.updateFeelingRow}>
            <Col sm={12} className={styles.buttons}>
              <Button variant='secondary' onClick={() => onSubmit()}>
                {t('common.action.close')}
              </Button>
            </Col>
          </Row>
          {feeling.couldUpdate && <FeelingReminder sphere={feeling.sphere} />}
        </Container>
      </div>
      {aiContext && (
        <FeelingAIModal
          show={aiShow}
          mode={aiContext.mode}
          initiationText={aiContext.initiationText}
          contextQuestions={aiContext.contextQuestions}
          feeling={feeling}
          suggestions={aiContext.suggestions}
          disableFreeText={aiContext.disableFreeText}
          onClose={handleChatClosed}
        />
      )}
    </div>
  );
};

export default UpdateFeelingPage;
