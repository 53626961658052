import React, { memo } from "react";

// System Icons
import { ReactComponent as LogoutBoxRLine } from "remixicon/icons/System/logout-box-r-line.svg";
import { ReactComponent as ArrowLeftSLine } from "remixicon/icons/Arrows/arrow-left-s-line.svg";
import { ReactComponent as ArrowRightSLine } from "remixicon/icons/Arrows/arrow-right-s-line.svg";
import { ReactComponent as ArrowRightFill } from "remixicon/icons/Arrows/arrow-right-fill.svg";
import { ReactComponent as ArrowLeftRight } from "remixicon/icons/Arrows/arrow-left-right-line.svg";
import { ReactComponent as ArrowUpDown } from "remixicon/icons/Arrows/arrow-up-down-line.svg";
import { ReactComponent as SubGoal } from "remixicon/icons/Arrows/corner-down-right-line.svg";
import { ReactComponent as SubElement } from "remixicon/icons/Arrows/corner-down-right-fill.svg";
import { ReactComponent as CloseLine } from "remixicon/icons/System/close-line.svg";
import { ReactComponent as DeleteBinLine } from "remixicon/icons/System/delete-bin-line.svg";
import { ReactComponent as HistoryLine } from "remixicon/icons/System/history-line.svg";
import { ReactComponent as Settings3Line } from "remixicon/icons/System/settings-3-line.svg";
import { ReactComponent as ShieldKeyholeLine } from "remixicon/icons/System/shield-keyhole-line.svg";
import { ReactComponent as ShieldKeyholeFill } from "remixicon/icons/System/shield-keyhole-fill.svg";
import { ReactComponent as Forbid2Line } from "remixicon/icons/System/forbid-2-line.svg";
import { ReactComponent as AddLine } from "remixicon/icons/System/add-line.svg";
import { ReactComponent as SubstractLine } from "remixicon/icons/System/subtract-line.svg";
import { ReactComponent as ExternalLinkLine } from "remixicon/icons/System/external-link-line.svg";
import { ReactComponent as Link } from "remixicon/icons/Editor/link.svg";
import { ReactComponent as Translate } from "remixicon/icons/Editor/translate-2.svg";
import { ReactComponent as ShieldUserLine } from "remixicon/icons/System/shield-user-line.svg";
import { ReactComponent as ShieldCheckLine } from "remixicon/icons/System/shield-check-line.svg";
import { ReactComponent as LockPasswordLine } from "remixicon/icons/System/lock-password-line.svg";
import { ReactComponent as ArrowDropDownLine } from "remixicon/icons/Arrows/arrow-drop-down-line.svg";
import { ReactComponent as TagSeparator } from "remixicon/icons/Arrows/arrow-drop-right-line.svg";
import { ReactComponent as TimeLine } from "remixicon/icons/System/time-line.svg";
import { ReactComponent as TimerLine } from "remixicon/icons/System/timer-line.svg";
import { ReactComponent as MenuLine } from "remixicon/icons/System/menu-line.svg";
import { ReactComponent as SearchLine } from "remixicon/icons/System/search-line.svg";
import { ReactComponent as Info } from "remixicon/icons/System/information-fill.svg";
import { ReactComponent as More } from "remixicon/icons/System/more-fill.svg";
import { ReactComponent as Warning } from "remixicon/icons/System/alert-line.svg";

import { ReactComponent as ListSettings } from "remixicon/icons/System/list-settings-line.svg";
import { ReactComponent as ArrowDropRight } from "remixicon/icons/Arrows/arrow-drop-right-line.svg";
import { ReactComponent as SalesUpGroup } from "remixicon/icons/System/checkbox-blank-circle-line.svg";
import { ReactComponent as StepActive } from "remixicon/icons/System/radio-button-line.svg";
import { ReactComponent as NextStep } from "remixicon/icons/System/checkbox-blank-circle-fill.svg";
import { ReactComponent as StepValidated } from "remixicon/icons/System/checkbox-circle-fill.svg";
import { ReactComponent as StepIncomplete } from "remixicon/icons/System/checkbox-blank-circle-line.svg";

// User Icons
import { ReactComponent as AccountCircleFill } from "remixicon/icons/User & Faces/account-circle-fill.svg";
import { ReactComponent as AccountCircleLine } from "remixicon/icons/User & Faces/account-circle-line.svg";
import { ReactComponent as GroupLine } from "remixicon/icons/User & Faces/group-line.svg";
import { ReactComponent as UserAddLine } from "remixicon/icons/User & Faces/user-add-line.svg";
import { ReactComponent as UserUnfollowLine } from "remixicon/icons/User & Faces/user-unfollow-line.svg";
import { ReactComponent as UserSettingsLine } from "remixicon/icons/User & Faces/user-settings-line.svg";
import { ReactComponent as Guest } from "remixicon/icons/User & Faces/user-add-line.svg";
import { ReactComponent as TagGroup } from "remixicon/icons/User & Faces/team-fill.svg";
import { ReactComponent as Member } from "remixicon/icons/User & Faces/user-line.svg";

// Design Icons
import { ReactComponent as PencilLine } from "remixicon/icons/Design/pencil-line.svg";
import { ReactComponent as Edit } from "remixicon/icons/Design/edit-box-line.svg";
import { ReactComponent as Magic } from "remixicon/icons/Design/magic-fill.svg";
// Editor Icons
import { ReactComponent as TagChart } from "remixicon/icons/Editor/organization-chart.svg";
import { ReactComponent as TreeView } from "remixicon/icons/Editor/node-tree.svg";
import { ReactComponent as FlowChart } from "remixicon/icons/Editor/flow-chart.svg";
import { ReactComponent as Ai } from "remixicon/icons/Editor/ai-generate.svg";
import { ReactComponent as Unknown } from "remixicon/icons/System/question-fill.svg";
import { ReactComponent as Help } from "remixicon/icons/System/question-fill.svg";
// Business Icons
import { ReactComponent as MailSendLine } from "remixicon/icons/Business/mail-send-line.svg";
import { ReactComponent as BarChartLine } from "remixicon/icons/Business/bar-chart-line.svg";
import { ReactComponent as BarChartBoxLine } from "remixicon/icons/Business/bar-chart-box-line.svg";
import { ReactComponent as BarChartLine2 } from "remixicon/icons/Business/bar-chart-2-fill.svg";
import { ReactComponent as AtLine } from "remixicon/icons/Business/at-line.svg";
import { ReactComponent as TagMoment } from "remixicon/icons/Business/calendar-event-fill.svg";
import { ReactComponent as TagPeriod } from "remixicon/icons/Business/calendar-2-fill.svg";
import { ReactComponent as RangeDatePicker } from "remixicon/icons/Business/calendar-2-line.svg";
import { ReactComponent as PrivacyUnlocked } from "remixicon/icons/Business/verified-badge-line.svg";
import { ReactComponent as PrivacyLocked } from "remixicon/icons/System/prohibited-line.svg";
import { ReactComponent as SetAsDefault } from "remixicon/icons/Business/global-line.svg";
import { ReactComponent as Email } from "remixicon/icons/Business/mail-send-line.svg";
import { ReactComponent as Scheduler } from "remixicon/icons/Business/calendar-line.svg";

// Buildings Icons
import { ReactComponent as Home4Line } from "remixicon/icons/Buildings/home-4-line.svg";
import { ReactComponent as Home4 } from "remixicon/icons/Buildings/home-4-fill.svg";
// Map Icons
import { ReactComponent as EarthLine } from "remixicon/icons/Map/earth-line.svg";
import { ReactComponent as MapPin2Line } from "remixicon/icons/Map/map-pin-2-line.svg";
import { ReactComponent as HotelBedFill } from "remixicon/icons/Map/hotel-bed-fill.svg";
import { ReactComponent as Campaign } from "remixicon/icons/Map/treasure-map-line.svg";
import { ReactComponent as MapPin } from "remixicon/icons/Map/map-pin-2-fill.svg";
// Media Icons
import { ReactComponent as FullscreenLine } from "remixicon/icons/Media/fullscreen-line.svg";
import { ReactComponent as FullscreenExitLine } from "remixicon/icons/Media/fullscreen-exit-line.svg";
import { ReactComponent as NotificationLine } from "remixicon/icons/Media/notification-line.svg";
import { ReactComponent as NotificationFill } from "remixicon/icons/Media/notification-fill.svg";
import { ReactComponent as Start } from "remixicon/icons/Media/play-fill.svg";
import { ReactComponent as Stop } from "remixicon/icons/Media/stop-fill.svg";
import { ReactComponent as Continue } from "remixicon/icons/Media/play-large-fill.svg";
//finance
import { ReactComponent as Tag } from "remixicon/icons/Finance/price-tag-3-line.svg";
import { ReactComponent as TagFill } from "remixicon/icons/Finance/price-tag-3-fill.svg";
import { ReactComponent as Subscription } from "remixicon/icons/Finance/vip-crown-2-line.svg";
import { ReactComponent as Coupon } from "remixicon/icons/Finance/coupon-3-line.svg";
// Device Icons
import { ReactComponent as ShutDownLine } from "remixicon/icons/Device/shut-down-line.svg";
import { ReactComponent as Scan } from "remixicon/icons/Device/qr-code-line.svg";
import { ReactComponent as RefreshFill } from "remixicon/icons/Device/restart-fill.svg";
import { ReactComponent as RefreshLine } from "remixicon/icons/Device/restart-line.svg";

import { ReactComponent as MentalHealthLine } from "remixicon/icons/Health & Medical/mental-health-line.svg";
import { ReactComponent as MentalHealthFill } from "remixicon/icons/Health & Medical/mental-health-fill.svg";
import { ReactComponent as Heart } from "remixicon/icons/Health & Medical/heart-fill.svg";
import { ReactComponent as FavoriteFill } from "remixicon/icons/System/star-fill.svg";
import { ReactComponent as Favorite } from "remixicon/icons/System/star-line.svg";
import { ReactComponent as Success } from "remixicon/icons/System/check-line.svg";
import { ReactComponent as Error } from "remixicon/icons/System/close-fill.svg";
import { ReactComponent as Filter } from "remixicon/icons/System/filter-fill.svg";
import { ReactComponent as Download } from "remixicon/icons/System/download-2-fill.svg";
import { ReactComponent as Preview } from "remixicon/icons/System/eye-fill.svg";
import { ReactComponent as Visible } from "remixicon/icons/System/eye-fill.svg";
import { ReactComponent as Hidden } from "remixicon/icons/System/eye-off-line.svg";

import { ReactComponent as Privacy } from "remixicon/icons/Document/folder-user-fill.svg";
import { ReactComponent as Copy } from "remixicon/icons/Document/file-copy-2-line.svg";
import { ReactComponent as BlankTemplate } from "remixicon/icons/Document/file-paper-fill.svg";
import { ReactComponent as Report } from "remixicon/icons/Document/book-2-fill.svg";
import { ReactComponent as ReportPart } from "remixicon/icons/Document/newspaper-line.svg";
import { ReactComponent as ChartDocument } from "remixicon/icons/Document/file-chart-line.svg";
import { ReactComponent as DataDocument } from "remixicon/icons/Document/file-excel-line.svg";
import { ReactComponent as TextDocument } from "remixicon/icons/Document/file-text-line.svg";

import { ReactComponent as ChatSend } from "remixicon/icons/Communication/chat-upload-line.svg";
import { ReactComponent as Chat } from "remixicon/icons/Communication/chat-1-line.svg";
import { ReactComponent as Axis } from "remixicon/icons/Communication/questionnaire-line.svg";
import { ReactComponent as Feedback } from "remixicon/icons/Communication/feedback-fill.svg";
import { ReactComponent as ChatPoll } from "remixicon/icons/Communication/chat-poll-line.svg";
import { ReactComponent as Forms } from "remixicon/icons/Document/survey-line.svg";
import { ReactComponent as PersonalNote } from "remixicon/icons/Document/file-lock-fill.svg";
import { ReactComponent as NoteSmall } from "remixicon/icons/System/lock-fill.svg";
import { ReactComponent as PreviewAccess } from "remixicon/icons/System/eye-2-line.svg";
import { ReactComponent as Note } from "remixicon/icons/Document/file-fill.svg";
import { ReactComponent as Excel } from "remixicon/icons/Document/file-excel-2-fill.svg";
import { ReactComponent as Csv } from "remixicon/icons/Editor/table-2.svg";
import { ReactComponent as Compare } from "remixicon/icons/Editor/insert-column-right.svg";
import { ReactComponent as Pdf } from "remixicon/icons/Document/file-pdf-fill.svg";
import { ReactComponent as Duplicate } from "remixicon/icons/Document/file-copy-2-fill.svg";
import { ReactComponent as Print } from "remixicon/icons/Business/printer-fill.svg";

import { ReactComponent as StrategyVision } from "remixicon/icons/Map/compass-3-line.svg";
import { ReactComponent as Strategy } from "remixicon/icons/Design/artboard-fill.svg";
import { ReactComponent as StrategyMission } from "remixicon/icons/Business/service-fill.svg";
import { ReactComponent as StrategyStrategicAxis } from "remixicon/icons/Design/focus-2-line.svg";
import { ReactComponent as Goal } from "remixicon/icons/Design/focus-2-line.svg";
import { ReactComponent as StrategyGoal } from "remixicon/icons/Design/focus-3-line.svg";
import { ReactComponent as StrategyCustom } from "remixicon/icons/Design/focus-line.svg";
import { ReactComponent as StrategyAction } from "remixicon/icons/Media/memories-fill.svg";

import { ReactComponent as IndentIncrease } from "remixicon/icons/Arrows/arrow-right-s-fill.svg";
import { ReactComponent as IndentDecrease } from "remixicon/icons/Arrows/arrow-left-s-fill.svg";
import { ReactComponent as Expand } from "remixicon/icons/Arrows/arrow-right-s-fill.svg";
import { ReactComponent as Collapse } from "remixicon/icons/Arrows/arrow-down-s-fill.svg";

import { ReactComponent as Whatsapp } from "remixicon/icons/Logos/whatsapp-fill.svg";
import { ReactComponent as Share } from "remixicon/icons/System/share-line.svg";

import { ReactComponent as UserConnection } from "remixicon/icons/User & Faces/user-shared-line.svg";
import { ReactComponent as UserConnection2 } from "remixicon/icons/User & Faces/user-3-fill.svg";
import { ReactComponent as UserConnection3 } from "remixicon/icons/User & Faces/user-3-line.svg";
import { ReactComponent as Dashboard } from "remixicon/icons/System/dashboard-fill.svg";
import { ReactComponent as UseConnect } from "remixicon/icons/User & Faces/user-follow-line.svg";

import { ReactComponent as CheckboxCircleEmpty } from "remixicon/icons/System/checkbox-blank-circle-line.svg";
import { ReactComponent as CheckboxCircleValid } from "remixicon/icons/System/checkbox-circle-fill.svg";

import { ReactComponent as RankingIcon } from "remixicon/icons/Finance/trophy-line.svg";
import { ReactComponent as WaitTime } from "remixicon/icons/System/hourglass-fill.svg";
import { ReactComponent as Gift } from "remixicon/icons/Finance/gift-fill.svg";

import { ReactComponent as Infinite } from "remixicon/icons/Others/infinity-line.svg";

import { ReactComponent as DataShotCard } from "remixicon/icons/Business/bar-chart-grouped-line.svg";
import { ReactComponent as TextCard } from "remixicon/icons/Editor/text-block.svg";
import { ReactComponent as TableOfContentCard } from "remixicon/icons/Editor/list-ordered.svg";

import { ReactComponent as CarouselLeft } from "remixicon/icons/Arrows/arrow-drop-left-line.svg";
import { ReactComponent as CarouselRight } from "remixicon/icons/Arrows/arrow-drop-right-line.svg";

import styles from "./RemixIcons.module.scss";

export const RankingIconIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <RankingIcon className={`${styles.root} ${className}`} {...otherProps} />
  )
);

export const CheckboxCircleEmptyIcon: React.FC<React.SVGProps<SVGSVGElement>> =
  memo(({ className = "", ...otherProps }: any) => (
    <CheckboxCircleEmpty
      className={`${styles.root} ${className}`}
      {...otherProps}
    />
  ));
export const CheckboxCircleValidIcon: React.FC<React.SVGProps<SVGSVGElement>> =
  memo(({ className = "", ...otherProps }: any) => (
    <CheckboxCircleValid
      className={`${styles.root} ${className}`}
      {...otherProps}
    />
  ));
export const SubscriptionIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <Subscription className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const CouponIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <Coupon className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const TagIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <Tag className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const TagFillIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <TagFill className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const TagGroupIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <TagGroup
      className={`${styles.root} ${styles.smaller} ${className}`}
      {...otherProps}
    />
  )
);
export const MemberIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <Member
      className={`${styles.root} ${styles.smaller} ${className}`}
      {...otherProps}
    />
  )
);
export const LogoutBoxRLineIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <LogoutBoxRLine className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const ArrowLeftSLineIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <ArrowLeftSLine className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const ArrowRightSLineIcon: React.FC<React.SVGProps<SVGSVGElement>> =
  memo(({ className = "", ...otherProps }: any) => (
    <ArrowRightSLine
      className={`${styles.root} ${className}`}
      {...otherProps}
    />
  ));
export const AccountCircleFillIcon: React.FC<React.SVGProps<SVGSVGElement>> =
  memo(({ className = "", ...otherProps }: any) => (
    <AccountCircleFill
      className={`${styles.root} ${className}`}
      {...otherProps}
    />
  ));
export const ArrowRightFillIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <ArrowRightFill className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const ArrowLeftRightIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <ArrowLeftRight className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const ArrowUpDownIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <ArrowUpDown className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const SubGoalIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <SubGoal className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const SubElementIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <SubElement className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const UserAddLineIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <UserAddLine className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const CloseLineIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <CloseLine className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const UserUnfollowLineIcon: React.FC<React.SVGProps<SVGSVGElement>> =
  memo(({ className = "", ...otherProps }: any) => (
    <UserUnfollowLine
      className={`${styles.root} ${className}`}
      {...otherProps}
    />
  ));
export const PencilLineIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <PencilLine className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const DeleteBinLineIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <DeleteBinLine className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const MailSendLineIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <MailSendLine className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const Home4LineIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <Home4Line className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const Home4Icon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <Home4 className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const BarChartLineIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <BarChartLine className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const BarChartLine2Icon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <BarChartLine2 className={`${styles.root} ${className}`} {...otherProps} />
  )
);

export const BarChartBoxLineIcon: React.FC<React.SVGProps<SVGSVGElement>> =
  memo(({ className = "", ...otherProps }: any) => (
    <BarChartBoxLine
      className={`${styles.root} ${className}`}
      {...otherProps}
    />
  ));
export const HistoryLineIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <HistoryLine className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const EarthLineIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <EarthLine className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const Settings3LineIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <Settings3Line className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const GroupLineIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <GroupLine
      className={`${styles.root} ${styles.smaller} ${className}`}
      {...otherProps}
    />
  )
);
export const ShieldKeyholeLineIcon: React.FC<React.SVGProps<SVGSVGElement>> =
  memo(({ className = "", ...otherProps }: any) => (
    <ShieldKeyholeLine
      className={`${styles.root} ${styles.superAdmin} ${className}`}
      {...otherProps}
    />
  ));
export const ShieldKeyholeFillIcon: React.FC<React.SVGProps<SVGSVGElement>> =
  memo(({ className = "", ...otherProps }: any) => (
    <ShieldKeyholeFill
      className={`${styles.root} ${styles.superAdmin} ${className}`}
      {...otherProps}
    />
  ));
export const AddLineIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <AddLine className={`${styles.root} ${className}`} {...otherProps} />
  )
);

export const SubstractLineIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <SubstractLine className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const Forbid2LineIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <Forbid2Line className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const AnomalyIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <Forbid2Line className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const FullscreenLineIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <FullscreenLine className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const FullscreenExitLineIcon: React.FC<React.SVGProps<SVGSVGElement>> =
  memo(({ className = "", ...otherProps }: any) => (
    <FullscreenExitLine
      className={`${styles.root} ${className}`}
      {...otherProps}
    />
  ));
export const ExternalLinkLineIcon: React.FC<React.SVGProps<SVGSVGElement>> =
  memo(({ className = "", ...otherProps }: any) => (
    <ExternalLinkLine
      className={`${styles.root} ${className}`}
      {...otherProps}
    />
  ));
export const LinkIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <Link className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const TranslateIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <Translate className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const ShieldUserLineIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <ShieldUserLine className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const AtLineIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <AtLine className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const ShutDownLineIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <ShutDownLine className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const ScanIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <Scan className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const UserSettingsLineIcon: React.FC<React.SVGProps<SVGSVGElement>> =
  memo(({ className = "", ...otherProps }: any) => (
    <UserSettingsLine
      className={`${styles.root} ${className}`}
      {...otherProps}
    />
  ));
export const GuestIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <Guest className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const MapPin2LineIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <MapPin2Line className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const MapPinIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <MapPin className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const ShieldCheckLineIcon: React.FC<React.SVGProps<SVGSVGElement>> =
  memo(({ className = "", ...otherProps }: any) => (
    <ShieldCheckLine
      className={`${styles.root} ${className}`}
      {...otherProps}
    />
  ));
export const LockPasswordLineIcon: React.FC<React.SVGProps<SVGSVGElement>> =
  memo(({ className = "", ...otherProps }: any) => (
    <LockPasswordLine
      className={`${styles.root} ${className}`}
      {...otherProps}
    />
  ));
export const ArrowDropDownLineIcon: React.FC<React.SVGProps<SVGSVGElement>> =
  memo(({ className = "", ...otherProps }: any) => (
    <ArrowDropDownLine
      className={`${styles.root} ${className}`}
      {...otherProps}
    />
  ));
export const TimeLineIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <TimeLine className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const TimerLineIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <TimerLine className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const NotificationLineIcon: React.FC<React.SVGProps<SVGSVGElement>> =
  memo(({ className = "", ...otherProps }: any) => (
    <NotificationLine
      className={`${styles.root} ${className}`}
      {...otherProps}
    />
  ));
export const AccountCircleLineIcon: React.FC<React.SVGProps<SVGSVGElement>> =
  memo(({ className = "", ...otherProps }: any) => (
    <AccountCircleLine
      className={`${styles.root} ${className}`}
      {...otherProps}
    />
  ));
export const NotificationFillIcon: React.FC<React.SVGProps<SVGSVGElement>> =
  memo(({ className = "", ...otherProps }: any) => (
    <NotificationFill
      className={`${styles.root} ${className}`}
      {...otherProps}
    />
  ));
export const StartIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <Start className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const StopIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <Stop className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const ContinueIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <Continue className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const MenuLineIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <MenuLine className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const SearchIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <SearchLine className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const InfoIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <Info className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const MoreIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <More className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const WarningIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <Warning
      className={`${styles.root} ${styles.warning} ${className}`}
      {...otherProps}
    />
  )
);
export const HotelBedFillIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <HotelBedFill className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const CampaignIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <Campaign className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const StudyProgramIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <MentalHealthLine
      className={`${styles.root} ${className}`}
      {...otherProps}
    />
  )
);
export const StudyProgramFillIcon: React.FC<React.SVGProps<SVGSVGElement>> =
  memo(({ className = "", ...otherProps }: any) => (
    <MentalHealthFill
      className={`${styles.root} ${className}`}
      {...otherProps}
    />
  ));
export const HeartIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <Heart className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const FavoriteFillIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <FavoriteFill className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const FavoriteIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <Favorite className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const ErrorIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <Error
      className={`${styles.root} ${styles.error} ${className}`}
      {...otherProps}
    />
  )
);
export const SuccessIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <Success
      className={`${styles.root} ${styles.success} ${className}`}
      {...otherProps}
    />
  )
);
export const PrivacyIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <Privacy className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const CopyIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <Copy className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const BlankTemplateIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <BlankTemplate className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const ReportIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <Report className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const ReportPartIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <ReportPart className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const DataDocumentIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <DataDocument className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const TextDocumentIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <TextDocument className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const ChartDocumentIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <ChartDocument className={`${styles.root} ${className}`} {...otherProps} />
  )
);

export const RefreshFillIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <RefreshFill className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const RefreshIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <RefreshLine className={`${styles.root} ${className}`} {...otherProps} />
  )
);

export const ChatPollIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <ChatPoll className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const ChatSendIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <ChatSend className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const AxisIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <Axis className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const ChatIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <Chat className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const FeedbackIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <Feedback className={`${styles.root} ${className}`} {...otherProps} />
  )
);

export const EditIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <Edit className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const MagicIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <Magic className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const TagSeparatorIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <TagSeparator className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const ListSettingsIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <ListSettings className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const TagChartIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <TagChart className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const TagsSelectionIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <FlowChart className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const AiIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <Ai className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const TreeViewIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <TreeView className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const UnknownIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <Unknown className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const HelpIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <Help className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const TagMomentIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <TagMoment className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const TagPeriodIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <TagPeriod className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const RangeDatePickerIcon: React.FC<React.SVGProps<SVGSVGElement>> =
  memo(({ className = "", ...otherProps }: any) => (
    <RangeDatePicker
      className={`${styles.root} ${className}`}
      {...otherProps}
    />
  ));
export const ArrowDropRightIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <ArrowDropRight className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const SalesUpGroupIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <SalesUpGroup
      className={`${styles.root} ${styles.smaller} ${className}`}
      {...otherProps}
    />
  )
);
export const FormsIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <Forms
      className={`${styles.root} ${styles.smaller} ${className}`}
      {...otherProps}
    />
  )
);

export const StepActiveIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <StepActive
      className={`${styles.root} ${styles.smaller} ${className}`}
      {...otherProps}
    />
  )
);
export const NextStepIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <NextStep
      className={`${styles.root} ${styles.smaller} ${className}`}
      {...otherProps}
    />
  )
);
export const StepValidatedIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <StepValidated
      className={`${styles.root} ${styles.smaller} ${className}`}
      {...otherProps}
    />
  )
);
export const StepIncompleteIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <StepIncomplete
      className={`${styles.root} ${styles.smaller} ${className}`}
      {...otherProps}
    />
  )
);

export const StrategyVisionIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <StrategyVision
      className={`${styles.root} ${styles.smaller} ${className}`}
      {...otherProps}
    />
  )
);
export const StrategyIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <Strategy
      className={`${styles.root} ${styles.smaller} ${className}`}
      {...otherProps}
    />
  )
);
export const StrategyMissionIcon: React.FC<React.SVGProps<SVGSVGElement>> =
  memo(({ className = "", ...otherProps }: any) => (
    <StrategyMission
      className={`${styles.root} ${styles.smaller} ${className}`}
      {...otherProps}
    />
  ));
export const StrategyStrategicAxisIcon: React.FC<
  React.SVGProps<SVGSVGElement>
> = memo(({ className = "", ...otherProps }: any) => (
  <StrategyStrategicAxis
    className={`${styles.root} ${styles.smaller} ${className}`}
    {...otherProps}
  />
));
export const StrategyActionIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <StrategyAction
      className={`${styles.root} ${styles.smaller} ${className}`}
      {...otherProps}
    />
  )
);
export const StrategyAssessmentIcon: React.FC<React.SVGProps<SVGSVGElement>> =
  FormsIcon;

export const GoalIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <Goal
      className={`${styles.root} ${styles.smaller} ${className}`}
      {...otherProps}
    />
  )
);
export const StrategyGoalIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <StrategyGoal
      className={`${styles.root} ${styles.smaller} ${className}`}
      {...otherProps}
    />
  )
);
export const StrategyCustomIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <StrategyCustom
      className={`${styles.root} ${styles.smaller} ${className}`}
      {...otherProps}
    />
  )
);

export const IndentIncreaseIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <IndentIncrease
      className={`${styles.root} ${styles.smaller} ${className}`}
      {...otherProps}
    />
  )
);
export const IndentDecreaseIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <IndentDecrease
      className={`${styles.root} ${styles.smaller} ${className}`}
      {...otherProps}
    />
  )
);

export const ExpandIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <Expand
      className={`${styles.root} ${styles.smaller} ${className}`}
      {...otherProps}
    />
  )
);
export const CollapseIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <Collapse
      className={`${styles.root} ${styles.smaller} ${className}`}
      {...otherProps}
    />
  )
);
export const PersonalNoteIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <PersonalNote
      className={`${styles.root} ${styles.secure} ${className}`}
      {...otherProps}
    />
  )
);
export const NoteSmallIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <NoteSmall
      className={`${styles.root} ${styles.secure} ${className}`}
      {...otherProps}
    />
  )
);
export const PreviewAccessIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <PreviewAccess
      className={`${styles.root} ${styles.secure} ${className}`}
      {...otherProps}
    />
  )
);
export const NoteIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <Note className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const ExcelIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <Excel className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const CsvIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <Csv className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const CompareIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <Compare className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const PdfIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <Pdf className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const DuplicateIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <Duplicate className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const PrintIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <Print className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const FilterIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <Filter className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const DownloadIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <Download className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const PreviewIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <Preview className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const VisibleIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <Visible className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const HiddenIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <Hidden className={`${styles.root} ${className}`} {...otherProps} />
  )
);

export const WhatsappIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <Whatsapp className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const ShareIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <Share className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const UserConnectionIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <UserConnection className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const UserConnection2Icon: React.FC<React.SVGProps<SVGSVGElement>> =
  memo(({ className = "", ...otherProps }: any) => (
    <UserConnection2
      className={`${styles.root} ${className}`}
      {...otherProps}
    />
  ));
export const UserConnection3Icon: React.FC<React.SVGProps<SVGSVGElement>> =
  memo(({ className = "", ...otherProps }: any) => (
    <UserConnection3
      className={`${styles.root} ${className}`}
      {...otherProps}
    />
  ));
export const UseConnectIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <UseConnect className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const DashboardIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <Dashboard className={`${styles.root} ${className}`} {...otherProps} />
  )
);

export const PrivacyLockedIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <PrivacyLocked
      className={`${styles.root} ${styles.error} ${className}`}
      {...otherProps}
    />
  )
);
export const PrivacyUnlockedIcon: React.FC<React.SVGProps<SVGSVGElement>> =
  memo(({ className = "", ...otherProps }: any) => (
    <PrivacyUnlocked
      className={`${styles.root} ${styles.unlock} ${className}`}
      {...otherProps}
    />
  ));
export const PrivacyDisabledIcon: React.FC<React.SVGProps<SVGSVGElement>> =
  memo(({ className = "", ...otherProps }: any) => (
    <PrivacyUnlocked
      className={`${styles.root} ${styles.disabled} ${className}`}
      {...otherProps}
    />
  ));
export const SetAsDefaultIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <SetAsDefault
      className={`${styles.root} ${styles.disabled} ${className}`}
      {...otherProps}
    />
  )
);
export const EmailIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <Email
      className={`${styles.root} ${styles.disabled} ${className}`}
      {...otherProps}
    />
  )
);
export const SchedulerIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <Scheduler className={`${styles.root}  ${className}`} {...otherProps} />
  )
);
export const SetAsPrimaryIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <FavoriteFill className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const WaitTimeIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <WaitTime className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const GiftIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <Gift className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const InfiniteIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <Infinite className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const DataShotCardIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <DataShotCard className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const TextCardIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <TextCard className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const CarouselLeftIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <CarouselLeft className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const CarouselRightIcon: React.FC<React.SVGProps<SVGSVGElement>> = memo(
  ({ className = "", ...otherProps }: any) => (
    <CarouselRight className={`${styles.root} ${className}`} {...otherProps} />
  )
);
export const TableOfContentCardIcon: React.FC<React.SVGProps<SVGSVGElement>> =
  memo(({ className = "", ...otherProps }: any) => (
    <TableOfContentCard
      className={`${styles.root} ${className}`}
      {...otherProps}
    />
  ));
