import React, {
  ReactElement,
  Suspense,
  useEffect,
  useState,
  useRef,
} from "react";
import stylesProfile from "./FormComponent.module.scss";
import stylesModal from "../../components/ModalWrapper/ModalSlideShowWrapper.module.scss";
import { AnswerDefinition, Form, Step } from "../../@types/form-api";
import { AnimatePresence, motion } from "framer-motion";
import {
  CloseLineIcon,
  InfoIcon,
  NextStepIcon,
  PrintIcon,
  StepActiveIcon,
  StepValidatedIcon,
} from "../RemixIcons";
import StepsComponent from "./StepsComponent";
import { ToastOnClose } from "../ModalWrapper/ModalSlideShowWrapper";
import RoundedButton from "../RoundedButton/RoundedButton";
import { getCurrentLanguage } from "../../i18n";
import { evaluateConditions } from "../../tools/formTools";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Dropdown, DropdownButton } from "react-bootstrap";

import { useReactToPrint } from "react-to-print";
import { confirmWrapper } from "../../tools/confirm";
import MarkdownComponent from "../Markdown/MarkdownComponent";
import { getRelevantTranslationFor } from "../../tools/multiLingualTools";

export const printStyle = `
@media all {
  .page-break {
    display: inline-block;
    page-break-after: auto;
    break-after: auto;
    float:none;
  }
}

@media print {
  html, body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }

  .formAnswer {
    text-align: left;
    width: 100%;
  }

  .MuiPaper-root{
    max-width: 100% !important;
    width: 100% !important;
    break-inside: avoid;
  }

  .MuiCardHeader-root {
    background-color: white;
  }
  .personal-note-text {
    max-width: 730px;
    padding-left: 58px;
  }

  .MuiTypography-root {
    max-width: 750px;
  }

  .timeline-legend svg {
    margin-left: 50px;
  }

  textarea.AutoHeightTextarea_textarea__3lrMQ {
    color: #222222 !important;
  }
  * {
    color: #222222 !important;
  }
  
}


@page {
  size: auto;
  margin: 20mm;
 
}`;

const styles = { ...stylesModal, ...stylesProfile };

const variantsModal = {
  hidden: {
    opacity: 0,
    top: "-100vh",
  },
  visible: {
    opacity: 1,
    top: 0,
    transition: {
      type: "tween",
      ease: "easeOut",
      duration: 0.6,
    },
  },
  exit: {
    opacity: 0,
    top: "-100vh",
    transition: {
      type: "tween",
      ease: "easeIn",
      duration: 0.6,
    },
  },
};

const variantsFirstPage = {
  hidden: {
    x: 0,
  },
  visible: {
    x: 0,
  },
  exit: {
    x: "-100vw",
    transition: {
      type: "tween",
      duration: 0.8,
    },
  },
};

const variantsPreviousPage = {
  hidden: {
    x: "-100vw",
  },
  visible: {
    x: 0,
    transition: {
      type: "tween",
      duration: 0.8,
    },
  },
  exit: {
    x: "-100vw",
    transition: {
      type: "tween",
      duration: 0.8,
    },
  },
};

const variantsOtherPages = {
  hidden: {
    x: "100vw",
  },
  visible: {
    x: 0,
    transition: {
      type: "tween",
      duration: 0.8,
    },
  },
  exit: {
    x: "-100vw",
    transition: {
      type: "tween",
      duration: 0.8,
    },
  },
};

const variantsLastPage = {
  hidden: {
    x: "100vw",
  },
  visible: {
    x: 0,
    transition: {
      type: "tween",
      duration: 0.8,
    },
  },
  exit: {
    x: 0,
  },
};

interface FormComponentProps {
  form: Form;
  defaultValue?: Record<string, any>;
  disabled?: boolean;
  saving?: boolean;
  printable?: boolean;
  onSave: (values: Record<string, any>) => void;
  onClose: () => void;
}

type State = {
  currentPageIdx: number;
  pageElements: ReactElement[];
  variants?: any;
  onCloseNotifications: ToastOnClose[];
};

type StepElement = {
  steps: Step[];
  element: JSX.Element;
};

const FormComponent: React.FC<FormComponentProps> = ({
  form,
  defaultValue,
  disabled,
  saving,
  onSave,
  onClose,
  printable,
}) => {
  const { t } = useTranslation("i18n");
  const [steps, setSteps] = useState<StepElement[]>([]);
  const [values, setValues] = useState<Record<string, any>>(defaultValue || {});
  const [infoDisplayed, setInfodisplay] = useState<boolean>(false);

  const [state, setState] = useState<State>({
    currentPageIdx: 0,
    pageElements: [],
    variants: variantsFirstPage,
    onCloseNotifications: [],
  });

  const getAllAnswerFrom = (form: Form) => {
    let anwers = form.steps.reduce(
      (acc, step) => [...acc, ...step.answers],
      [] as AnswerDefinition[]
    );

    return anwers;
  };

  useEffect(() => {
    let steps: StepElement[] = [];
    if (form) {
      const stepGroup = form.steps.reduce((acc, step) => {
        if (acc.length && step.sameTabAsPreviousOne) {
          acc[acc.length - 1] = [...acc[acc.length - 1], step];
          return acc;
        }
        return [...acc, [step]];
      }, [] as Step[][]);

      steps = stepGroup.map((stepGroup, index) => {
        return {
          element: <StepsComponent key={`STEPS-${index}`} steps={stepGroup} />,
          steps: stepGroup,
        };
      }, [] as JSX.Element[]);

      setValues(
        getAllAnswerFrom(form).reduce((acc, answer) => {
          return { ...acc, [answer.id]: acc[answer.id] };
        }, values)
      );
    }
    setSteps(steps);
    setState({ ...state });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  useEffect(() => {
    const pageElements = steps
      .filter((s) =>
        s.steps.some((s) => evaluateConditions(s.displayConditions, values))
      )
      .map((s) => s.element);
    setState({ ...state, pageElements: pageElements });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [steps, values]);

  useEffect(() => {
    const currentLang = getCurrentLanguage();
    if (
      !infoDisplayed &&
      !!form.availableLanguages &&
      !!form.availableLanguages.length &&
      !form.availableLanguages.find((lang) => lang === currentLang)
    ) {
      toast.info(t(`pages.forms.langNotAvailable`), {
        autoClose: 20000,
      });
      setInfodisplay(true);
    }
  }, [form, t, infoDisplayed]);

  const handleSave = async (state: State) => {
    onSave(values);
  };

  const gotoNextPage = async (newState: State) => {
    const currentStep = state.currentPageIdx + 1;
    if (currentStep >= state.pageElements.length) {
      handleSave({
        ...state,
        ...newState,
      });
      return;
    }
    const variants =
      currentStep < state.pageElements.length - 1
        ? variantsOtherPages
        : variantsLastPage;

    setState({
      ...state,
      ...newState,
      currentPageIdx: currentStep,
      variants: variants,
    });
  };

  const gotoPreviousPage = async (newState: State) => {
    const currentStep = state.currentPageIdx - 1;
    if (currentStep < 0) {
      return;
    }
    const variants =
      currentStep === 0
        ? variantsPreviousPage
        : currentStep < state.pageElements.length - 1
        ? variantsOtherPages
        : variantsLastPage;

    setState({
      ...state,
      ...newState,
      currentPageIdx: currentStep,
      variants: variants,
    });
  };

  const onChange = async (id: string, value: any) => {
    setValues({ ...values, [id]: value });
  };

  const [printing, setPrinting] = useState(false);
  const componentToPrint = useRef(null);

  const print = useReactToPrint({
    content: () => componentToPrint.current,
    pageStyle: `${printStyle} `,
    onAfterPrint: () => {
      setPrinting(false);
    },
    documentTitle: getRelevantTranslationFor(form.title),
  });

  useEffect(() => {
    if (printing && componentToPrint) {
      setTimeout(() => {
        print();
      }, 100);
    }
  }, [print, printing, componentToPrint]);

  const handlePrint = () => {
    setPrinting(true);
  };

  const handleDetail = async () => {
    await confirmWrapper(
      <MarkdownComponent text={getRelevantTranslationFor(form.description)} />,
      {
        title: getRelevantTranslationFor(form.title),
        hideSecondaryButton: true,
        btnPrimaryLabel: t("common.action.close"),
      }
    );
  };

  const handleClick = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <div className={styles.page}>
      <AnimatePresence exitBeforeEnter>
        <motion.div
          className={styles.root}
          variants={variantsModal}
          initial='hidden'
          animate='visible'
          exit='exit'
          key={`form-survey-${form.id}`}
        >
          <div className={styles.header}>
            <div className={styles.innerHeader}>
              <h3>{getRelevantTranslationFor(form.title)} </h3>
              <RoundedButton className={styles.close} onClick={onClose}>
                <CloseLineIcon />
              </RoundedButton>
              {(form.description || printable) && (
                <div className={styles.actions}>
                  <DropdownButton
                    variant='outline'
                    title={""}
                    id='copyOptions'
                    as={RoundedButton}
                    onClick={handleClick}
                  >
                    {form.description && (
                      <Dropdown.Item onClick={handleDetail}>
                        <InfoIcon /> {t("common.action.detailled")}
                      </Dropdown.Item>
                    )}
                    {printable && (
                      <Dropdown.Item onClick={handlePrint}>
                        <PrintIcon /> {t("common.action.print")}
                      </Dropdown.Item>
                    )}
                  </DropdownButton>
                </div>
              )}
              {form.description && (
                <span>
                  <MarkdownComponent
                    className={styles.description}
                    text={getRelevantTranslationFor(form.description)}
                  />
                </span>
              )}
            </div>
            <div className={styles.breadcrumb}>
              {state.pageElements.map((step, index) =>
                index < state.currentPageIdx ? (
                  <StepValidatedIcon className={styles.inactiveStep} />
                ) : index === state.currentPageIdx ? (
                  <StepActiveIcon />
                ) : (
                  <NextStepIcon className={styles.inactiveStep} />
                )
              )}
            </div>
          </div>

          <Suspense fallback={<div></div>}>
            <AnimatePresence>
              {state.pageElements.length &&
                React.cloneElement(state.pageElements[state.currentPageIdx], {
                  key: `${state.pageElements[state.currentPageIdx].key}`,
                  ...state,
                  values,
                  disabled,
                  saving,
                  next: gotoNextPage,
                  previous: gotoPreviousPage,
                  onChange,
                  onClose,
                })}
            </AnimatePresence>
          </Suspense>
        </motion.div>
      </AnimatePresence>
      {printing && (
        <div className={styles.printPage}>
          <div ref={componentToPrint}>
            <div className={styles.header}>
              <div className={styles.sharingLogoContainer}>
                <img
                  className={styles.sharingLogo}
                  src='/logo.png'
                  alt='logo'
                />
              </div>
              <div className={styles.sharingText}>
                Powered by <b>Seen-apps</b>
              </div>
            </div>
            <h3>{form.title}</h3>
            {form.description && (
              <span>
                <MarkdownComponent
                  className={styles.description}
                  text={getRelevantTranslationFor(form.description)}
                />
              </span>
            )}
            {
              <StepsComponent
                disabled
                steps={form.steps}
                values={values}
                printMode
              />
            }
          </div>
        </div>
      )}
    </div>
  );
};

export default FormComponent;
