import React, { useContext, useEffect, useState } from "react";
import stylesTag from "./AddOrUpdateTagsSelectionPage.module.scss";
import stylesModal from "../../../components/ModalWrapper/ModalSlideShowWrapper.module.scss";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import GlobalStateContext from "../../../context/globalState/GlobalStateContext";
import {
  SelectionCondition,
  SphereTagSelectionResponse,
} from "../../../@types/sphere-api";
import PageTitle from "../../../components/PageTitle/PageTitle";
import { extractLocationData } from "../../../tools/routeTools";
import { toast } from "react-toastify";
import api from "../../../api/Api";
import { getErrorTranslation } from "../../../tools/errorTools";
import ReactLoading from "react-loading";
import TagsInput from "../../../components/input/TagsInput";
import OperatorInput from "../../../components/input/OperatorInput";
import { Button, Form } from "react-bootstrap";
import GenderInput from "../../../components/input/GenderInput";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
  ResponderProvided,
} from "react-beautiful-dnd";

import { v4 as uuidv4 } from "uuid";
import { captureException } from "@sentry/react";
import { Icon } from "@material-ui/core";
import { getRelevantTranslationFor } from "../../../tools/multiLingualTools";
import { ADD_OR_UPDATE_SPHERE_TAGS_SELECTION } from "../../../context/globalState/globalStateReducer";

export const AVAILABLE_TAGS_SELECTIONS = [
  "user_age",
  "user_gender",
  "member_seniority",
];

const STRING_OPERATORS = ["=", "!=", "~", "!~"];
const NUMBER_OPERATORS = ["=", "!=", "<", "<=", ">", ">="];
type Definition = {
  type: "string" | "number";
  values?: any[];
  opertors: string[];
  getLabel: (value: any, t: any) => string;
  tagType?: string;
};
const DEFINITION: Record<string, Definition> = {
  user_age: {
    type: "number",
    getLabel: (value) => value,
    opertors: NUMBER_OPERATORS,
    tagType: "age",
  },
  user_gender: {
    type: "string",
    values: ["male", "female", "other"],
    getLabel: (value, t) => {
      return t(`pages.user.genderValues.${value}`);
    },
    opertors: STRING_OPERATORS,
    tagType: "gender",
  },
  member_seniority: {
    type: "number",
    getLabel: (value) => value,
    opertors: NUMBER_OPERATORS,
    tagType: "seniority",
  },
};

const styles = { ...stylesModal, ...stylesTag };

type Props = {
  onSubmit?: (state?: any) => void;
};

const ConditionInput = ({
  field,
  condition,
  index,
  handleChange,
  handleDelete,
  sphereId,
  definition,
}: {
  condition: Condition;
  field: string;
  sphereId: string;
  index: number;
  definition?: Definition;
  handleChange: (condition: Condition) => void;
  handleDelete: () => void;
}) => {
  const { t } = useTranslation("i18n");
  return (
    <div className={styles.conditionLine}>
      <div className={styles.conditionAction}>
        <Button size='sm' variant='secondary' onClick={handleDelete}>
          x
        </Button>
      </div>
      <div className={styles.condition}>
        {t(
          `pages.sphere.tags-selections.section.form.labels.${
            index === 0 ? "if" : "elseIf"
          }`
        )}
      </div>
      <div className={styles.field}>
        {t(`pages.sphere.tags-selections.section.form.values.field.${field}`)}
      </div>
      <div className={styles.operatorValue}>
        <div className={styles.operator}>
          <OperatorInput
            onChange={(operator: any) => {
              handleChange({ ...condition, operator });
            }}
            operators={definition?.opertors || STRING_OPERATORS}
            value={condition.operator}
          />
        </div>
        <div className={styles.value}>
          {field === "user_gender" ? (
            <GenderInput
              onChange={(gender) =>
                handleChange({ ...condition, value: gender })
              }
              value={condition.value}
            />
          ) : (
            <Form.Control
              type='number'
              value={condition.value}
              onChange={(event) =>
                handleChange({ ...condition, value: event.target.value })
              }
            />
          )}
        </div>
      </div>
      <div className={styles.then}>
        {t(`pages.sphere.tags-selections.section.form.labels.then`)}
      </div>
      <div className={styles.tags}>
        <TagsInput
          sphereId={sphereId}
          onChange={(tags) => handleChange({ ...condition, tags })}
          tagsSelected={condition.tags}
          defaultValueTagWhenCreation={{
            type: definition?.tagType,
            modules: ["member"],
          }}
          module='member'
        />
      </div>
    </div>
  );
};

type Condition = Omit<SelectionCondition, "tagIds"> & {
  id: string;
  tags: { value: string; label: string }[];
};

const AddOrUpdateTagsSelectionPage: React.FC<Props> = ({
  onSubmit = () => {},
}) => {
  const { dispatch } = useContext(GlobalStateContext);
  const { t } = useTranslation("i18n");
  const location = useLocation();
  const [field, setField] = useState<string>("");
  const [sphereId, setSphereId] = useState<string>("");
  const [tagsSelection, setTagsSelection] =
    useState<SphereTagSelectionResponse>();
  const [conditions, setConditions] = useState<Condition[]>([]);
  const [defaultTags, setDefaultTags] = useState<
    { value: string; label: string }[]
  >([]);
  const [definition, setDefinition] = useState<Definition>();
  const [loading, setLoading] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);

  useEffect(() => {
    const getSelection = async (selectionId: string, sphereId: string) => {
      setLoading(true);
      try {
        const selection = await api.getSphereTagsSelection({
          sphereId,
          selectionId: selectionId,
        });
        setTagsSelection(selection);
        setConditions(
          selection?.selections.map((s) => ({
            ...s,
            id: uuidv4(),
            tags: s.tags.map((tag) => ({
              value: tag.id,
              label: getRelevantTranslationFor(tag.name),
            })),
          })) ?? []
        );
        setDefaultTags(
          selection.defaultTags.map((tag) => ({
            value: tag.id,
            label: getRelevantTranslationFor(tag.name),
          }))
        );
      } catch (error) {
        toast.error(getErrorTranslation({ error, t }));
        onSubmit();
      }
      setLoading(false);
    };

    const data = extractLocationData(location);
    const sphereId = data.params.sphereId;

    let selectionId = new URLSearchParams(location.search).get("selectionId");
    if (selectionId) {
      setSphereId(sphereId);
      setField(selectionId);
      getSelection(selectionId, sphereId);
      setDefinition(DEFINITION[selectionId]);
    } else {
      toast.error(t("common.not-found.tagsSelection"));
      onSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);

  const handleCreateCondition = async () => {
    setConditions([
      ...conditions,
      {
        operator: "=",
        value: "",
        tags: [],
        id: uuidv4(),
      },
    ]);
  };

  const handleDragEnd = (result: DropResult, provided: ResponderProvided) => {
    if (result.destination) {
      try {
        const copy = [...conditions];
        copy.splice(
          result.destination.index,
          0,
          copy.splice(result.source.index, 1)[0]
        );
        setConditions(copy);
      } catch (error) {
        captureException(error);
        console.log(`Error during dropîng element`, error);
      }
    }
  };

  const handleSave = async () => {
    try {
      const selection = await api.addOrUpdateSphereTagsSelection({
        sphereId,
        selectionId: field,
        selection: {
          selections: conditions.map((c) => ({
            operator: c.operator,
            value: c.value,
            tagIds: c.tags.map((t) => t.value),
          })),
          defaultTagId: defaultTags.map((t) => t.value),
          field,
        },
      });
      dispatch({
        type: ADD_OR_UPDATE_SPHERE_TAGS_SELECTION,
        payload: [selection],
      });
      toast.success(
        t(`pages.sphere.tags-selections.section.addOrUpdate.succeed`)
      );
      onSubmit();
    } catch (error: any) {
      captureException(error);
      toast.error(
        getErrorTranslation({
          t,
          error,
          defaultKey: `pages.sphere.tags-selections.section.addOrUpdate.error`,
        })
      );
    }
    setSaving(false);
  };

  return (
    <div className={styles.page}>
      <PageTitle
        title={t(`pages.sphere.tags-selections.section.addOrUpdate.title`, {
          field: t(
            `pages.sphere.tags-selections.section.form.values.field.${field}`
          ),
        })}
      />
      <div className={`${styles.content}`}>
        {loading || !tagsSelection ? (
          <div className={styles.center}>
            <ReactLoading
              type={"bars"}
              color={"#DDD"}
              height={"60px"}
              width={"80px"}
            />
            <div className={styles.loading}>{t("common.loading")}</div>
          </div>
        ) : (
          <div className={styles.center}>
            <div className={styles.conditions}>
              <div className={styles.actions}>
                <Button onClick={handleCreateCondition}>
                  {t(
                    `pages.sphere.tags-selections.section.form.labels.addCondition`
                  )}
                </Button>
              </div>
              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId='droppable'>
                  {(provided, snapshot) => (
                    <div ref={provided.innerRef} className={styles.conditions}>
                      {conditions.map((condition, index) => (
                        <Draggable
                          key={`condition-${condition.id}`}
                          draggableId={condition.id}
                          index={index}
                        >
                          {
                            // tslint:disable-next-line:no-shadowed-variable
                            (provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className={styles.card}
                              >
                                <Icon className={styles.icon}>
                                  drag_indicator
                                </Icon>
                                <ConditionInput
                                  condition={condition}
                                  definition={definition}
                                  field={field}
                                  handleChange={(condition) => {
                                    const newConditions = [...conditions];
                                    newConditions[index] = condition;
                                    setConditions([...newConditions]);
                                  }}
                                  handleDelete={() => {
                                    const newConditions = [...conditions];
                                    newConditions.splice(index, 1);
                                    setConditions(newConditions);
                                  }}
                                  index={index}
                                  sphereId={sphereId}
                                />

                                {(provided as any).placeholder}
                              </div>
                            )
                          }
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>

              <div
                className={`${styles.conditionLine} ${styles.lastCondition}`}
              >
                <div className={`${styles.condition}`}>
                  {t(
                    `pages.sphere.tags-selections.section.form.labels.${
                      conditions.length === 0 ? "all" : "others"
                    }`
                  )}
                </div>
                <div className={styles.tags}>
                  <TagsInput
                    sphereId={sphereId}
                    onChange={setDefaultTags}
                    tagsSelected={defaultTags}
                    defaultValueTagWhenCreation={{
                      type: definition?.tagType,
                      modules: ["member"],
                    }}
                    module='member'
                  />
                </div>
              </div>
            </div>
            <div className={styles.buttons}>
              <Button
                variant='secondary'
                className={styles.right}
                onClick={() => onSubmit()}
              >
                {t("common.action.close")}
              </Button>
              <Button
                disabled={loading || saving}
                variant='primary'
                className={styles.left}
                onClick={handleSave}
              >
                {saving && (
                  <div className={styles.submitContent}>
                    <ReactLoading
                      type={"bars"}
                      color={"#DDD"}
                      height={"30px"}
                      width={"40px"}
                    />
                    <div className={styles.submitText}>
                      {t("common.action.saving")}
                    </div>
                  </div>
                )}
                {!saving && t("common.action.save")}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddOrUpdateTagsSelectionPage;
