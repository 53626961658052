import React, { useState } from "react";
import styles from "./TextAnswerComponent.module.scss";
import { FreeTextMeasureType } from "../../../@types/form-api";
import AutoHeightTextarea from "../../input/AutoHeightTextarea";
import { getRelevantTranslationFor } from "../../../tools/multiLingualTools";

interface AnswerComponentProps {
  id: string;
  measureType: FreeTextMeasureType;
  value?: string;
  onChange?: (value: any) => void;
  disabled?: boolean;
  printMode?: boolean;
  required: boolean;
}

const TextAnswerComponent: React.FC<AnswerComponentProps> = ({
  measureType,
  id,
  value,
  onChange,
  disabled,
  printMode,
  required,
}) => {
  const [initialized, setInitialized] = useState(false);

  const handleValueChange = (text: string) => {
    if (onChange) {
      onChange(text || undefined);
    }
    setInitialized(true);
  };

  return (
    <div className={styles.text}>
      <AutoHeightTextarea
        isInvalid={required && initialized && (!value || !value.length)}
        className={`${styles.description} ${disabled ? styles.disabled : ""}`}
        key={`freeText-${id}`}
        rows={measureType.textOptions.multiline ? 3 : 1}
        placeholder={
          !printMode && measureType.textOptions.placeholder
            ? getRelevantTranslationFor(measureType.textOptions.placeholder)
            : ""
        }
        value={value || ""}
        maxLength={measureType.textOptions.max || 2000}
        onChange={(event: any) => handleValueChange(event.target.value)}
        disabled={disabled}
        singleLine={!measureType.textOptions.multiline}
      />
    </div>
  );
};

export default TextAnswerComponent;
