import React, { useContext, useEffect, useState } from "react";
import stylesTag from "./AddOrUpdateTagPage.module.scss";
import selectStyles from "../../../styles/react-select.module.scss";
import stylesModal from "../../../components/ModalWrapper/ModalSlideShowWrapper.module.scss";
import { useTranslation } from "react-i18next";
import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";
import ReactLoading from "react-loading";

import { toast } from "react-toastify";
import { MultiLingual, Tag } from "../../../@types/sphere-api";
import api from "../../../api/Api";
import { confirmWrapper } from "../../../tools/confirm";
import MultiLingualInput from "../../../components/MulitLingual/MultiLingualInput";
import { clearEmptyLanguage } from "../../../tools/multiLingualTools";
import { subject } from "@casl/ability";
import { getErrorTranslation } from "../../../tools/errorTools";
import AbilityContext from "../../../context/AbilityContext";
import { captureException } from "@sentry/minimal";
import IdentifierInput from "../../../components/input/IdentifierInput";
import Select from "react-select";
import AdvancedForm from "../../../components/forms/AdvancedForm";
import ModuleIcon, { MODULES } from "./ModuleIcon";
import TagTypeInput from "../../../components/input/TagTypeInput";
import ColorInput from "../../../components/input/ColorInput";

const styles = { ...stylesModal, ...stylesTag };

type Props = {
  tagId?: string;
  sphereId: string;
  defaultValue?: Partial<Tag>;
  onClose: () => void;
  onSaved: (tag: Tag) => void;
  onArchived?: (tag: Tag) => void;
};

const AddOrUpdateTagForm: React.FC<Props> = ({
  tagId,
  sphereId,
  defaultValue,
  onClose,
  onArchived,
  onSaved,
}) => {
  const { t } = useTranslation("i18n");
  const [shortId, setShortId] = useState<string>("");
  const [name, setName] = useState<MultiLingual>({});
  const [archived, setArchived] = useState<boolean>(false);
  const [color, setColor] = useState<string>();
  const [type, setType] = useState<string>();
  const [modules, setModules] = useState<{ value: string; label: string }[]>(
    []
  );
  const [saving, setSaving] = useState<boolean>(false);
  const [archiving, setArchiving] = useState<boolean>(false);
  const [couldUpdateTag, setCouldUpdateTag] = useState<boolean>(false);
  const [moduleOptions] = useState(
    MODULES.map((module) => ({
      label: (
        <div className={styles.moduleOption}>
          <ModuleIcon module={module} />
          {t(`pages.sphere.tags.modules.${module}`)}
        </div>
      ) as any,
      value: module,
    }))
  );
  const [loading, setLoading] = useState<boolean>(false);
  const ability = useContext(AbilityContext);

  const isModulesInAdvancedForm = !!defaultValue;

  useEffect(() => {
    setName(defaultValue?.name || {});
    setShortId(defaultValue?.shortId || "");
    setColor(defaultValue?.color || "");
    setType(defaultValue?.type || "");
    setShortId(defaultValue?.shortId || "");
    setModules(
      defaultValue?.modules?.map((module) => ({
        label: t(`pages.sphere.tags.modules.${module}`),
        value: module,
      })) || []
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  useEffect(() => {
    const fetchTag = async () => {
      setLoading(true);
      if (tagId) {
        try {
          const response = await api.getSphereTag(sphereId, tagId);
          setArchived(response?.archived);
          setShortId(response?.shortId);
          setType(response?.type);
          setColor(response?.color);
          setName(response?.name || {});
          setModules(
            moduleOptions
              .sort(
                (a, b) => MODULES.indexOf(a.value) - MODULES.indexOf(b.value)
              )
              .filter((m) =>
                response?.modules.find((module) => m.value === module)
              )
          );
        } catch (err: any) {
          captureException(err);
          if (err?.response?.status === 404) {
            toast.error(t("common.not-found.tag"));
          } else {
            toast.error(t("common.notAllowed"));
          }
          onClose();
          return;
        }
      }
      setLoading(false);
    };
    fetchTag();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagId]);
  useEffect(() => {
    setCouldUpdateTag(ability.can("post", subject("sphere-tag", { sphereId })));
  }, [ability, sphereId]);

  const handleClose = () => {
    onClose();
  };

  const handleNameChanged = (value: MultiLingual) => {
    setName(value);
  };

  const handleModulesChanged = (modules: any) => {
    setModules(modules);
  };

  const handleSaveTag = async (event: any) => {
    event.preventDefault();
    event.stopPropagation();

    try {
      if (!name?.default) {
        return;
      }
      setSaving(true);
      let tagResponse: Tag;

      const tagBody = {
        name: clearEmptyLanguage(name),
        archived,
        modules: modules.map((m) => m.value),
        color: color || undefined,
        type: type || undefined,
      };

      if (tagId) {
        tagResponse = await api.updateSphereTag(sphereId, tagId, {
          ...tagBody,
          archived,
        });
        toast.success(t(`pages.sphere.tags.section.update.succeed`));
      } else {
        tagResponse = await api.addSphereTag(sphereId!, tagBody);
        toast.success(t(`pages.sphere.tags.section.add.succeed`));
      }
      onSaved(tagResponse);
    } catch (error) {
      captureException(error);

      toast.error(
        getErrorTranslation({
          t,
          error,
          defaultKey: `pages.sphere.tags.section.${
            !tagId ? "add" : "update"
          }.error`,
        })
      );
    }
    setSaving(false);
  };

  const handleArchive = async (operation: "archive" | "restore") => {
    if (!tagId) {
      return;
    }
    try {
      if (
        await confirmWrapper(
          t(`pages.sphere.tags.section.${operation}.confirm`),
          {
            title: t(`pages.sphere.tags.section.${operation}.title`),
            btnPrimaryLabel: t(
              `pages.sphere.tags.section.${operation}.confirmAction`
            ),
          }
        )
      ) {
        setArchiving(true);
        const tagResponse = await api.updateSphereTag(sphereId, tagId, {
          name: clearEmptyLanguage(name),
          archived: operation === "archive",
        });
        toast.success(t(`pages.sphere.tags.section.${operation}.succeed`));
        if (onArchived) {
          onArchived(tagResponse);
        }
      }
    } catch (error) {
      captureException(error);
      toast.error(
        getErrorTranslation({
          t,
          error,
          defaultKey: `pages.sphere.tags.section.${operation}.error`,
        })
      );
    }
    setArchiving(false);
  };

  return (
    <div className={`${styles.content}`}>
      {loading ? (
        <div className={styles.center}>
          <ReactLoading
            type={"bars"}
            color={"#DDD"}
            height={"60px"}
            width={"80px"}
          />
          <div className={styles.loading}>{t("common.loading")}</div>
        </div>
      ) : (
        <Form noValidate onSubmit={handleSaveTag}>
          <Container className={styles.root}>
            {archived && (
              <Row key={`label`}>
                <Col sm={6}>
                  {/* 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'dark' | 'light' */}
                  <Alert variant='warning'>
                    {t("pages.sphere.tags.section.add.archived")}
                  </Alert>
                </Col>
              </Row>
            )}
            {shortId && (
              <Row key={`shortId`}>
                <Col sm={6}>
                  <IdentifierInput value={shortId} />
                </Col>
              </Row>
            )}
            <Row key={`label`}>
              <Col sm={12}>
                <MultiLingualInput
                  onChange={handleNameChanged}
                  value={name}
                  label={t("pages.sphere.tags.section.form.labels.name")}
                  required
                  disabled={saving || loading}
                  maxLength={50}
                />
              </Col>
            </Row>
            <Row key={`type`} className={styles.noMargin}>
              <Col sm={6} className={styles.noMargin}>
                <Form.Group controlId='type'>
                  <Form.Label>
                    {t("pages.sphere.tags.section.form.labels.type")}
                  </Form.Label>
                  <TagTypeInput value={type} onChange={setType} />
                </Form.Group>
              </Col>
            </Row>
            <Row key={`color`} className={styles.noMargin}>
              <Col sm={6} className={styles.noMargin}>
                <Form.Group controlId='type'>
                  <Form.Label>
                    {t("pages.sphere.tags.section.form.labels.color")}
                  </Form.Label>
                  <ColorInput value={color} onChange={setColor} />
                </Form.Group>
              </Col>
            </Row>

            {!isModulesInAdvancedForm && (
              <Row key={`modules`} className={styles.noMargin}>
                <Col sm={6} className={styles.noMargin}>
                  <Form.Group controlId='modules'>
                    <Form.Label>
                      {t("pages.sphere.tags.section.form.labels.modules")}
                    </Form.Label>
                    <Select
                      key={`modules`}
                      name='modules'
                      options={moduleOptions}
                      isSearchable={false}
                      isClearable
                      isMulti
                      className={selectStyles.reactSelect}
                      classNamePrefix='custom-react-select'
                      placeholder={""}
                      onChange={handleModulesChanged}
                      value={modules}
                    />
                  </Form.Group>
                </Col>
              </Row>
            )}

            {isModulesInAdvancedForm && (
              <AdvancedForm defaultStatus='closed'>
                <Container className={styles.noMargin}>
                  <Row key={`modules`} className={styles.noMargin}>
                    <Col sm={6} className={styles.noMargin}>
                      <Form.Group controlId='modules'>
                        <Form.Label>
                          {t("pages.sphere.tags.section.form.labels.modules")}
                        </Form.Label>
                        <Select
                          key={`modules`}
                          name='modules'
                          options={moduleOptions}
                          isSearchable={false}
                          isClearable
                          isMulti
                          className={selectStyles.reactSelect}
                          classNamePrefix='custom-react-select'
                          placeholder={""}
                          onChange={handleModulesChanged}
                          value={modules}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Container>
              </AdvancedForm>
            )}

            <hr />
            <Row>
              <Col sm={11} className={styles.buttons}>
                <Button
                  variant='secondary'
                  className={styles.right}
                  onClick={() => handleClose()}
                >
                  {t("common.action.close")}
                </Button>
                <Button
                  disabled={archived || saving || archiving || !couldUpdateTag}
                  variant='primary'
                  className={styles.left}
                  type='submit'
                >
                  {saving && (
                    <div className={styles.submitContent}>
                      <ReactLoading
                        type={"bars"}
                        color={"#DDD"}
                        height={"30px"}
                        width={"40px"}
                      />
                      <div className={styles.submitText}>
                        {t("common.action.saving")}
                      </div>
                    </div>
                  )}
                  {!saving && t("common.action.save")}
                </Button>
                {tagId && (
                  <Button
                    disabled={archiving || saving || !couldUpdateTag}
                    variant={archived ? "secondary" : "danger"}
                    className={styles.left}
                    onClick={() =>
                      handleArchive(archived ? "restore" : "archive")
                    }
                  >
                    {archiving && (
                      <div className={styles.submitContent}>
                        <ReactLoading
                          type={"bars"}
                          color={"#DDD"}
                          height={"30px"}
                          width={"40px"}
                        />
                        <div className={styles.submitText}>
                          {t(
                            `common.action.${
                              archived ? "restoring" : "archiving"
                            }`
                          )}
                        </div>
                      </div>
                    )}
                    {!saving &&
                      t(`common.action.${archived ? "restore" : "archive"}`)}
                  </Button>
                )}
              </Col>
            </Row>
          </Container>
        </Form>
      )}
    </div>
  );
};

export default AddOrUpdateTagForm;
