import { useEffect, useState } from "react";
import Select, { OptionsType } from "react-select";
import { OptionType } from "../FacetFilter/MultiSelectFacet";
import selectStyles from "../../styles/react-select.module.scss";
import styles from "./TagTypeInput.module.scss";
import { useTranslation } from "react-i18next";

export const TYPE_VALUES = [
  "gender",
  "age",
  "seniority",
  "jobTitle",
  "job",
  "region",
  "area",
  "location",
  "branch",
  "department",
  "office",
  "education",
  "responsibility",
  "contract",
  "perspective",
  "trackerA",
  "trackerB",
  "trackerC",
];

type OptionExtended = OptionType & { sample: string };

interface Props {
  onChange: (value: string | undefined) => void;
  value: string | undefined;
  className?: string;
  placeholder?: string;
}

const getOptions = (t: any): OptionExtended[] => {
  const options = TYPE_VALUES.map((type) => {
    return {
      label: t(`pages.sphere.tags.section.form.values.type.${type}`),
      sample: t(`pages.sphere.tags.section.form.sample.type.${type}`),
      value: type,
      count: 0,
    };
  });

  options.sort((a, b) => a.label.localeCompare(b.label));
  return options;
};

const TagTypeInput = ({ value, onChange, className, placeholder }: Props) => {
  const { t } = useTranslation("i18n");
  const [options, setOptions] = useState<OptionsType<OptionExtended>>([]);

  useEffect(() => {
    setOptions(getOptions(t));
  }, [t]);

  const [selectedValue, setSelectedValue] = useState<OptionExtended>();

  useEffect(() => {
    const selectedValue = options.find((v) => v.value === value);
    setSelectedValue(selectedValue);
  }, [value, options]);

  const handleOnChange = (valueSelected: any) => {
    if (onChange) {
      onChange(valueSelected?.value);
    }
  };

  return (
    <Select
      onChange={handleOnChange}
      isSearchable={false}
      className={`${selectStyles.reactSelect} ${className}`}
      classNamePrefix='custom-react-select'
      options={options}
      isMulti={false}
      value={selectedValue as any}
      placeholder={placeholder || ""}
      isClearable
      noOptionsMessage={() => t("common.noValue")}
      formatOptionLabel={(option, labelMeta) => {
        if (labelMeta.context === "menu") {
          return (
            <div className={styles.option}>
              <div>{option.label}</div>
              {!["trackerA", "trackerB", "trackerC"].includes(option.value) && (
                <div className={styles.sample}>{`(${t(
                  "pages.sphere.tags.section.form.sample.title"
                )} ${option.sample})`}</div>
              )}
            </div>
          );
        }
        return (
          <div className={styles.selection}>
            <span>{option.label}</span>
          </div>
        );
      }}
    />
  );
};

export default TagTypeInput;
