import React, { useEffect, useState } from 'react';
import IELink from '../ie/IELink';
import IEModal from '../ie/IEModal';
import styles from './MoodMeterPolygon.module.scss';
import { getDarkColor, getEmptyColor } from '../../tools/colors';

export interface MoodMeterProps {
  red?: MoodMeterColorProps;
  blue?: MoodMeterColorProps;
  green?: MoodMeterColorProps;
  yellow?: MoodMeterColorProps;
}

export interface MoodMeterColorProps {
  active: boolean;
  count?: number;
}

export const getSVG = ({
  width,
  height,
  showNumber,
  moodMetre: { red, blue, green, yellow },
  backgroundColor,
  monochrome,
}: Props & {
  width: number;
  height: number;
  backgroundColor?: string;
  monochrome?: 'light' | 'dark';
}) => {
  const monochromeColor = backgroundColor;
  const monochromeBackground =
    monochrome === 'light' ? getEmptyColor() : getDarkColor();

  return (
    <svg
      viewBox='0 0 300 300'
      width={width}
      height={height}
      xmlns='http://www.w3.org/2000/svg'
    >
      <g>
        <title>background</title>
        <rect
          fill={backgroundColor || '#ffffff00'}
          id='canvas_background'
          height='300'
          width='300'
          y='0'
          x='0'
        />
        <g
          display='none'
          overflow='visible'
          y='0'
          x='0'
          height='100%'
          width='100%'
          id='canvasGrid'
        >
          <rect
            fill='url(#gridpattern)'
            strokeWidth='0'
            y='0'
            x='0'
            height='100%'
            width='100%'
          />
        </g>
      </g>

      <g>
        <title>RED</title>
        <rect
          id='RED'
          height='130'
          width='130'
          y='10'
          x='10'
          strokeWidth='10'
          stroke={monochrome ? monochromeColor : '#F55C72'}
          fill={
            monochrome
              ? monochromeBackground
              : `#f44a64${red?.active ? visible : invisible}`
          }
        />
        {showNumber && (
          <text
            textAnchor='middle'
            fontFamily='Helvetica, Arial, sans-serif'
            fontSize='80'
            id='RED_TEXT'
            y='105'
            x='75'
            strokeWidth='0'
            fill='#0c0a21'
          >
            {red?.count || ''}
          </text>
        )}
      </g>
      <g>
        <title>BLUE</title>
        <rect
          id='BLUE'
          height='130'
          width='130'
          y='160'
          x='10'
          strokeWidth='10'
          stroke={monochrome ? monochromeColor : '#4D8AFE'}
          fill={
            monochrome
              ? monochromeBackground
              : `#3a7ffe${blue?.active ? visible : invisible}`
          }
        />
        {showNumber && (
          <text
            textAnchor='middle'
            fontFamily='Helvetica, Arial, sans-serif'
            fontSize='80'
            id='BLUE_TEXT'
            y='255'
            x='75'
            strokeWidth='0'
            fill='#0c0a21'
          >
            {blue?.count || ''}
          </text>
        )}
      </g>
      <g>
        <title>GREEN</title>
        <rect
          id='GREEN'
          height='130'
          width='130'
          y='160'
          x='160'
          strokeWidth='10'
          stroke={monochrome ? monochromeColor : '#1FD99B'}
          fill={
            monochrome
              ? monochromeBackground
              : `#07d590${green?.active ? visible : invisible}`
          }
        />
        {showNumber && (
          <text
            textAnchor='middle'
            fontFamily='Helvetica, Arial, sans-serif'
            fontSize='80'
            id='BLUE_TEXT'
            y='255'
            x='225'
            strokeWidth='0'
            fill='#0c0a21'
          >
            {green?.count || ''}
          </text>
        )}
      </g>
      <g>
        <title>YELLOW</title>
        <rect
          id='YELLOW'
          height='130'
          width='130'
          y='10'
          x='160'
          strokeWidth='10'
          stroke={monochrome ? monochromeColor : '#F7E265'}
          fill={
            monochrome
              ? monochromeBackground
              : `#f7df54${yellow?.active ? visible : invisible}`
          }
        />
        {showNumber && (
          <text
            textAnchor='middle'
            fontFamily='Helvetica, Arial, sans-serif'
            fontSize='80'
            id='YELLOW_TEXT'
            y='105'
            x='225'
            strokeWidth='0'
            fill='#0c0a21'
          >
            {yellow?.count || ''}
          </text>
        )}
      </g>
    </svg>
  );
};

interface Props {
  monochrome?: 'light' | 'dark';
  showNumber: boolean;
  size: 'big' | 'medium' | 'small' | 'xsmall';
  moodMetre: MoodMeterProps;
  onlySVG?: boolean;
}

const visible = 'FF';
const invisible = '00';

const MoodMeterPolygon = ({
  moodMetre,
  size,
  showNumber,
  onlySVG,
  monochrome,
}: Props) => {
  const width =
    size === 'xsmall'
      ? 20
      : size === 'small'
      ? 45
      : size === 'medium'
      ? 90
      : 125;
  const height = width;

  const [color, setColor] = useState('multi');
  const [visible, setVisible] = useState<boolean>();

  const handleClick = () => {
    setVisible(true);
  };

  const handleClose = () => {
    setVisible(false);
  };

  useEffect(() => {
    const isBlue = moodMetre.blue?.active ? 1 : 0;
    const isRed = moodMetre.red?.active ? 1 : 0;
    const isYellow = moodMetre.yellow?.active ? 1 : 0;
    const isGreen = moodMetre.green?.active ? 1 : 0;
    const multi = isBlue + isRed + isYellow + isGreen;

    if (multi === 1) {
      setColor(
        isBlue
          ? 'blue'
          : isRed
          ? 'red'
          : isYellow
          ? 'yellow'
          : isGreen
          ? 'green'
          : 'multi'
      );
    } else {
      setColor('multi');
    }
  }, [moodMetre]);

  const svg = getSVG({
    moodMetre,
    size,
    showNumber,
    width,
    height,
    monochrome,
  });
  return onlySVG ? (
    svg
  ) : (
    <>
      <div className={`${styles.root}`} onClick={handleClick}>
        <div>{svg}</div>
        {size !== 'small' && (
          <div className={styles.ie} style={{ width, maxWidth: width }}>
            <IELink />
          </div>
        )}
      </div>
      {visible === undefined ? (
        <></>
      ) : (
        <IEModal show={visible} onClose={handleClose} defaultValue={color} />
      )}
    </>
  );
};

export default MoodMeterPolygon;
