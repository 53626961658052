import React, { ReactElement, useEffect, useState } from "react";
import styles from "./StepsComponent.module.scss";

import { Step } from "../../@types/form-api";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { motion, Variants } from "framer-motion";
import ReactLoading from "react-loading";
import { evaluateConditions } from "../../tools/formTools";
import StepComponent from "./StepComponent";

interface StepsComponentProps {
  steps: Step[];
  disabled?: boolean;
  printMode?: boolean;
  saving?: boolean;
  variants?: Variants;
  currentPageIdx?: number;
  pageElements?: ReactElement[];
  values?: Record<string, any>;
  next?: (state?: any) => void;
  previous?: (state?: any) => void;
  onChange?: (id: string, value: any) => void;
  onClose?: () => void;
}

const StepsComponent: React.FC<StepsComponentProps> = ({
  next = () => {},
  previous = () => {},
  onChange = () => {},
  variants,
  steps,
  values,
  currentPageIdx,
  pageElements,
  disabled,
  saving,
  printMode,
  onClose = () => {},
}) => {
  const { t } = useTranslation("i18n");

  const [showPrevious, setShowPrevious] = useState<boolean>(false);
  const [showNext, setShowNext] = useState<boolean>(false);
  const [showSave, setShowSave] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);
  const [visibleSteps, setVisibleSteps] = useState<(Step & { key: string })[]>(
    []
  );

  const [misingResponseId, setMisingResponseId] = useState<string[]>([]);

  const handleNext = () => {
    if (misingResponseId.length > 0) {
      setShowError(true);
      document
        .getElementById(`answer-${misingResponseId[0]}`)
        ?.scrollIntoView({ behavior: "smooth" });
      return;
    }

    if (next) {
      next({});
      setShowError(false);
    }
  };
  const handlePrevious = () => {
    if (previous) {
      previous({});
    }
  };
  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  useEffect(() => {
    const index = currentPageIdx || 0;
    const stepNumber = pageElements?.length || 1;
    setShowNext(index < stepNumber - 1);
    setShowPrevious(index > 0);
    setShowSave(!!pageElements && stepNumber - 1 === index);
  }, [currentPageIdx, pageElements]);

  useEffect(() => {
    const visibleSteps = steps
      .map((s, index) => ({ ...s, key: `STEP-${index}` }))
      .filter((step) => evaluateConditions(step.displayConditions, values));
    setVisibleSteps(visibleSteps);
  }, [values, steps]);

  useEffect(() => {
    const misingResponseIds = visibleSteps.reduce(
      (acc, step) => [
        ...acc,
        ...step.answers
          .filter(
            (a) =>
              evaluateConditions(a.displayConditions, values) &&
              a.required &&
              (!values || values[a.id] === undefined)
          )
          .map((a) => a.id),
      ],
      [] as string[]
    );

    setMisingResponseId(misingResponseIds);
  }, [visibleSteps, values]);

  return (
    <motion.div
      variants={variants}
      initial='hidden'
      animate='visible'
      exit='exit'
      className={printMode ? "" : styles.page}
    >
      {/* <Badge
        className={styles.summary}
        variant={responseCount === answerCount ? 'success' : 'secondary'}
      >
        {responseCount}/{answerCount}
      </Badge> */}

      <div className={printMode ? "" : styles.content}>
        <div className={printMode ? "" : styles.innerContent}>
          {visibleSteps.map((step, index) => {
            return (
              <div
                className={`${styles.stepContent}`}
                key={`step-content-${index}`}
              >
                {index > 0 && <div className={styles.stepSeperator} />}
                <StepComponent
                  step={step}
                  printMode={printMode}
                  showError={showError}
                  onChange={onChange}
                  values={values || {}}
                  disabled={disabled || saving}
                />
              </div>
            );
          })}
          {!printMode && (
            <div className={styles.footer}>
              {showPrevious && (
                <Button disabled={saving} onClick={handlePrevious}>
                  {t("common.action.previous")}
                </Button>
              )}
              {showNext && (
                <Button onClick={handleNext} disabled={saving}>
                  {t("common.action.next")}
                </Button>
              )}
              {!disabled && showSave && (
                <Button
                  onClick={handleNext}
                  disabled={saving}
                  variant='primary'
                  className={styles.left}
                  type='submit'
                >
                  {saving && (
                    <div className={styles.submitContent}>
                      <ReactLoading
                        type={"bars"}
                        color={"#DDD"}
                        height={"30px"}
                        width={"40px"}
                      />
                      <div className={styles.submitText}>
                        {t("common.action.saving")}
                      </div>
                    </div>
                  )}
                  {!saving && t("common.action.save")}
                </Button>
              )}
              {disabled && (
                <Button onClick={handleClose} variant='secondary'>
                  {t("common.action.close")}
                </Button>
              )}
            </div>
          )}
        </div>
      </div>
    </motion.div>
  );
};

export default StepsComponent;
