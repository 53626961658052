import React, { useContext, useEffect, useState } from "react";
import BurgerMenu from "react-burger-menu";
import PageShrinkMenu from "./push-shrink";
import cn from "classnames";
import LayoutContext from "../../context/layout/LayoutContext";
import burgerStyles from "./react-burger-menu.module.scss";
import menuStyles from "./Menu.module.scss";
import {
  getDefaultPage,
  getMyHistoryPage,
  getUrlParams,
  getAdminSphereRoute,
  getAdminSphereEngagementUpRoute,
  getSphereFullReportRoute,
  getSphereContributionRoute,
  getAdminSphereListingRoute,
  getAdminSphereSalesUpRoute,
  getAdminThinkingAxesListingRoute,
  getSessionRoute,
  getAdminSessionRoute,
  getSessionReportRoute,
  getMyFormsPage,
  getChatRoute,
  getGlobalMembersRoute,
  getCampaignAdminRoute,
  getCampaignReportsRoute,
  getCampaignCustomReportRoute,
  getReportAdminRoute,
} from "../../tools/routeTools";
import {
  Home4LineIcon,
  BarChartLineIcon,
  BarChartBoxLineIcon,
  HistoryLineIcon,
  EarthLineIcon,
  Settings3LineIcon,
  ExternalLinkLineIcon,
  ShieldKeyholeLineIcon,
  ShutDownLineIcon,
  ShieldUserLineIcon,
  ReportIcon,
} from "../RemixIcons";
import { MenuProperties, MenuOptions } from "../../@types/seen-apps";
import { useTranslation } from "react-i18next";
import GlobalStateContext from "../../context/globalState/GlobalStateContext";
import { Link, useLocation, useHistory } from "react-router-dom";
import AbilityContext from "../../context/AbilityContext";
import { Divider } from "@material-ui/core";
import { subject } from "@casl/ability";
import SessionIcon from "../session/SessionIcon";
import { getSessionReports } from "../../reports/SessionReports";
import { getMemberFromSession, getSessionName } from "../../tools/sessionTools";
import { toast } from "react-toastify";
import api from "../../api/Api";
import { confirmWrapper } from "../../tools/confirm";
import {
  DELETE_MEMBER,
  UPDATE_MEMBER,
} from "../../context/globalState/globalStateReducer";
import { captureException } from "@sentry/minimal";
import { Action, Subject } from "../../@types/member-api";
import { getRelevantTranslationFor } from "../../tools/multiLingualTools";

const styles = { ...burgerStyles, ...menuStyles };

const handleLeaveSessionClick = async (menuOptions: MenuOptions) => {
  const { t, dispatch, history, state, ability } = menuOptions;
  const session = state.context.sessionCtx!.session;
  const prefixDico = `pages.sphere.sessions.${session.category}.leave`;
  let member = getMemberFromSession(session.id, state);

  if (!member) {
    toast.error(t("common.not-found.member"));
    return;
  }

  if (
    await confirmWrapper(
      t(`${prefixDico}.message`, {
        session: getSessionName(session, ability),
      }),
      {
        title: t(`${prefixDico}.title`, {
          session: getSessionName(session, ability),
        }),
        btnPrimaryLabel: t(`${prefixDico}.confirm`, {
          session: getSessionName(session, ability),
        }),
      }
    )
  ) {
    try {
      member = await api.leaveSession(session.id, member.id);
      toast.success(
        t(`${prefixDico}.success`, {
          session: getSessionName(session, ability),
        })
      );
      dispatch({
        type: UPDATE_MEMBER,
        payload: member,
      });
      history.push(
        getSphereContributionRoute({
          sphereId: session.sphere.id,
        })
      );
    } catch (error) {
      captureException(error);
      toast.error(t(`${prefixDico}.error`));
    }
  }
};

const handleLeaveSphereClick = async (menuOptions: MenuOptions) => {
  const {
    t,
    dispatch,
    history,
    state: {
      members,
      context: { sphereCtx },
    },
  } = menuOptions;
  const { id: sphereId, name: sphereName } = sphereCtx?.sphere || {};
  const member = members.find((m) => m.sphereId === sphereId);
  if (!member) {
    toast.error(t("common.not-found.member"));
    return;
  }

  if (
    await confirmWrapper(
      t("app.sphere.leave.message", { sphere: sphereName }),
      {
        title: t("app.sphere.leave.title", { sphere: sphereName }),
        btnPrimaryLabel: t("app.sphere.leave.confirm", {
          sphere: sphereName,
        }),
      }
    )
  ) {
    try {
      await api.leaveSphere(member.sphereId!, member.id);
      toast.success(t("app.sphere.leave.success", { sphere: sphereName }));
      dispatch({
        type: DELETE_MEMBER,
        payload: member,
      });
      history.push(getDefaultPage());
    } catch (error) {
      captureException(error);
      toast.error(t("app.sphere.leave.error", { error }));
    }
  }
};

const defaultMenu: MenuProperties[] = [
  {
    path: () => getDefaultPage(),
    title: ({ t }: MenuOptions) => t("pages.myself.title"),
    visible: () => true,
    selected: ({ path }: MenuOptions) => path === "" || path === "/",
    icon: <Home4LineIcon />,
    application: "myself",
  },
  // {
  //   path: () => getMyselfFullReportPage(),
  //   title: ({ t }: MenuOptions) => t('pages.myselfReport.title'),
  //   visible: () => true,
  //   disabled: ({ state }: MenuOptions) => state?.profile?.isGuest || false,
  //   selected: ({ path }: MenuOptions) => path === getMyselfFullReportPage(),
  //   icon: <BarChartLineIcon />,
  //   application: 'myself',
  // },
  {
    path: () => getMyFormsPage(),
    title: ({ t }: MenuOptions) => t("pages.myFormsReport.title"),
    visible: () => true,
    disabled: ({ state }: MenuOptions) => state?.profile?.isGuest || false,
    selected: ({ path }: MenuOptions) => path === getMyFormsPage(),
    icon: <ShieldUserLineIcon />,
    application: "myself",
  },
  {
    path: () => getMyHistoryPage(),
    title: ({ t }: MenuOptions) => t("pages.myHistory.title"),
    visible: () => true,
    selected: ({ path }: MenuOptions) => path === getMyHistoryPage(),
    icon: <HistoryLineIcon />,
    application: "myself",
  },
];
const sphereMenu: MenuProperties[] = [
  {
    path: ({ state }: MenuOptions) => {
      return state.context.sphereCtx
        ? getSphereContributionRoute({
            sphereId: state.context.sphereCtx.sphere.id,
          })
        : "";
    },
    title: ({ state }: MenuOptions) =>
      state.context?.sphereCtx?.sphere.name || "",
    visible: ({ state, ability }: MenuOptions) => {
      return (
        !!state.context.sphereCtx &&
        ability.can(
          "get",
          subject("sphere", { id: state.context.sphereCtx.sphere.id })
        )
      );
    },
    selected: ({ path, pathParams }: MenuOptions) =>
      path === getSphereContributionRoute(pathParams as any),
    icon: <EarthLineIcon />,
    application: "sphere",
  },
  {
    path: ({ state }: MenuOptions) => {
      return state.context.sphereCtx
        ? getSphereFullReportRoute({
            sphereId: state.context.sphereCtx.sphere.id,
          })
        : "";
    },
    title: ({ t }: MenuOptions) => t("pages.sphere.report.title"),
    level: 0,
    visible: ({ state, ability }: MenuOptions) => {
      return (
        !!state.context.sphereCtx &&
        ability.can(
          "smartReport",
          subject("sphere", { id: state.context.sphereCtx.sphere.id })
        )
      );
    },
    selected: ({ path, pathParams }: MenuOptions) =>
      path ===
      getSphereFullReportRoute({
        ...(pathParams as any),
      }),
    icon: <BarChartBoxLineIcon />,
    application: "sphere",
  },
  {
    path: ({ state }: MenuOptions) => {
      return state.context.sphereCtx
        ? getAdminSphereRoute({ sphereId: state.context.sphereCtx.sphere.id })
        : "";
    },
    title: ({ t }: MenuOptions) => t("pages.sphere.settings.title"),
    visible: ({ state, ability }: MenuOptions) => {
      return (
        !!state.context.sphereCtx &&
        ability.can(
          "put",
          subject("sphere", { id: state.context.sphereCtx.sphere.id })
        )
      );
    },
    selected: ({ path, pathParams }: MenuOptions) =>
      path === getAdminSphereRoute(pathParams as any),
    icon: <Settings3LineIcon />,
    application: "sphere",
  },
  {
    path: ({ state }: MenuOptions) => {
      return state.context.sphereCtx
        ? getAdminSphereEngagementUpRoute({
            sphereId: state.context.sphereCtx.sphere.id,
          })
        : "";
    },
    title: ({ t }: MenuOptions) =>
      t("pages.sphere.settings.section.engagement-up.title"),
    visible: ({ state, ability }: MenuOptions) => {
      return (
        !!state.context.sphereCtx?.sphere?.entitlements?.engagementUp?.active &&
        ability.can(
          "put",
          subject("sphere", { id: state.context.sphereCtx.sphere.id })
        )
      );
    },
    level: 0,
    selected: ({ path, pathParams }: MenuOptions) => {
      return path === getAdminSphereEngagementUpRoute(pathParams as any);
    },
    icon: <SessionIcon session={{ type: "group", category: "contributor" }} />,
    application: "sphere",
  },
  {
    path: ({ state }: MenuOptions) => {
      return state.context.sphereCtx
        ? getAdminSphereSalesUpRoute({
            sphereId: state.context.sphereCtx.sphere.id,
          })
        : "";
    },
    title: ({ t }: MenuOptions) =>
      t("pages.sphere.settings.section.sales-up.title"),
    visible: ({ state, ability }: MenuOptions) => {
      return (
        !!state.context.sphereCtx?.sphere?.entitlements?.salesUp?.active &&
        ability.can(
          "put",
          subject("sphere", { id: state.context.sphereCtx.sphere.id })
        )
      );
    },
    level: 0,
    selected: ({ path, pathParams }: MenuOptions) =>
      path === getAdminSphereSalesUpRoute(pathParams as any),
    icon: <SessionIcon session={{ type: "group", category: "consumer" }} />,
    application: "sphere",
  },
  {
    onClick: handleLeaveSphereClick,
    path: () => {
      return "";
    },
    title: ({ t, state }: MenuOptions) =>
      t(`app.sphere.leave.title`, {
        sphere: state.context.sphereCtx?.sphere.name || "",
      }),
    visible: ({ state }: MenuOptions) => {
      return !!state.context.sphereCtx;
    },
    selected: () => false,
    icon: <ShutDownLineIcon color='red' />,
    application: "sphere",
  },
];

const sessionMenu: MenuProperties[] = [
  {
    path: ({ state }: MenuOptions) => {
      return state.context.sessionCtx
        ? getSphereContributionRoute({
            sphereId: state.context.sessionCtx.session.sphere.id,
          })
        : "";
    },
    title: ({ state }: MenuOptions) =>
      state.context?.sessionCtx?.session.sphere.name || "",
    visible: ({ state, ability }: MenuOptions) => {
      return (
        !!state.context.sessionCtx &&
        ability.can(
          "smartReport",
          subject("session", state.context.sessionCtx.session)
        )
      );
    },
    selected: ({ path, pathParams }: MenuOptions) =>
      path === getSphereContributionRoute(pathParams as any),
    icon: <EarthLineIcon />,
    application: "sphere",
  },
  {
    path: ({ state }: MenuOptions) => {
      return state.context.sessionCtx
        ? getSessionRoute({
            sessionId: state.context.sessionCtx.session.id,
          })
        : "";
    },
    title: ({ state, ability }: MenuOptions) =>
      getSessionName(state.context?.sessionCtx?.session, ability),
    visible: ({ state, ability }: MenuOptions) => {
      return !!state.context.sessionCtx;
    },
    selected: ({ path, pathParams }: MenuOptions) =>
      path === getSessionRoute(pathParams as any),

    disabled: ({ state, ability }: MenuOptions) => {
      return (
        !state.context.sessionCtx ||
        ability.cannot(
          "smartReport",
          subject("session", state.context.sessionCtx.session)
        )
      );
    },
    icon: ({ state }: MenuOptions) =>
      state.context.sessionCtx ? (
        <SessionIcon session={state.context.sessionCtx.session} />
      ) : (
        <ShieldKeyholeLineIcon />
      ),
    application: "session",
  },
  {
    path: ({ state }: MenuOptions) => {
      return state.context.sessionCtx
        ? getSessionReportRoute({
            sessionId: state.context.sessionCtx.session.id,
          })
        : "";
    },
    title: ({ t, state }: MenuOptions) =>
      t(
        `pages.sphere.sessions.settings.${state.context?.sessionCtx?.session.category}.reports`
      ),
    visible: ({ state, ability, t }: MenuOptions) => {
      const session = state.context.sessionCtx?.session;
      if (!session) {
        return false;
      }
      const reports = getSessionReports({
        ability,
        context: state.context,
        t,
        profile: state.profile,
        strategyEnabled: false,
      });
      return reports.length > 0;
    },
    selected: ({ path, pathParams }: MenuOptions) =>
      path === getSessionReportRoute({ ...(pathParams as any) }),
    icon: <BarChartLineIcon />,
    application: "session",
  },

  {
    path: ({ state }: MenuOptions) => {
      return state.context.sessionCtx
        ? getAdminSessionRoute({
            sessionId: state.context.sessionCtx.session.id,
          })
        : "";
    },
    title: ({ t }: MenuOptions) => t("pages.sphere.sessions.settings.title"),
    visible: ({ state, ability }: MenuOptions) => {
      return (
        !!state.context.sessionCtx &&
        ability.can("put", subject("session", state.context.sessionCtx.session))
      );
    },
    selected: ({ path, pathParams }: MenuOptions) =>
      path === getAdminSessionRoute(pathParams as any),
    icon: <Settings3LineIcon />,
    application: "session",
  },
  {
    onClick: handleLeaveSessionClick,
    path: ({ state }: MenuOptions) => {
      return "";
    },
    title: ({ t, state }: MenuOptions) =>
      t(
        `pages.sphere.sessions.${state.context.sessionCtx?.session.category}.leave.title`
      ),
    visible: ({ state, ability }: MenuOptions) => {
      return (
        !!state.context.sessionCtx &&
        state.mySessions.some(
          (s) => s.id === state.context.sessionCtx?.session.id
        )
      );
    },
    selected: () => false,
    icon: <ShutDownLineIcon color='red' />,
    application: "session",
  },
];

const campaignMenu: MenuProperties[] = [
  {
    path: ({ state }: MenuOptions) => {
      return state.context.campaignCtx
        ? getCampaignReportsRoute({
            campaignId: state.context.campaignCtx.id,
          })
        : "";
    },
    title: ({ t, state }: MenuOptions) => t(`pages.campaign.reports.title`),
    visible: ({ state, ability, t }: MenuOptions) => {
      return !!state.context.campaignCtx;
    },
    selected: ({ path, pathParams }: MenuOptions) =>
      path === getCampaignReportsRoute({ ...(pathParams as any) }),
    icon: <BarChartLineIcon />,
    application: "campaign",
  },
  {
    path: ({ state }: MenuOptions) => {
      return state.context.campaignCtx
        ? getCampaignCustomReportRoute({
            campaignId: state.context.campaignCtx.id,
          })
        : "";
    },
    title: ({ t, state }: MenuOptions) => t(`pages.report.title`),
    visible: ({ state, ability, t }: MenuOptions) => {
      return !!state.context.campaignCtx;
    },
    selected: ({ path, pathParams }: MenuOptions) =>
      path === getCampaignCustomReportRoute({ ...(pathParams as any) }),
    icon: <ReportIcon />,
    application: "campaign",
  },
  {
    path: ({ state }: MenuOptions) => {
      return state.context.campaignCtx
        ? getCampaignAdminRoute({
            campaignId: state.context.campaignCtx.id,
          })
        : "";
    },
    title: ({ state }: MenuOptions) =>
      getRelevantTranslationFor(state.context?.campaignCtx?.name),
    visible: ({ state, ability }: MenuOptions) => {
      return (
        !!state.context.campaignCtx &&
        ability.can(
          Action.Put,
          subject(Subject.Campaign, state.context.campaignCtx)
        )
      );
    },
    selected: ({ path, pathParams }: MenuOptions) =>
      path === getCampaignAdminRoute(pathParams as any),
    icon: <Settings3LineIcon />,
    application: "campaign",
  },
];
const reportMenu: MenuProperties[] = [
  {
    path: ({ state }: MenuOptions) => {
      return state.context.reportCtx
        ? getReportAdminRoute({
            reportId: state.context.reportCtx.id,
          })
        : "";
    },
    title: ({ t, state }: MenuOptions) =>
      state.context.reportCtx
        ? getRelevantTranslationFor(state.context.reportCtx.name)
        : "",
    visible: ({ state, ability, t }: MenuOptions) => {
      return !!state.context.reportCtx;
    },
    selected: ({ path, pathParams }: MenuOptions) =>
      path === getReportAdminRoute({ ...(pathParams as any) }),
    icon: <ReportIcon />,
    application: "report",
  },
];

const adminMenu: MenuProperties[] = [
  {
    path: () => getChatRoute({}),
    title: ({ t }: MenuOptions) => t("pages.chat.title"),
    visible: ({ ability }: MenuOptions) => ability.can("manage", "chat-thread"),
    selected: ({ path }: MenuOptions) => path === getChatRoute({}),
    icon: <ShieldKeyholeLineIcon />,
    application: "admin",
  },
  {
    path: () => getAdminSphereListingRoute(),
    title: ({ t }: MenuOptions) => t("pages.sphere.manage.title"),
    visible: ({ ability }: MenuOptions) => ability.can("manage", "sphere"),
    selected: ({ path }: MenuOptions) => path === getAdminSphereListingRoute(),
    icon: <ShieldKeyholeLineIcon />,
    application: "admin",
  },
  {
    path: () => getAdminThinkingAxesListingRoute(),
    title: ({ t }: MenuOptions) => t("pages.thinking-axis.manage.title"),
    visible: ({ ability }: MenuOptions) => ability.can("manage", "sphere"),
    selected: ({ path }: MenuOptions) =>
      path === getAdminThinkingAxesListingRoute(),
    icon: <ShieldKeyholeLineIcon />,
    application: "admin",
  },
  {
    path: () => getGlobalMembersRoute({}),
    title: ({ t }: MenuOptions) => t("pages.superAdmin.members.title"),
    visible: ({ ability }: MenuOptions) => ability.can("manage", "member"),
    selected: ({ path }: MenuOptions) => path === getGlobalMembersRoute({}),
    icon: <ShieldKeyholeLineIcon />,
    application: "admin",
  },
];

export const navigationMenu: MenuProperties[] = [
  ...defaultMenu,
  ...sphereMenu,
  ...sessionMenu,
  ...campaignMenu,
  ...reportMenu,
  ...adminMenu,
];

const NavigationMenu = () => {
  const layoutContext = useContext(LayoutContext);
  const { t } = useTranslation("i18n");
  const { state: globalState, dispatch } = useContext(GlobalStateContext);
  const location = useLocation();
  const history = useHistory();

  const [menus, setMenus] = useState<
    (MenuProperties & { pathValue: string; isDisabled: boolean })[]
  >([]);

  const ability = useContext(AbilityContext);

  useEffect(() => {
    const menuOptions: MenuOptions = {
      path: location.pathname,
      dispatch,
      state: {
        context: globalState.context,
        user: globalState.user,
        mySessions: globalState.mySessions || [],
        version: globalState.version,
        profile: globalState.profile,
        members: globalState.members,
      },
      ability,
      pathParams: getUrlParams(location.pathname),
      history,
      t,
    };
    const menus = navigationMenu
      .filter((m) => m.visible(menuOptions))
      .reduce((acc, menu, index, array) => {
        const { icon, title, selected, newTab, onClick } = menu;

        const pathValue = menu.path(menuOptions);
        const isDisabled = menu.disabled && menu.disabled(menuOptions);

        const accCopy = [...acc];

        // Add separator if application change
        if (index > 0 && array[index - 1].application !== menu.application) {
          accCopy.push(
            <Divider key={`divider_${pathValue}`} className={styles.divider} />
          );
        }

        const levelStyle = {
          marginLeft: `${(menu.level || 0) * 30}px`,
        };

        accCopy.push(
          isDisabled ? (
            <li key={pathValue} className={cn(styles.navItemDisabled, {})}>
              {
                <span
                  className={`${styles.navLinkDisabled} ${styles.maintitle}`}
                  style={levelStyle}
                >
                  <div className={styles.menu}>
                    {typeof icon === "function" ? icon(menuOptions) : icon}
                    <span className={styles.menuText}>
                      {title(menuOptions)}
                    </span>
                  </div>
                </span>
              }
            </li>
          ) : (
            <li
              key={pathValue}
              className={cn(styles.navItem, {
                [styles.selected]: selected(menuOptions),
              })}
            >
              {pathValue.startsWith("http") ? (
                <a
                  className={`${styles.navLink} ${styles.maintitle}`}
                  href={pathValue}
                  target={newTab ? "_blank" : undefined}
                  rel='noreferrer'
                >
                  <div className={styles.menu}>
                    {typeof icon === "function" ? icon(menuOptions) : icon}
                    <span className={styles.menuText}>
                      {title(menuOptions)}
                    </span>
                    <ExternalLinkLineIcon className={styles.openInNew} />
                  </div>
                </a>
              ) : (
                <Link
                  className={`${styles.navLink} ${styles.titleStyle}`}
                  style={levelStyle}
                  onClick={(event) => {
                    layoutContext.setSelectedNavigationMenuItem(
                      navigationMenu[index]
                    );
                    if (onClick) {
                      onClick(menuOptions);
                      event.preventDefault();
                      event.stopPropagation();
                    }
                  }}
                  to={pathValue}
                >
                  <div className={styles.menu}>
                    {typeof icon === "function" ? icon(menuOptions) : icon}
                    <span className={styles.menuText}>
                      {title(menuOptions)}
                    </span>
                  </div>
                </Link>
              )}
            </li>
          )
        );

        return accCopy;
      }, [] as any[]);

    setMenus(menus);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    ability,
    dispatch,
    globalState.context,
    globalState.members,
    globalState.profile,
    globalState.user,
    globalState.version,
    history,
    layoutContext,
    location.pathname,
    globalState.context,
    t,
  ]);

  const isGuest = globalState.profile?.isGuest;

  const Menu = layoutContext.isNavigationMenuDocked
    ? PageShrinkMenu
    : BurgerMenu["slide"];

  return (
    <div className={`${styles.root} ${isGuest ? styles.guestRoot : ""}`}>
      <Menu
        className={styles.menuContainer}
        width={250}
        customBurgerIcon={false}
        customCrossIcon={false}
        pageWrapId={"page-wrap"}
        isOpen={
          layoutContext.isNavigationMenuDocked ||
          layoutContext.isNavigationMenuOpen
        }
        noOverlay={layoutContext.isNavigationMenuDocked}
        onStateChange={(state: any) =>
          layoutContext.navigationStateChangeHandler(state)
        }
      >
        <ul className={`navbar-nav ${styles.leftSideMenu}`}>{menus}</ul>
      </Menu>
    </div>
  );
};

export default NavigationMenu;
