import React, { FC, ReactNode } from "react";
import cn from "classnames";
import styles from "./Card.module.scss";

export type Props = {
  title?: string | ReactNode;
  subTitle?: string | ReactNode;
  icon?: ReactNode;
  actionIcon?: ReactNode;
  variant?: "primary" | "secondary" | "dark";
  onClick?: (event?: any) => void;
  className?: string;
  headerClassName?: string;
  bodyClassName?: string;
};

const Card: FC<Props> = ({
  title,
  subTitle,
  icon,
  actionIcon,
  onClick,
  children,
  variant,
  className,
  headerClassName,
  bodyClassName,
}) => {
  return (
    <div
      className={cn(
        className,
        styles.root,
        variant ? styles[variant] : styles.primary,
        {
          [styles.clickable]: onClick,
        }
      )}
      onClick={onClick}
    >
      <div className={`${styles.header} ${headerClassName ?? ""}`}>
        {icon && <div className={styles.avatar}>{icon}</div>}
        <div className={styles.content}>
          <span className={styles.title}>{title}</span>
          {subTitle && <span className={styles.subTitle}>{subTitle}</span>}
        </div>
        {actionIcon && <div className={styles.action}>{actionIcon}</div>}
      </div>
      <div className={`${styles.body} ${bodyClassName || ""}`}>{children}</div>
    </div>
  );
};

export default Card;
