import styles from "./ParticipationMenuItem.module.scss";
import {
  CampaignMesureDefinition,
  ParticipationValue,
} from "../../@types/campaign-api";
import { getRelevantTranslationFor } from "../../tools/multiLingualTools";
import { useEffect, useState } from "react";
import { MultiLingual } from "../../@types/sphere-api";
import ParticipationSubMenuItem from "./ParticipationSubMenuItem";
import { motion } from "framer-motion";

export type MeasureItem = {
  id: string;
  position: number;
  title: MultiLingual;
  message?: MultiLingual;
  stepDefinitions: CampaignMesureDefinition[];
  values: ParticipationValue[];
};

type Props = {
  size: "sm" | "lg";
  currentStep: number;
  onClick: (stepId: number) => void;
  measure: MeasureItem;
};

const ParticipationMenuItem: React.FC<Props> = ({
  measure,
  currentStep,
  onClick,
  size,
}) => {
  const [isSelected, setIsSelected] = useState(false);
  const [status, setStatus] = useState<"done" | "inProgress" | "todo">("todo");
  const [mode, setMode] = useState<"full" | "smart" | "collapse">("collapse");
  useEffect(() => {
    setIsSelected(
      measure.stepDefinitions.some((s) => s.stepId === currentStep)
    );
  }, [measure.stepDefinitions, currentStep]);

  useEffect(() => {
    const relevantValuesForMenu = measure.values.filter((value) =>
      measure.stepDefinitions.some(
        (stepDefinition) => stepDefinition.stepId === value.stepId
      )
    );
    let mode: "done" | "inProgress" | "todo" = "todo";
    if (relevantValuesForMenu.every((value) => value.stepStatus === "done")) {
      mode = "done";
    } else if (
      relevantValuesForMenu.some(
        (value) =>
          value.stepStatus === "inProgress" || value.stepStatus === "done"
      )
    ) {
      mode = "inProgress";
    }
    setStatus(mode);
  }, [measure.stepDefinitions, measure.values]);

  useEffect(() => {
    setMode(
      size === "lg"
        ? "full"
        : size === "sm" && isSelected
        ? "smart"
        : "collapse"
    );
  }, [isSelected, size]);

  const handleClick = () => {
    const relevantIndex =
      //retrieve the first step not done
      measure.values.find(
        (value) =>
          value.stepStatus === "todo" || value.stepStatus === "inProgress"
      )?.stepId || measure.values[0].stepId;
    onClick(relevantIndex);
  };

  const SubItems = ({
    steps,
    className,
  }: {
    steps: CampaignMesureDefinition[];
    className?: string;
  }) => {
    return (
      <div className={className}>
        {measure.stepDefinitions.map((step) => {
          const value = measure.values.find((v) => v.stepId === step.stepId);
          return (
            <ParticipationSubMenuItem
              key={`SUB_MENU_${step.measureId}_${step.stepId}`}
              currentStep={currentStep}
              definition={step}
              value={value}
              onClick={onClick}
              size={size}
            />
          );
        })}
      </div>
    );
  };

  return (
    <li onClick={handleClick}>
      <div
        className={`${styles.navItem}  ${
          isSelected ? styles.navItemSelected : ""
        }`}
      >
        <div
          className={`${styles.mainItem} ${
            mode === "smart" ? styles.mainSmartItem : ""
          } ${styles[status]} ${
            isSelected ? styles.itemSelected : styles.itemNotSelected
          }`}
        >
          <div className={`${styles.measureNumber}`}>{measure.position}</div>
          {mode === "collapse" ? (
            <></>
          ) : mode === "full" ? (
            <>
              <div className={`${styles.divider}`}></div>
              <div className={styles.menuText}>
                {getRelevantTranslationFor(measure.title)}
                <SubItems
                  steps={measure.stepDefinitions}
                  className={`${styles.subItems}`}
                />
              </div>
            </>
          ) : (
            <motion.div
              transition={{ duration: 0.25 }}
              initial={{
                height: 0,
              }}
              animate={{
                scale: 1,
                height: "unset",
              }}
              variants={{
                hidden: {
                  opacity: 0,
                },
                show: {
                  opacity: 1,
                },
              }}
            >
              <SubItems
                steps={measure.stepDefinitions}
                className={`${styles.subItemsVertical}`}
              />
            </motion.div>
          )}
        </div>
      </div>
    </li>
  );
};

export default ParticipationMenuItem;
