import { useContext } from "react";
import { slide as Menu } from "react-burger-menu";
import cn from "classnames";
import LayoutContext from "../../context/layout/LayoutContext";
import burgerStyles from "./react-burger-menu.module.scss";
import menuStyles from "./Menu.module.scss";
import {
  getProfileRoute,
  getEmailsRoute,
  getChangePasswordRoute,
  getUrlParams,
  getGuestRegistrationRoute,
  getSubscriptionRoute,
} from "../../tools/routeTools";
import {
  LockPasswordLineIcon,
  AtLineIcon,
  ShutDownLineIcon,
  UserSettingsLineIcon,
  GuestIcon,
  ExternalLinkLineIcon,
  PrivacyIcon,
  FeedbackIcon,
  SubscriptionIcon,
  UseConnectIcon,
} from "../RemixIcons";
import {
  MenuProperties,
  MenuOptions,
  translateFnc,
} from "../../@types/seen-apps";
import GlobalStateContext from "../../context/globalState/GlobalStateContext";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useLocation } from "react-router-dom";
import AbilityContext from "../../context/AbilityContext";
import { Auth } from "aws-amplify";
import { confirmWrapper } from "../../tools/confirm";
import { EmailsPage, PasswordPage, ProfilePage } from "../../AppRoutes";
import { Divider } from "@material-ui/core";
import { Badge } from "react-bootstrap";

const styles = { ...burgerStyles, ...menuStyles };

const profileMenus: MenuProperties[] = [
  {
    path: () => getProfileRoute({ callerUrl: "" }),
    title: ({ t, state }: MenuOptions) =>
      state.profile ? state.profile.firstName : t("pages.user.profile"),
    visible: () => true,
    selected: () => false,
    // disabled: ({ state }: MenuOptions) => state?.profile?.isGuest || false,
    icon: ({ state }: MenuOptions) => <UserSettingsLineIcon />,
    application: "myself",
  },
  {
    path: () => getSubscriptionRoute({ callerUrl: "" }),
    title: ({ t, state }: MenuOptions) => (
      <span className={styles.subscription}>
        {t(`pages.user.subscription.title`)}
        <Badge variant='secondary'>
          {t(`pages.user.subscription.${state.ability?.subscription}.title`)}
        </Badge>
      </span>
    ),
    visible: ({ state }) =>
      state.ability?.subscription && !state?.profile?.isGuest,
    selected: () => false,
    icon: ({ state }: MenuOptions) => <SubscriptionIcon />,
    application: "myself",
  },
  {
    path: () => getEmailsRoute({ callerUrl: "" }),
    title: ({ t }: MenuOptions) => t("pages.emails.title"),
    visible: ({ state }: MenuOptions) => !!!state?.profile?.isGuest,
    selected: () => false,
    icon: <AtLineIcon />,
    application: "myself",
  },
  {
    path: () => getChangePasswordRoute({ callerUrl: "" }),
    title: ({ t }: MenuOptions) => t("pages.user.changePassword"),
    visible: ({ state }: MenuOptions) => !!!state?.profile?.isGuest,
    selected: () => false,
    icon: <LockPasswordLineIcon />,
    application: "myself",
  },
  {
    path: () => getGuestRegistrationRoute({ callerUrl: "" }),
    title: ({ t }: MenuOptions) => t("pages.guest.createAccount"),
    visible: ({ state }: MenuOptions) => !!state?.profile?.isGuest,
    selected: () => false,
    icon: <GuestIcon />,
    application: "myself",
  },
  {
    path: () => "",
    title: ({ t }: MenuOptions) => t("loginflow.connect"),
    visible: ({ state }: MenuOptions) => !!state?.profile?.isGuest,
    onClick: async (
      { state }: MenuOptions,
      event: MouseEvent,
      t: translateFnc,
      history,
      callback
    ) => {
      event.preventDefault();
      await Auth.signOut();
      history.push("/");
      callback();
    },
    selected: () => false,
    icon: <UseConnectIcon />,
    application: "myself",
  },
  {
    path: () => "",
    onClick: async (
      { state }: MenuOptions,
      event: MouseEvent,
      t: translateFnc,
      history,
      callback
    ) => {
      event.preventDefault();
      const isGuest = state.profile?.isGuest;

      if (
        await confirmWrapper(
          isGuest
            ? t("pages.user.leaveConfirm")
            : t("pages.user.logoutConfirm"),
          {
            title: isGuest ? t("pages.user.leave") : t("pages.user.logout"),
            btnPrimaryLabel: isGuest
              ? t("common.action.leave")
              : t("common.action.logout"),
          }
        )
      ) {
        callback();
        await Auth.signOut();
        history.push("/");
      }
      callback();
    },
    title: ({ t, state }: MenuOptions) =>
      state.profile?.isGuest ? t("pages.user.leave") : t("pages.user.logout"),
    visible: () => true,
    selected: () => false,
    icon: <ShutDownLineIcon />,
    application: "myself",
  },
  // {
  //   path: () => {
  //     return `https://help.seen-apps.com/kb/${getCurrentLanguage()}`;
  //   },
  //   newTab: true,
  //   title: ({ t }: MenuOptions) => t('app.help.title'),
  //   visible: () => true,
  //   selected: () => false,
  //   icon: <HelpIcon />,
  //   application: 'general',
  // },
  {
    path: () => {
      const isDev =
        window.location.hostname === "localhost" ||
        window.location.hostname.indexOf("-dev") >= 0;

      return isDev
        ? `/?show=campaign&campaignId=qdqas1tjru&lang=fr&validationCode=P0V4cGlyZXM9NDgzODE3NDk4MiZLZXktUGFpci1JZD1LMlFKNjhIQk82UjA5QiZTaWduYXR1cmU9R1VJckhyajE1VGplWFVGWU1lMXR%2BNlBxOG53SG1lbzN6QTlBd3kwbTlDRllCOEVoSVNmYS1NdzZ%2BeHdtVlhyTGZlVFBCSlVSZWdNVzRWZkdHRGM1MlRVdkwzZDhEMXl3cHJDTUNISTRHVm9qeUxmQ3ZWYWtDUllXQ0tGUXVBOXhwczZiUzdHaS03bFRtdFNhUC1MWVBZck9EfnRrbUhXSUthVnNiWDlxMkZZZ0Q3NnpZYWtDNDhmeEJnZWJ6ZE82TW9va2JHOFMxS2s2d2R2NWtvYkZIdUYwNFJKdXlQbHVGeW8zaFNYNzI5emloVDJxWUJxSXRQZ2RtRH5uY0JBUERnMWJmOE81RW5iMmxadm9PVVdEOVJ5OE83bWxCUllTc0xGakJrSnd4cGFCUGFUNk9aenlEMkYyMlFpOVJYQzdhUjNLOXl1ejFMTUgxVmxXbk9ENX5nX18%3D`
        : `/?show=campaign&campaignId=gsty91wxw9&validationCode=P0V4cGlyZXM9NDgzODExMTIyMCZLZXktUGFpci1JZD1LOUVGQ1lHRFVVWE9DJlNpZ25hdHVyZT1MWmV1WXp%2BUEdYNnFDS3NjZWYyWmxkUDdTRUNDOVJhSjZ6bjY5Y2xPbXVQMmZQMmNpRFBzNWFvM35Hem4tUTJZVkdqbDZwN29DaTVsWlhMYVhMTjNFZXc1SHkyYjEwMkQyc0duTmNDclhnbHktOG5HQ1BlTkwydjZod3JIaWt1ZnBES015RE9vOGNrdy1RVjQ3dlJ%2BY1p5NH5WMFRnVmJnSXI2NTZzU1B3WVBJLUMzN3JQTlJ0R2tIcEFVOE5WN3dRd29%2BY2hQSmRPaU4yRmd0d0dPVFRpU1ZPfms2LU9KOFhUOXJHRGFscDE3VU9xWVVTajFsZHJERmZldTZDMVJvUjh5VThOUDhEV0lZZ3c5TmZZTFprcHFFSlk1S1I2UG9kLWFYNjRxTjd%2BODh5Z0ZlSzJlQUVjeVpXTnVMZk04Y21ZLWZWYXNxYzdHWU0tMmJXNVZFfkFfXw%3D%3D`;
    },
    title: ({ t }: MenuOptions) => t("app.feedback.title"),
    visible: () => true,
    selected: () => false,
    icon: <FeedbackIcon />,
    application: "general",
  },
  {
    path: () => "https://www.seen-apps.com/terms",
    newTab: true,
    title: ({ t }: MenuOptions) => t("app.consent.termsAndConditions"),
    visible: () => true,
    selected: () => false,
    icon: <PrivacyIcon />,
    application: "general",
  },
];

const SettingsMenu = () => {
  const layoutContext = useContext(LayoutContext);
  const { state: globalState, dispatch } = useContext(GlobalStateContext);
  const { t } = useTranslation("i18n");
  const location = useLocation();
  const history = useHistory();
  const toggleMenu = () => layoutContext.toggleSettingsMenu();

  const isGuest = globalState.profile?.isGuest;

  const menuOptions: MenuOptions = {
    path: location.pathname,
    state: globalState,
    ability: useContext(AbilityContext),
    pathParams: getUrlParams(location.pathname),
    dispatch,
    history,
    t,
  };

  const preloadRouteComponents = (isOpen: boolean) => {
    if (isOpen) {
      EmailsPage.preload();
      PasswordPage.preload();
      ProfilePage.preload();
    }
  };

  return (
    <div className={`${styles.root} ${isGuest ? styles.guestRoot : ""}`}>
      <Menu
        customBurgerIcon={false}
        customCrossIcon={false}
        right
        isOpen={layoutContext.isSettingsMenuOpen}
        onStateChange={(state: any) => {
          layoutContext.settingsStateChangeHandler(state);
          preloadRouteComponents(state.isOpen);
        }}
      >
        <ul className='navbar-nav'>
          {profileMenus
            .filter((m) => m.visible(menuOptions))
            .reduce((acc, menu, index, array) => {
              const {
                path,
                icon,
                title,
                selected,
                onClick,
                newTab,
                disabled: disabledFunc,
              } = menu;

              const pathValue = path(menuOptions);

              const iconValue =
                typeof icon === "function" ? icon(menuOptions) : icon;

              const disabled = disabledFunc && disabledFunc(menuOptions);

              // Add separator if application change
              if (
                index > 0 &&
                array[index - 1].application !== menu.application
              ) {
                acc.push(
                  <Divider
                    key={`divider_${pathValue}`}
                    className={styles.divider}
                  />
                );
              }

              acc.push(
                disabled ? (
                  <li key={pathValue} className={styles.navItemDisabled}>
                    <span className={styles.navLinkDisabled}>
                      {iconValue}
                      <span className={styles.menuText}>
                        {title(menuOptions)}
                      </span>
                    </span>
                  </li>
                ) : (
                  <li
                    key={pathValue}
                    className={cn(styles.navItem, {
                      [styles.selected]: selected(window.location.pathname),
                    })}
                  >
                    {pathValue.startsWith("http") ? (
                      <a
                        className={styles.navLink}
                        href={pathValue}
                        target={newTab ? "_blank" : undefined}
                        rel='noreferrer'
                      >
                        <div className={styles.menu}>
                          {icon}
                          <span className={styles.menuText}>
                            {title(menuOptions)}
                          </span>
                          <ExternalLinkLineIcon className={styles.openInNew} />
                        </div>
                      </a>
                    ) : (
                      <Link
                        className={styles.navLink}
                        onClick={
                          onClick && !disabled
                            ? (event) =>
                                onClick(
                                  menuOptions,
                                  event,
                                  t,
                                  history,
                                  toggleMenu
                                )
                            : toggleMenu
                        }
                        to={pathValue}
                        target={newTab ? "_blank" : undefined}
                      >
                        {iconValue}
                        <span className={styles.menuText}>
                          {title(menuOptions)}
                        </span>
                      </Link>
                    )}
                  </li>
                )
              );

              return acc;
            }, [] as any[])}
          <li>
            <div
              className={styles.version}
            >{`v. ${process.env.REACT_APP_VERSION}`}</div>
          </li>
        </ul>
      </Menu>
    </div>
  );
};

export default SettingsMenu;
