import { RawRule } from "@casl/ability";
import { SubscriptionOptions, Tag } from "./sphere-api";

export type Subscription = "starter" | "essentials" | "premium";

export enum Role {
  SA = "super-admin",
  AMBASSADOR = "ambassador",
  PREVIEW_ACCESS = "preview-access",
  ADMIN = "admin",
  MEMBER = "member",
  CONTRIBUTOR = "contributor",
  SALES_UP = "sales-up",
}

export enum Action {
  Get = "get",
  Put = "put",
  Delete = "delete",
  Post = "post",
  Manage = "manage",
  SmartReport = "smartReport",
  Report = "report",
  Export = "export",
}

export enum Subject {
  All = "all",
  Feeling = "feeling",
  Thinking = "thinking",
  SphereReport = "fullReport",
  Axes = "Axes",
  AxesGroup = "axesGroup",
  Member = "member",
  Sphere = "sphere",
  Session = "session",
  SphereCategory = "sphere-category",
  SphereTag = "sphere-tag",
  SphereEntitlement = "sphere-entitlement",
  FormEntitlement = "form-entitlement",
  FormSessionEntitlement = "form-session-entitlement",
  FormDefinition = "form-definition",
  FormSurvey = "form-survey",
  Strategy = "strategy",
  StrategyAssessment = "strategy-assessment",
  Url = "url",
  Translation = "translation",
  ChatThread = "chat-thread",
  FeelingGoal = "feeling-goal",
  SubscriptionPromotionCode = "subscription-promotion-code",
  Campaign = "campaign",
  CampaignParticipation = "campaign-participation",
}

export interface Ability {
  userId: string;
  isSuperAdmin: boolean;
  subscription: Subscription;
  isGuest?: boolean;
  rules: RawRule[];
}

export type SessionMemberRole = "owner" | "member";

export type SessionMember = {
  roles: SessionMemberRole[];
};

export type SessionMemberPut = {
  roles: SessionMemberRole[];
};

export type SessionMemberPost = SessionMemberPut & {
  memberId: string;
};

export interface Member {
  id: string;
  userLinked: boolean;
  email: string;
  name: string;
  sphereId?: string;
  firstDayOfWork?: string;
  roles: Role[];
  removedByAdmin: boolean;
  subscription?: string;
  removedByUser: boolean;
  createdFrom: "admin" | "link";
  active: boolean;
  tags: (Tag & { autoTag: boolean })[];
  createdAt: string;
  updatedAt: string;
}
export type MemberResponse = Member & {
  sessionMembers: (SessionMember & { id: string })[];
  updateDate: string;
};

export interface MemberBody {
  email: string;
  name: string;
  sphereId: string;
  roles: Role[];
  createdFrom: "admin" | "link";
  tags: Tag[];
  userId?: string;
  active?: boolean;
  requestByUserId: string;
}

export enum EventAction {
  created = "created",
  updated = "updated",
  deleted = "deleted",
}

export enum EventType {
  member = "MemberEvent",
  ability = "AbilityEvent",
  thinking = "ThinkingEvent",
}

export interface MemberEvent {
  subject: string;
  type: EventType;
  action: EventAction;
  member: Member;
  userId?: string;
  previousUserId?: string;
}

export interface AbilityEvent {
  subject: string;
  type: EventType;
  action: EventAction;
  ability: Ability;
}

export type BulkRequest = {
  memberIds: string[];
  sphereContext?: {
    id: string;
    subscriptionOptions?: SubscriptionOptions;
  };
  addTags: { key: string; value: string }[];
  removeTags: { key: string; value: string }[];
  subscription?: Subscription;
};
