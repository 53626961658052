/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import styles from "./AdvancedForm.module.scss";

import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { CollapseIcon, ExpandIcon } from "../RemixIcons";

interface Props {
  className?: string;
  children: any;
  defaultStatus: "opened" | "closed";
  title?: string;
}

const AdvancedForm: React.FC<Props> = ({
  children,
  defaultStatus,
  className,
  title,
}) => {
  const { t } = useTranslation("i18n");
  const [status, setStatus] = useState(defaultStatus);

  const onChange = () => {
    setStatus(status === "opened" ? "closed" : "opened");
  };

  return (
    <div className={`${className || ""} ${styles.root}`}>
      <Button variant='link' onClick={onChange} className={styles.link}>
        {status === "opened" ? <CollapseIcon /> : <ExpandIcon />}
        {title || t("common.action.advancedForm")}
      </Button>
      {status === "opened" && <div className={styles.content}>{children}</div>}
    </div>
  );
};

export default AdvancedForm;
