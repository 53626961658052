import React from "react";
import { LayoutCtx } from "../../@types/seen-apps";
import { navigationMenu } from "../../components/side-menus/NavigationMenu";

const LayoutContext = React.createContext<LayoutCtx>({
  isNavigationMenuOpen: false,
  isNavigationMenuDocked: false,
  isContextMenuOpen: false,
  contextStateChangeHandler: () => {},
  toggleContextMenu: () => {},
  selectedNavigationMenuItem: navigationMenu[0],
  isSettingsMenuOpen: false,
  isFiltersMenuOpen: false,
  isPortalMenuOpen: false,
  toggleNavigationMenu: () => {},
  toggleSettingsMenu: () => {},
  toggleFiltersMenu: () => {},
  togglePortalMenu: () => {},
  setSelectedNavigationMenuItem: () => {},
  navigationStateChangeHandler: () => {},
  settingsStateChangeHandler: () => {},
  filtersStateChangeHandler: () => {},
  portalStateChangeHandler: () => {},
  menuBehaviour: {
    mode: "auto",
  },
  setMenuBehaviour: () => {},
  setPortalBehaviour: () => {},
  portalBehaviour: {
    width: "auto",
  },
});

export const LayoutContextConsumer = LayoutContext.Consumer;
export default LayoutContext;
