import React, { useEffect } from "react";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import remarkMath from "remark-math";
import "./MarkdownComponent.module.scss";

type Props = {
  text?: string;
  className?: string;
};

const MarkdownComponent: React.FC<Props> = ({ text, className }) => {
  const [value, setValue] = React.useState("");

  useEffect(() => {
    const value = (text || "").split(/\n/).reduce((acc, line) => {
      if (line.trim().startsWith("|")) {
        return `${acc}\n${line}`;
      }

      return `${acc}\n\n${line}`;
    }, "");

    setValue(value);
  }, [text]);

  return (
    <Markdown
      className={className}
      linkTarget='_blank'
      rehypePlugins={[remarkMath]}
      remarkPlugins={[remarkGfm]}
    >
      {value}
    </Markdown>
  );
};

export default MarkdownComponent;
